import { useState, useEffect } from "react";

function PageBuilderModal({PageModal,channelData, closePageModal,programId,fetchProgramDetails}) {
  const [isLoading, setIsLoading] = useState(true);
  const [iframeKey, setIframeKey] = useState(Date.now());

  const hideIframe = async() =>{
    closePageModal()
    await fetchProgramDetails();
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIframeKey(Date.now());
    if (PageModal) {
      document.getElementById('iframeForm').submit();
    }
  }, [PageModal]);
  useEffect(() => {
    const handleMessages = (event) => {
        // Check the origin of the message for security
        if (event.origin === 'https://staticblock.campapp.tech') {
          hideIframe();
        }
    };

    window.addEventListener('message', handleMessages);

    return () => {
        window.removeEventListener('message', handleMessages);
    };
}, []);

  return (
    <div>
      {PageModal && (
        <div className="iframe-overlay">
          <div className="iframe-popup">
            {isLoading && <div className="loading-bar"></div>}
            <form
              id="iframeForm"
              action={`https://staticblock.campapp.tech/main/pgdb/Latest-admin.php?key=${iframeKey}`}
              method="POST"
              target="iframePopup"
              style={{ display: 'none' }}
            >
              <input type="hidden" name="user_type" value="user" />
              <input type="hidden" name="page_id" value={channelData.cardId} />
              <input type="hidden" name="host_base" value={process.env.REACT_APP_BASE_URL_API} />
              <input type="hidden" name="host_base_client" value={process.env.REACT_APP_PUBLIC_URL} />
              <input type="hidden" name="program_id" value={programId} />
              <input type="hidden" name="iframeKey" value={iframeKey} />
            </form>
            <iframe
              name="iframePopup"
              style={{ width: '100%', height: '100%', border: 'none', display: isLoading ? 'none' : 'block' }}
              title=""
              onLoad={handleIframeLoad}
            />
          </div>
          <style>
            {`
              .iframe-overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1000; /* Ensure it appears above other content */
              }
              .iframe-popup {
                position: relative;
                width: 100%;
                height: 100%;
                background: white;
                border: none;
              }
              .close-button {
                position: absolute;
                top: 10px;
                left: 10px;
                background: red;
                color: white;
                border: none;
                padding: 10px 20px;
                cursor: pointer;
                z-index: 1100; /* Ensure it appears above the iframe */
              }
              .loading-bar {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 5px;
                background: #3498db; /* Blue color */
                animation: loading 2s infinite;
                z-index: 1050; /* Ensure it appears above the iframe */
              }
              @keyframes loading {
                0% { transform: translateX(-100%); }
                100% { transform: translateX(100%); }
              }
            `}
          </style>
        </div>
      )}
    </div>
  );
}

export default PageBuilderModal;
