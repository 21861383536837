import React, { useState, useEffect } from "react";
import { FaTrash } from 'react-icons/fa';
import { MdOutlineLibraryAdd } from "react-icons/md";
import userIcon from "../../components/Assets/user.jpg";
const Linkedinspeaker=({ onData })=>{
const [speaker,setspeaker]=useState([]);
const [linkedinURL, setLinkedinURL] = useState("");
const [speakervisibility,setspeakervisibility]=useState(true);
useEffect(() => {
    if (onData) {
        onData({
            speaker,
        });
    }
}, [
    speaker, onData
]);

  const handleLinkedInUrlSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL_API}/api/linkedInPro/linkedInProfile?profileURL=${linkedinURL}`,{
          method: 'GET',
          credentials: 'include', 
        }
      );
      const data = await response.json();
      
      // Check if the response contains valid data
      if (data.full_name && data.occupation) {
        const profilePic = data.profile_pic_url || userIcon;
        const speakerData = {
          name: data.full_name,
          profilePic,
          occupation: data.occupation,
          linkedinURL: linkedinURL,
        };
      
        // Check if the LinkedIn URL already exists in the speaker array
        const isExistingSpeaker = speaker.some(
          (s) => s.linkedinURL === linkedinURL
        );

        if (isExistingSpeaker) {
          alert("This LinkedIn profile has already been added.");
        } else {
          // Update the speaker array
          const tempVariable = [...speaker]; // Create a new array to avoid mutating state directly
          tempVariable.push(speakerData);

          setspeaker(tempVariable);
          setspeakervisibility(false);
        }
      } else {
        alert("Invalid Profile URL. Please enter a valid LinkedIn URL.");
      }
    } catch (error) {
      console.error("Error fetching LinkedIn profile:", error);
    }
  };
  const handleremovespeaker = (speakerToRemove) => {
    const updatedSpeaker = speaker.filter(data => data !== speakerToRemove);
    setspeaker(updatedSpeaker);
};

return(
    <>
    {speakervisibility?(
    <div className="row mb-3">
        <div className="col-md-10">
            <label className="form-label">Speakers Linkedin URL</label>
            <input type="text" className="form-control" placeholder="Enter linkedin url" value={linkedinURL} onChange={(e) => setLinkedinURL(e.target.value)}/>
        </div>
        <div className="col-md-2 d-flex align-items-end">
            <button className="btn btn-success text-white" 
            onClick={() => {
                if (!linkedinURL) {
                  alert("Please enter a LinkedIn URL before submitting."); // Optional: you can replace this with any other form of validation message
                  return;
                }
                handleLinkedInUrlSubmit();
              }}
             >Submit</button>
        </div>
    </div>
    ):(
        <div className="row mb-3" style={{overflow:'scroll', scrollbarWidth:'thin', maxHeight:'50vh'}}>
            {speaker.length >0 && speaker.map((data)=>{
                
                    return(
                        <div className="col-sm-2 col-md-2 col-lg-2" style={{paddingBottom:'20px'}}>
                        <div className="card position-relative shadow" style={{borderRadius:"24px"}}>
                        <button className="position-absolute btn btn-link py-1" style={{ top: '2px', right: '2px' }} onClick={()=>{handleremovespeaker(data)}}><FaTrash /></button>
                            <img src={data.profilePic} alt="Speaker" className="rounded-circle mt-2 mx-auto" height={80} width={80} style={{padding: "0.15rem",backgroundColor: 'var(--bs-body-bg)',border: "3px solid var(--bs-success)"}}/>
                            <div className="card-body text-center">
                            <span className="card-title text-truncate" style={{fontSize:"14px"}}>
                                    <a className="text-success" href={data.linkedinURL} target="_blank" rel="noopener noreferrer">
                                    <p className="card-text text-truncate"  style={{fontSize:"14px",cursor: 'pointer'}} data-bs-toggle="tooltip" data-bs-placement="top" 
                                title={data.name} >{data.name}</p></a>
                                </span>
                                <p className="card-text text-truncate"  style={{fontSize:"14px",cursor: 'pointer'}} data-bs-toggle="tooltip" data-bs-placement="top" 
                                title={data.occupation} >{data.occupation}</p>
                            </div>
                        </div></div>
                    );
                
            })}
            <div className="col-sm-4 col-md-3 col-lg-2">
                <div className="card position-relative border border-2 d-flex h-100 align-items-center justify-content-center bg-secondary border-success text-success border-dashed"  style={{borderRadius:"24px"}}>
                    <button className="btn btn-link text-success" onClick={() => {
        setspeakervisibility(true);
        setLinkedinURL(""); // Clear the text box
      }}>
                        <div> <MdOutlineLibraryAdd size={"40px"} color=""></MdOutlineLibraryAdd></div>
                        Add speaker
                    </button>
                </div>
            </div>
        </div>
    )}

    </>
);
}
export default Linkedinspeaker;