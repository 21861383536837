import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UserPreview = () => {
    const [formSettings, setFormSettings] = useState([]);
    const [formHtml, setFormHtml] = useState('');
    const [captchaResponse, setCaptchaResponse] = useState('');
    const formContainerRef = useRef(null);
    const { id } = useParams();
    const [load, setLoad] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/getUserFormById/${id}`,{
            method: 'GET',
            credentials: 'include', 
          })
            .then(res => res.json())
            .then(data => {
                setFormSettings(data);
                setFormHtml(data.html);
            })
            .catch(error => console.error('Error fetching form:', error));
    }, [id]);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Initialize the reCAPTCHA with a delay
    useEffect(() => {
        const initializeRecaptchaWithDelay = () => {
            setTimeout(() => {
                try {
                    if (formHtml && formContainerRef.current) {
                        const formElement = formContainerRef.current.querySelector('#dynamicForm');
                        const addAutoFillEvent = formContainerRef.current.querySelector('input[name=email]');

                        if (addAutoFillEvent) {
                            addAutoFillEvent.addEventListener('input', handleChangeEmail); 
                        }

                        const recaptchaContainer = formContainerRef.current.querySelector('.g-recaptcha');
                        if (formElement) {
                            formElement.addEventListener('submit', handleSubmit);
                        }

                        if (window.grecaptcha && recaptchaContainer) {
                            if (recaptchaContainer.childElementCount === 0) {
                                window.grecaptcha.render(recaptchaContainer, {
                                    'sitekey': '6LfoASoqAAAAANWkZyCrk8KwQwrAwu2OB0yskKqW',
                                    'callback': (response) => {
                                        setCaptchaResponse(response);
                                    },
                                    'expired-callback': () => {
                                        setCaptchaResponse('');
                                    }
                                });
                            }
                        } else {
                            console.warn('reCAPTCHA script not loaded or recaptchaContainer not found');
                        }

                        return () => {
                            if (formElement) {
                                formElement.removeEventListener('submit', handleSubmit);
                            }
                            if (addAutoFillEvent) {
                                addAutoFillEvent.removeEventListener('input', handleChangeEmail);
                            }
                        };
                    }
                    setLoad(true)
                } catch (error) {
                    console.log(error);
                }
            }, 1000); 
        };

        initializeRecaptchaWithDelay();
    }, [formHtml]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formObject = {};

        formData.forEach((value, key) => {
            if (key in formObject) {
                if (!Array.isArray(formObject[key])) {
                    formObject[key] = [formObject[key]];
                }
                formObject[key].push(value);
            } else {
                formObject[key] = value;
            }
        });



        formObject.recaptchaResponse = captchaResponse;
        formObject.referer = 'not implemented';
        formObject.form_id = formSettings._id;

        if (formObject['g-recaptcha-response'] === '') {
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Please verify the captcha',
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/submitUserForm`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formObject),
        })
            .then(response => response.json())
            .then(data => {
                if (data.downloadUrl) {
                    const link = document.createElement('a');
                    link.href = `${process.env.REACT_APP_BASE_URL_API}/asset` + data.downloadUrl;
                    link.download = '';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                if (formSettings.successfulSubmitAction === 'remainFormWithMessage') {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: formSettings.redirectUrlOrMessage,
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else if (formSettings.successfulSubmitAction === 'redirectUrl') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Thank you',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        window.open(formSettings.redirectUrlOrMessage)
                    });
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Thank you',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        window.location.reload();
                    });
                    
                }
            })
            .catch(error => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong please try again later!',
                    showConfirmButton: false,
                    timer: 3000
                });
                console.error('Error submitting form:', error);
            });
    };


    let debounceTimeout;

    const handleChangeEmail = (e) => {
        const email = e.target.value;

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        debounceTimeout = setTimeout(async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/fetchLead/getLead`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });

                const data = await response.json();

                if (data) {
                    const formElement = formContainerRef.current.querySelector('#dynamicForm');
                    if (formElement) {
                        Object.keys(data).forEach(key => {
                            const input = formElement.querySelector(`input[name=${key}]`);
                            if (input) {
                                input.value = data[key];
                            }
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching lead data:', error);
            }
        }, 1000);
    };


    return (
        <div className='container'>
            {load ?
                <div ref={formContainerRef}>
                    <div dangerouslySetInnerHTML={{ __html: formHtml }} />
                </div>
            :'loading'}
        </div>
    );
};

export default UserPreview;
