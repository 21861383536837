import React, { useState } from 'react';
import '../../components/CSS/ProgressStepper.css'
import { FaCheck } from 'react-icons/fa';



const ProgressStepper = ({ steps, currentStep, currentSubStep, setCurrentStep, setCurrentSubStep }) => {

  const handleNextSubStep = () => {
    const nextSubStep = currentSubStep + 1;
    if (nextSubStep < steps[currentStep].subSteps.length) {
      setCurrentSubStep(nextSubStep);
    } else {
      handleNextStep();
    }
  };

  const handlePreviousSubStep = () => {
    const previousSubStep = currentSubStep - 1;
    if (previousSubStep >= 0) {
      setCurrentSubStep(previousSubStep);
    } else {
      handlePreviousStep();
    }
  };

  const handleNextStep = () => {
    const nextStep = currentStep + 1;
    if (nextStep < steps.length) {
      setCurrentStep(nextStep);
      setCurrentSubStep(0); // Reset sub-step to the first one
    }
  };

  const handlePreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 0) {
      setCurrentStep(previousStep);
      setCurrentSubStep(steps[previousStep].subSteps.length - 1); // Set to last sub-step of the previous step
    }
  };

  const handleSubmit = () => {
    console.log('Form submitted!');
  };

  const calculateProgress = () => {
    const totalSubSteps = steps[currentStep].subSteps.length;
    return ((currentSubStep + 1) / totalSubSteps) * 100;
  };

  return (
    <div className="vertical-steps">
      <div className="stepper-container">
        <div className="stepper-steps">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`stepper-step ${
                currentStep >= index ? 'active' : ''
              }`}
            >
              {currentStep > index ? (
                <FaCheck></FaCheck>
              ) : (
                step.label
              )}
              {index < steps.length - 1 && (
                <div className="connector">
                  <div
                    className="progress-bar"
                    style={{
                      height: `${
                        currentStep === index ? calculateProgress() : currentStep > index ? 100 : 0
                      }%`,
                    }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>
          <div>
            <div className='head'>
            <h6>Basic Information</h6>
            <p>Provide essential details about your business and event to enhance marketing automation with our AI.</p>
            </div>
            <div className='head'>
            <h6>Journey</h6>
            <p>Guide, remind, and follow up with attendees through every event stage.</p>
            </div>
            <div className='head'>
            <h6>Results/Outcomes</h6>
            <p>Evaluate success and impact from event awareness to attendance and follow-up communication.</p>
            </div>
          </div>      
      </div>
    </div>
  );
};

export default ProgressStepper;

