import { DataGrid } from '@mui/x-data-grid';
const FailedList = ({ rows, columns }) => {
    console.log(rows ,columns);
    
    const rowsWithId = rows.map((row, index) => ({
        ...row,
        id:  index,  
    }));

    return (
        <div style={{ height: 400, width: '100%' }}>
            {rowsWithId.length > 0 ? <DataGrid rows={rowsWithId} columns={columns} /> : 'no data found'}
        </div>
    );
}

export default FailedList;

