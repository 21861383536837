import React from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { useState } from 'react';
import { RiDragMove2Fill } from 'react-icons/ri';

const DraggableFormElement = ({ element, index, moveFormElement, handleElementClick, isClicked }) => {
    const [, ref] = useDrag({
        type: 'form-element',
        item: { index },
    });
    const [hoveredElementIndex, setHoveredElementIndex] = useState(null);
    const handleMouseEnter = (index) => {
        setHoveredElementIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredElementIndex(null);
    };

    const [, drop] = useDrop({
        accept: 'form-element',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveFormElement(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });
    const handleCaptchaChange = (value) => {
        console.log("Captcha value:", value);
    };
    return (
        <div ref={(node) => ref(drop(node))} className={`col-md-${element.columns || 12} form-group ${isClicked ? 'highlighted' : ''}`} onClick={() => handleElementClick(index)} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
            {element.type === 'heading'  && <div className=''>{hoveredElementIndex === index && <RiDragMove2Fill style={{ marginLeft: '8px', color: '#007bff', cursor: 'move',float:'right' }} />}<p style={element.style}>{element.text}</p></div>}

            {element.label && element.type !== 'submit' && element.type !== 'hidden' && element.type !== 'empty' && (
                <label style={element.labelStyle}>
                    {element.label}
                    {element.required === 'on' && <span style={{ color: 'red' }}> * </span>}
                    {hoveredElementIndex === index && <RiDragMove2Fill style={{ marginLeft: '8px', color: '#007bff', cursor: 'move' }} />}
                </label>
            )}

            {element.type === 'text' || element.type === 'email' || element.type === 'number' || element.type === 'password' || element.type === 'date' || element.type === 'hidden' || element.type === 'datetime-local' || element.type === 'file' ? (
                <input
                    type={element.type}
                    name={element.name}
                    placeholder={element.placeholder}
                    style={element.style}
                    className="form-control"
                    required={element.required === 'on'}
                />
            ) : element.type === 'select' ? (
                <select name={element.name} style={element.style} className="form-control" required={element.required === 'on'}>
                    <option value=''>select</option>
                    {element.options?.map((option, i) => (
                        <option key={i} value={option}>{option}</option>
                    ))}

                </select>
            ) : element.type === 'multi-select' ? (
                <select
                    name={element.name}
                    style={element.style}
                    className="form-control"
                    multiple
                    required={element.required === 'on'}
                    
                >
                    {element.options?.map((option, i) => (
                        <option key={i} value={option}>{option}</option>
                    ))}

                </select>
            ) : element.type === 'textarea' ? (
                <textarea
                    name={element.name}
                    placeholder={element.placeholder}
                    style={element.style}
                    className="form-control"
                />
            ) : element.type === 'checkbox' ? (
                <div className="checkbox-group">
                    {element.options?.map((option, i) => (
                        <div key={i} className="form-check">
                            <input
                                type="checkbox"
                                name={element.name}
                                value={option}
                                style={element.style}
                                className="form-check-input"
                            />
                            <label className="form-check-label">{option}</label>
                        </div>
                    ))}
                </div>
            ) : element.type === 'radio' ? (
                <div className="radio-group">
                    {element.options?.map((option, i) => (
                        <div key={i} className="form-check">
                            <input
                                type="radio"
                                name={element.name}
                                value={option}
                                style={element.style}
                                className="form-check-input"
                            />
                            <label className="form-check-label">{option}</label>
                        </div>
                    ))}
                </div>
            ) : element.type === 'submit' ? (
                
                <button type="submit" style={element.style} className="btn">
                    {element.label}
                </button>
            ) : element.type === 'recaptcha' ? (
                <div className="g-recaptcha" > captcha will appear here</div>
            ) : element.type === 'spacebar' ? (
                <div style={element.style}></div>
            ) : null}
        </div>
    );
};

export default DraggableFormElement

