import React,{useState, useEffect} from 'react';
import gmail from "../../components/Assets/socialIcons/Gmail.png";
import meta from "../../components/Assets/socialIcons/Meta.png";
import Google from "../../components/Assets/socialIcons/Google.png";
import Linkedin from "../../components/Assets/socialIcons/Linkedin.png";
import Whatsapp from "../../components/Assets/socialIcons/Whatsapp.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {ReactComponent as AiIcon} from '../../components/Assets/AiIcon.svg';
import axios from 'axios';

const fetchMediaData = async (alias) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/labelIcon?alias=${alias}`,{withCredentials: true});
        return response.data;
    } catch (error) {
        console.error(`Error fetching data for ${alias}:`, error);
        return null; // Return null if there's an error
    }
};
const useMediaData = () => {
    const [mediaData, setMediaData] = useState([]);

    useEffect(() => {
        const initialMedia = [
            { name: 'email', registrations: 0, budget: 0, costPerRegistration: 0.00, enabled: true },
			{ name: 'whatsapp', registrations: 0, budget: 0, costPerRegistration: 0.00, enabled: true },
            { name: 'google_ad', registrations: 0, budget: 0, costPerRegistration: 0.00, enabled: true },
            { name: 'meta_ad', registrations: 0, budget: 0, costPerRegistration: 0.00, enabled: true },
            { name: 'linkedin_ad', registrations: 0, budget: 0, costPerRegistration: 0.00, enabled: true }
        ];
 
        const fetchAllMediaData = async () => {
            // Create an array of promises for all API requests
            const promises = initialMedia.map(async (media) => {
                const data = await fetchMediaData(media.name);
                if (data) {
                    return {
                        ...media,
                        displayName: data.name, // Use the name from API response
                        logo: `/assets/socialIcons/${data.icon}`, //data.icon, // Use the icon as logo from API response
                    };
                } else {
                    return media; // In case of an error, return the original media data
                }
            });

            // Wait for all promises to resolve and set the media data
            const updatedMediaData = await Promise.all(promises);
            setMediaData(updatedMediaData);
        };

        fetchAllMediaData();
    }, []);

    return mediaData;
};

const EventReachExpectations = ({ eventType, onData }) => {
	
	const initialMedia = useMediaData();
	console.log(initialMedia);

	  const updateMediaBasedOnType = (type) => {
		return initialMedia.map(channel => {
		  if(type === "b2bevent")
		  {
			if (['email', 'linkedin_ad', 'whatsapp'].includes(channel.name)) {
				return { ...channel, enabled: true };
			  }
			  else{
				return { ...channel, enabled: false };
			  }
		  }
		  else if(type === "b2cevent")
		  {
			  if (['meta_ad', 'whatsapp', 'google_ad'].includes(channel.name)) {
				  return { ...channel, enabled: true };
				}
				else{
				  return { ...channel, enabled: false };
				}
		  }
		  else if(type === "bothevent")
		  {
				if (['email', 'linkedin_ad', 'google_ad', 'meta_ad'].includes(channel.name)) {
					return { ...channel, enabled: true };
				  }
				  else{
					return { ...channel, enabled: false };
				  }
		  }
		  else{
			return channel;
		  }
		});
	  };
	  
	  const [media, setMedia] = useState(updateMediaBasedOnType(eventType));//useState(updatedMedia);
	  const [selectedDisabledMedia, setSelectedDisabledMedia] = useState('');

	  // Use useEffect to update media whenever eventType changes
	  useEffect(() => {
		const updatedMedia = updateMediaBasedOnType(eventType);
		setMedia(updatedMedia);
	  }, [eventType]); // Dependency array includes eventType

	  useEffect(() => {
		if (onData) {
			onData({
				media,
			});
		}
	}, [
		media, onData
	]);

	  const toggleEnabled = (index) => {
		const newMedia = [...media];
		newMedia[index].enabled = !newMedia[index].enabled;
		const item = newMedia[index];
		// Reset values if disabled
		if (item.enabled) {
			item.registrations = 0;
			item.budget = 0;
			item.costPerRegistration = 0.00;
		}
		setMedia(newMedia);
	  };
	
	  const calculateCostPerRegistration = (budget, registrations) => {
		return registrations > 0 ? (budget / registrations).toFixed(2) : 0;
	  };
	
	  const updateRegistrations = (index, value) => {
		const newMedia = [...media];
		newMedia[index].registrations = value;
		newMedia[index].costPerRegistration = calculateCostPerRegistration(newMedia[index].budget, value);
		setMedia(newMedia);
	  };
	
	  const updateBudget = (index, value) => {
		const newMedia = [...media];
		newMedia[index].budget = value;
		newMedia[index].costPerRegistration = calculateCostPerRegistration(value, newMedia[index].registrations);
		setMedia(newMedia);
	  };

	  const handleSelectChange = (selectedValue) => {
		selectedValue=selectedValue.target.value;
		setSelectedDisabledMedia(selectedValue);
		if (selectedValue) {
		  const index = media.findIndex(item => item.name === selectedValue);
		  if (index !== -1) {
			toggleEnabled(index);
		  }
		  setSelectedDisabledMedia('');
		}
	  };

	  function formatNumber(num) {
		if (num >= 1e9) {
		  return (num / 1e9).toFixed(1) + 'B';
		} else if (num >= 1e6) {
		  return (num / 1e6).toFixed(1) + 'M';
		} else if (num >= 1e3) {
		  return (num / 1e3).toFixed(1) + 'K';
		} else {
		  return num.toString();
		}
	  }
	  	  
	  return (
		<div style={{fontSize:"10px"}}>
			<div style={{ display: 'flex', marginTop: '-10px' }}>
			<AiIcon style={{height:'20px',width:'20px',color:'#7280f9'}}></AiIcon>
			<div className="gradient-text"> Recommended by Camp AI</div>
			</div>
			<div className="row mb-3">
				{media.map((item, index) => (
				  <div className={`col-md-2 p-2 ${item.enabled ? 'd-block' : 'd-none'}`} key={index}>
					<div className={`card h-100 ${item.enabled ? 'd-block' : 'd-none'}`} style={{borderRadius:'12px',boxShadow:' 0px 0px 25px 0px #00000014'}}>
					  <div className="card-body p-2 position-relative">
						<div className='d-flex justify-content-start align-items-center mb-2'>
							<img src={item.logo} height={25} width={25} alt={item.name}></img>
							<div className='text-start ms-2'>
								<div className='fw-bolder'>{item.displayName}</div>
							</div>                        
						</div>
						<div className="form-group mb-2">
						  <label htmlFor={`registrations-${index}`} className='mb-1'>No of Registrations</label>
						  <input
							type="number"
							className="form-control p-1 fw-bolder"
							id={`registrations-${index}`}
							value={item.registrations}
							min={0}
							onChange={(e) => updateRegistrations(index, parseInt(e.target.value))}
							disabled={!item.enabled}
							style={{width:"70px"}}
						  />
						</div>
						<div className="form-group mb-2">
						  <label htmlFor={`budget-${index}`}>Total Budget</label>
						  <div style={{ display: "flex", alignItems: "center" }}>
    						<input
								type="number"
								className="form-control p-1 fw-bolder"
								id={`budget-${index}`}
								value={item.budget}
								min={0}
								onChange={(e) => updateBudget(index, parseInt(e.target.value))}
								disabled={!item.enabled}
								style={{width:"70px"}}
							/>
							<span style={{ paddingLeft: "5px", fontWeight: "bold", fontSize: "medium" }}>$</span>
						  </div>
						</div>
						<p className="card-text">Cost/Registration: <span className='fw-bolder text-success'>{item.costPerRegistration} $</span></p>

						<div className="btn-group position-absolute" style={{top:0,right:0}}>
							<button className="btn btn-link btn-sm " type="button" data-bs-toggle="dropdown" aria-expanded="false">
								<BsThreeDotsVertical></BsThreeDotsVertical>
							</button>
							<ul className="dropdown-menu py-1" style={{minWidth:0,fontSize:'12px'}}>
								<li>
									<button
									className="dropdown-item"
									onClick={() => toggleEnabled(index)}
									>
									{item.enabled ? 'Delete' : ''}
									</button>
								</li>

							</ul>
						</div>
					  </div>
					</div>
				  </div>
				))}
				{media.some(item => !item.enabled) && (
				<div className="col-md-2 p-2">
					<div className="card position-relative border border-2 h-100 bg-secondary border-success text-success border-dashed" style={{borderRadius:'12px',boxShadow:' 0px 0px 25px 0px #00000014'}}>
						<div className="card-body text-center p-2 d-flex flex-column justify-content-center">
							<button className="btn btn-link text-success">
								<MdOutlineLibraryAdd size={"40px"} color=""></MdOutlineLibraryAdd> 
								<div className='text-nowrap'>Add Goals</div>
							</button>
							<div className="form-group mt-3">
							<FormControl fullWidth>
								<InputLabel id="media-select-label" style={{top:'-14px'}}>Choose</InputLabel>
								<Select style={{height:'25px'}}
									labelId="media-select-label"
									value={selectedDisabledMedia}
									onChange={handleSelectChange}
									label="Choose"
									sx={{
										'& .MuiFormControl-root': {
											'& .MuiInputLabel-root':{
												
											},
										  '& .MuiSelect-select': {
											paddingTop: '0px',   // Adjust vertical padding
											paddingBottom: '0px', // Adjust vertical padding
										  },
										},
									  }}
									>
									{media
									.filter(item => !item.enabled)
									.map((item, index) => (
										<MenuItem key={index} value={item.name}>
										<img src={item.logo} style={{height:'25px',width:'25px',marginRight:'5px'}}></img>
										{item.displayName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							</div>
						</div>
					</div>
				</div>
				)}
			</div>
			<hr></hr>
			<div className="row justify-content-start">
				<div className="col-md-3">
				<div className="card shadow-sm mb-4">
					<div className="card-body p-2">
					<p className="card-title ps-3">Total Registrations</p>
					<hr className='m-2'></hr>
					<p className="card-text h6 text-primary ps-3">
						{
							media.reduce((acc, item) => item.enabled ? acc + item.registrations : acc, 0) > 0
							? formatNumber(media.reduce((acc, item) => item.enabled ? acc + item.registrations : acc, 0))
							: '0'
						}
					</p>
					</div>
				</div>
				</div>
				<div className="col-md-3">
				<div className="card shadow-sm mb-4">
					<div className="card-body p-2">
					<p className="card-title ps-3">Total Budget</p>
					<hr className='m-2'></hr>
					<p className="card-text h6 text-primary ps-3">
						{
							media.reduce((acc, item) => item.enabled ? acc + item.budget : acc, 0) > 0
							? `${formatNumber(media.reduce((acc, item) => item.enabled ? acc + item.budget : acc, 0))} $`
							: '0.00 $'
						}
					</p>
					</div>
				</div>
				</div>
				<div className="col-md-3">
				<div className="card shadow-sm mb-4">
					<div className="card-body p-2">
					<p className="card-title ps-3">Cost/Registration</p>
					<hr className='m-2'></hr>
					<p className="card-text h6 text-success ps-3">	
					{
						media.reduce((acc, item) => item.enabled ? acc + item.registrations : acc, 0) <= 0
						? '0.00 $'
						: (
						formatNumber(
							(
							media.reduce((acc, item) => item.enabled ? acc + item.budget : acc, 0) /
							media.reduce((acc, item) => item.enabled ? acc + item.registrations : acc, 0)
							).toFixed(2)
						) + ' $'
						)
					}
					</p>
					</div>
				</div>
				</div>
			</div>
		</div>
	  );
};

export default EventReachExpectations;
