import React, { useRef, useEffect } from 'react';
import CardComp from './journeyModels/CardComp';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useGlobal } from '../../context/GlobalProvider';
import metaIcon from '../../components/Assets/meta.svg';
const Attend = ({ handleChannelModel ,fetchProgramDetails}) => {
    const { attend_List,
        attend_Channel,
        attend_connectionIds,
        notAttend_List,
        notAttend_Channel,
        notAttend_connectionIds,

    } = useGlobal();

    const getClassName = (data) => {
        if (data.length === 1) return 'single';
        if (data.length === 2) return 'double';
        if (data.length > 2) return 'multiples';
        return '';
    };
    const elementsRefs = useRef({});

 

    // return (
    //     <Xwrapper>
    //         <div className='reach-tab'>
    //             <div className='row main-card-div'>
    //                 <div className={`col-sm-3 channel-list card-center`}>
    //                 </div>
    //                 <div className={`col-sm-3 registration-page card-center ${getClassName(attend_List)}`}>
    //                     {attend_List.map((data, index) => (
    //                         <div className='card-wrap' key={index} id={`box-${data.id}`}
    //                             ref={(el) => (elementsRefs.current[data.id] = el)}>
    //                             <CardComp
    //                                 channel={data.channel}
    //                                 no_campains={data.no_campains}
    //                                 expected={data.expected}
    //                                 reach={data.reach}
    //                                 icon={data.icon}
    //                                 status={data.status}
    //                                 handleChannelModel={handleChannelModel}
    //                             />
    //                         </div>
    //                     ))}
    //                 </div>
    //                 <div className={`col-sm-3 form-list card-center ${getClassName(attend_Channel)}`}>
    //                     {attend_Channel.map((data, index) => (
    //                         <div className='card-wrap' key={index} id={`box-${data.id}`}
    //                             ref={(el) => (elementsRefs.current[data.id] = el)}>
    //                             <CardComp
    //                                 channel={data.channel}
    //                                 no_campains={data.no_campains}
    //                                 expected={data.expected}
    //                                 reach={data.reach}
    //                                 icon={data.icon}
    //                                 status={data.status}
    //                                 handleChannelModel={handleChannelModel}
    //                             />
    //                         </div>
    //                     ))}
    //                 </div>
    //                 <div className={`col-sm-3 registration-list card-center `}>

    //                 </div>
    //             </div>
    //             <div className='row main-card-div'>
    //                 <div className={`col-sm-3 channel-list card-center`}>
    //                 </div>
    //                 <div className={`col-sm-3 registration-page card-center ${getClassName(notAttend_List)}`}>
    //                     {notAttend_List.map((data, index) => (
    //                         <div className='card-wrap' key={index} id={`box-${data.id}`}
    //                             ref={(el) => (elementsRefs.current[data.id] = el)}>
    //                             <CardComp
    //                                 channel={data.channel}
    //                                 no_campains={data.no_campains}
    //                                 expected={data.expected}
    //                                 reach={data.reach}
    //                                 icon={data.icon}
    //                                 status={data.status}
    //                                 handleChannelModel={handleChannelModel}
    //                             />
    //                         </div>
    //                     ))}
    //                 </div>
    //                 <div className={`col-sm-3 form-list card-center ${getClassName(notAttend_Channel)}`}>
    //                     {notAttend_Channel.map((data, index) => (
    //                         <div className='card-wrap' key={index} id={`box-${data.id}`}
    //                             ref={(el) => (elementsRefs.current[data.id] = el)}>
    //                             <CardComp
    //                                 channel={data.channel}
    //                                 no_campains={data.no_campains}
    //                                 expected={data.expected}
    //                                 reach={data.reach}
    //                                 icon={data.icon}
    //                                 status={data.status}
    //                                 handleChannelModel={handleChannelModel}
    //                             />
    //                         </div>
    //                     ))}
    //                 </div>
    //                 <div className={`col-sm-3 registration-list card-center `}>

    //                 </div>
    //             </div>
    //             {attend_connectionIds.map((connection, index) => (
    //                 <Xarrow
    //                     key={index}
    //                     start={`box-${connection.source}`}
    //                     end={`box-${connection.target}`}
    //                     startAnchor='right'
    //                     endAnchor='left'
    //                     color="#B3B3B3"
    //                     strokeWidth={2}
    //                     headSize={6}
    //                     curveness={0.5}
    //                 />
    //             ))}
    //             {attend_connectionIds.map((connection, index) => (
    //                 <Xarrow
    //                     key={index}
    //                     start={`box-${connection.source}`}
    //                     end={`box-${connection.target}`}
    //                     startAnchor='right'
    //                     endAnchor='left'
    //                     color="#B3B3B3"
    //                     strokeWidth={2}
    //                     headSize={6}
    //                     curveness={0.5}
    //                 />
    //             ))}
    //             {notAttend_connectionIds.map((connection, index) => (
    //                 <Xarrow
    //                     key={index}
    //                     start={`box-${connection.source}`}
    //                     end={`box-${connection.target}`}
    //                     startAnchor='right'
    //                     endAnchor='left'
    //                     color="#B3B3B3"
    //                     strokeWidth={2}
    //                     headSize={6}
    //                     curveness={0.5}
    //                 />
    //             ))}
    //         </div>
    //     </Xwrapper>
    // );
    return (
        <Xwrapper>
            <div className='attend-tab'>
                <div className='row main-card-div'>
                    <div className={`col-sm-3 registration-page card-center ${getClassName(attend_List)}`}>
                        {attend_List.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={`col-sm-3 form-list card-center ${getClassName(attend_Channel)}`}>
                        {attend_Channel.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={`col-sm-3 registration-page card-center ${getClassName(notAttend_List)}`}>
                        {notAttend_List.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={`col-sm-3 form-list card-center ${getClassName(notAttend_Channel)}`}>
                        {notAttend_Channel.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {attend_connectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
                {attend_connectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
                {notAttend_connectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
            </div>
        </Xwrapper>
    );
}

export default Attend;
