import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaMobileAlt, FaLaptop, FaDesktop } from 'react-icons/fa';
import './PreviewModel.css';
import { IoMdCloseCircle } from "react-icons/io";

function PreviewModel({ show, onHide, formHtml }) {
    const [view, setView] = useState('desktop');

    const getContainerClass = () => {
        switch (view) {
            case 'mobile':
                return 'preview-container preview-mobile';
            case 'laptop':
                return 'preview-container preview-laptop';
            case 'desktop':
            default:
                return 'preview-container preview-desktop';
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton style={{ background: "#455a54", color: 'white' }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Preview
                </Modal.Title>
                <div className="view-toggle">
                    <Button variant="light" onClick={() => setView('mobile')}>
                        <FaMobileAlt size={20} />
                    </Button>
                    <Button variant="light" onClick={() => setView('laptop')}>
                        <FaLaptop size={20} />
                    </Button>
                    <Button variant="light" onClick={() => setView('desktop')}>
                        <FaDesktop size={20} />
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={getContainerClass()}>
                    <div dangerouslySetInnerHTML={{ __html: formHtml }} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="cssbuttons-io-button" onClick={onHide}>
                    <IoMdCloseCircle />
                    <span>Close</span>
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default PreviewModel;
