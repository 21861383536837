import { IoInformationCircle } from "react-icons/io5";
import { Tooltip } from 'react-tooltip';

function Icons({ content, id }) {
    return (
        <div className="icon-tooltip-container">
            <IoInformationCircle id={id} className="my-anchor-element"/>
            <Tooltip anchorSelect={`#${id}`} place="right">
                {content}
            </Tooltip>
        </div>
    );
}

export default Icons;
