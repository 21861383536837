import React, { useState, useRef, useEffect } from 'react';
import '../../formBuilder/Editor.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import availableFonts from '../../formBuilder/FontFamily';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableFormElement from '../../formBuilder/DraggableFormElement';
import { IoSave } from "react-icons/io5";
import { MdPreview } from "react-icons/md";
import SaveModel from '../../formBuilder/SaveModel';
import axios from 'axios';
import { MdOutlineFormatColorReset } from "react-icons/md";
import Select from 'react-select';
import Icons from '../../formBuilder/Icons';
import Modal from 'react-bootstrap/Modal';
const FormDataChannel = ({ formChannelDataModel, closeformChannelDataModel, channelData, programId, fetchProgramDetails }) => {
    const id = channelData.cardId;
    // const id = '66cd858b377ec31c4b0e5c54';
    const [formElements, setFormElements] = useState([]);
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);
    const [textInput, setTextInput] = useState(false);
    const [selectInput, setSelectInput] = useState(false);
    const [textareaInput, setTextareaInput] = useState(false);
    const [checkboxInput, setCheckboxInput] = useState(false);
    const [radioInput, setRadioInput] = useState(false);
    const [heading, setHeading] = useState(false);
    const [recaptchaInput, setRecaptchaInput] = useState(false);
    const [button, setButton] = useState(false);
    const [spacebar, setSpacebar] = useState(false);
    const [preview, setPreview] = useState(false);
    const [save, setSave] = useState(false);
    const [labelStyling, setLabelStyling] = useState(false);
    const [columns, setColumns] = useState([]);
    const [unique, setUnique] = useState(0);
    const [editorStatus, setEditorStatus] = useState(false);
    const [pageUrl, setPageUrl] = useState('');
    const [mainForm, setMainForm] = useState({
        formName: '',
        successfulSubmitAction: '',
        redirectUrlOrMessage: '',
        description: '',
        action: '',
        actionDetails: ''
    });
    const [formSettings, setFormSettings] = useState({
        backgroundColor: '#ffffff',
        padding: '10px',
        margin: '10px',
        borderRadius: '5px',
        color: '#000000',
    });
    const form = useRef();

    const handleInputChange = (property, value, isStyle = false) => {
        const updatedElements = [...formElements];
        const currentElement = updatedElements[selectedElementIndex];
        if (labelStyling) {
            updatedElements[selectedElementIndex].labelStyle = {
                ...currentElement.labelStyle,
                [property]: value
            };
        } else if (isStyle && !labelStyling) {
            updatedElements[selectedElementIndex].style = {
                ...currentElement.style,
                [property]: value
            };
        } else {
            if (property === 'label' && currentElement.name?.startsWith('default') || (!(currentElement.name) && property === 'label')) {
                updatedElements[selectedElementIndex] = {
                    ...currentElement,
                    label: value,
                    name: `default_${value}`
                };
            } else if (property === 'name' && value === undefined) {
                updatedElements[selectedElementIndex] = {
                    ...currentElement,
                    [property]: `default_${unique}`
                };
            } else {
                updatedElements[selectedElementIndex] = {
                    ...currentElement,
                    [property]: value
                };
            }
        }

        setFormElements(updatedElements);
    };
    const [clickedElementIndex, setClickedElementIndex] = useState(null);
    const [validation, setValidation] = useState(false);
    const handleElementClick = (index) => {
        console.log(index);

        const element = formElements[index];
        if (element.name === 'email' || element.name === 'firstname') {
            setValidation(true)
            // console.log('email field cannot be edited');
            // setTextInput(true);
            // setSelectInput(false);
            // setTextareaInput(false);
            // setCheckboxInput(false);
            // setRadioInput(false);
            // setHeading(false);
            // setButton(false);
            // setRecaptchaInput(false);
            // setSpacebar(false);
            // setSelectedElementIndex(null);
            // setClickedElementIndex(null);
            // return
        } else {
            setValidation(false);
        }

        setSelectedElementIndex(index);
        setClickedElementIndex(index);

        setTextInput(false);
        setSelectInput(false);
        setTextareaInput(false);
        setCheckboxInput(false);
        setRadioInput(false);
        setHeading(false);
        setButton(false);
        setRecaptchaInput(false);
        setSpacebar(false);

        switch (element.type) {
            case 'text':
            case 'email':
            case 'number':
            case 'password':
            case 'file':
            case 'date':
            case 'datetime-local':
            case 'hidden':
                setTextInput(true);
                break;
            case 'select':
            case 'multi-select':
                setSelectInput(true);
                break;
            case 'textarea':
                setTextareaInput(true);
                break;
            case 'checkbox':
                setCheckboxInput(true);
                break;
            case 'heading':
                setHeading(true);
                break;
            case 'submit':
                setButton(true);
                break;
            case 'radio':
                setRadioInput(true);
                break;
            case 'recaptcha':
                setRecaptchaInput(true);
                break;
            case 'spacebar':
                setSpacebar(true);
                break;
            default:
                break;
        }
    };

    const removeElement = () => {
        if (selectedElementIndex !== null && selectedElementIndex >= 0) {
            setTextInput(false);
            setSelectInput(false);
            setTextareaInput(false);
            setCheckboxInput(false);
            setRadioInput(false);
            setHeading(false);
            setButton(false);
            setRadioInput(false);
            setRecaptchaInput(false);
            setSpacebar(false);
            const updatedElements = formElements.filter((_, index) => index !== selectedElementIndex);
            setFormElements(updatedElements);
            setSelectedElementIndex(null);
            toast.success('Element removed Successfully!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });

        }
    };



    const handleSelectOptionsChange = (index, value) => {
        const updatedElements = [...formElements];
        updatedElements[selectedElementIndex].options[index] = value;
        setFormElements(updatedElements);
    };

    const addSelectOption = () => {
        const updatedElements = [...formElements];
        const options = updatedElements[selectedElementIndex].options || [];
        options.push('New Option');
        updatedElements[selectedElementIndex].options = options;
        setFormElements(updatedElements);
    };

    const removeSelectOption = () => {
        const updatedElements = [...formElements];
        const options = updatedElements[selectedElementIndex].options || [];
        if (options.length > 1) {
            options.pop();
        } else {
            toast.warn('This element requires atleast one option ', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
        updatedElements[selectedElementIndex].options = options;
        setFormElements(updatedElements);
    };


    const moveFormElement = (fromIndex, toIndex) => {
        const updatedElements = [...formElements];
        const [movedElement] = updatedElements.splice(fromIndex, 1);
        updatedElements.splice(toIndex, 0, movedElement);
        setFormElements(updatedElements);
    };

    useEffect(() => {
        if (form.current) {
            const submitButton = form.current.querySelector('button[type="submit"]');
            if (submitButton) {
                if (formElements.length > 0) {
                    submitButton.disabled = true;
                } else {
                    submitButton.disabled = false;
                }
            }
        }
    }, [formElements, id]);




    const formContainer = useRef();
    const [htmlData, setHtmlData] = useState('');
    const [jsonData, setJsonData] = useState([]);
    const saveHtml = () => {
        if (form.current) {
            const submitButton = form.current.querySelector('button[type="submit"]');
            if (submitButton) {
                submitButton.disabled = false;
            }
        }

        if (formElements.length > 0) {
            if (formContainer.current) {
                const bootstrapLink = '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">';
                const htmlContent = formContainer.current.innerHTML;
                const fullHtmlContent = htmlContent;
                const jsonContent = formElements;
                setSave(true);
                setHtmlData(fullHtmlContent);
                setJsonData(jsonContent)
            }
        } else {
            toast.warn('Please add a Elements to save ', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }

    };
    const onShow = () => {
        if (formElements.length > 0) {
            setPreview(true)
        } else {
            toast.warn('Please add a Elements to preview', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
    }
    const updateFormCardStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedCardForm`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ programId }),
            });
            if (response.ok) {
                const result = await response.json();
                await fetchProgramDetails();
                closeformChannelDataModel();
            } else {
                console.error('Error in API response:', response.statusText);
            }
        } catch (error) {
            console.error('Error making API request:', error);
        }
    };

    const [defaultSettings, setDefaultSettings] = useState({});
    const [fId, setFId] = useState('');
    const saveForm = async () => {

        // console.log(mainForm);
        // return
        if (mainForm.formName === '' || mainForm.redirectUrl === '' || mainForm.successfulSubmitAction === '' || mainForm.description === '' || mainForm.action === '' || mainForm.actionDetails.length <= 0) {
            toast.error('Please fill the required fields..', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        } else {
            mainForm.action = [...new Set(mainForm.actionDetails.map(actions => actions.action))];
            mainForm.html = htmlData;
            mainForm.json = jsonData;
            mainForm.formSettings = formSettings;
            // console.log('Form data:', mainForm);
            try {
                let url;
                if (fId) {
                    mainForm.id = fId;
                    url = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/updateCloneForm`, mainForm, {
                        withCredentials: true
                    });
                } else {
                    url = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/saveForm`, mainForm, {
                        withCredentials: true
                    });
                }
                const api = url;
                const response = api.data;
                if (response) {
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    })
                    closeformChannelDataModel()
                    updateFormCardStatus();
                    setSave(false)
                } else {
                    toast.error('Something went wrong please try again later', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    setSave(false)
                }
            } catch (error) {
                console.log(error);
            }
        }

    };

        const fetchColumns = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/getColumns`, {
                    withCredentials: true
                });
                // console.log(response.data.data)
                const col = response.data.data
                setColumns(col.map(column => ({
                    value: column.alias,
                    label: column.label,
                })));

            } catch (error) {
                console.log(error);
            }
        }
        

    useEffect(() => {

        if (id != '0' && id !== undefined && channelData.cardType === 'form') {
            fetchColumns();
            const fetchFormDetails = async () => {
                try {
                    const formResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/formByPage/${id}`, {
                        method: 'GET',
                        credentials: 'include',
                    });
                    const data = await formResponse.json();
                    const formData = await data.form;
                    // console.log(formData);
                    if (formData) {
                        setPageUrl(data.pageUrl);
                        setFId(data.formId);
                        setFormElements(formData.json);
                        setFormSettings(formData.formSettings);
                        setDefaultSettings(formData.formSettings);
                        setEditorStatus(true);
                        setMainForm({
                            formName: formData.formName,
                            successfulSubmitAction: formData.successfulSubmitAction,
                            redirectUrlOrMessage: formData.redirectUrlOrMessage,
                            description: formData.description,
                            action: formData.action,
                            actionDetails: formData.actionDetails,
                        });

                    }
                } catch (error) {
                    console.error('Error fetching form:', error);
                }
            };

            fetchFormDetails();
        }
    }, [channelData]);


    const handleInheritStyles = (checked) => {
        if (checked) {
            setFormSettings({
                backgroundColor: "#ffe0e0",
                borderRadius: "10px",
                color: "#000000",
                fontFamily: "Trebuchet MS",
                margin: "10px",
                padding: "30px"
            });
        } else {
            setFormSettings(defaultSettings);
        }
    };



    return (
        <div>
            <SaveModel
                show={save}
                onHide={() => setSave(false)}
                mainForm={mainForm}
                setMainForm={setMainForm}
                saveForm={saveForm}
                editorStatus={editorStatus}
            />
            <Modal
                show={formChannelDataModel}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={closeformChannelDataModel}
                className="form-custom-channelData-modal"
            >
                <Modal.Header>
                    <div className='row' style={{ width: '100%' }}>
                        <div className='col-sm-6'>
                            <h6>{mainForm.formName}</h6>
                            <b><a href={pageUrl} target='_blank' rel="noreferrer">{pageUrl}</a></b>
                        </div>
                        <div className='col-sm-6'>
                            <div className='right-head'>
                                <a href={`${process.env.REACT_APP_PUBLIC_URL}/UserPreview/${fId}`} target='_blank'>preview</a>
                                <button className='sv-button' onClick={() => {
                                    updateFormCardStatus();
                                }}>
                                    <MdPreview />
                                    <span>Save & Close</span>
                                </button>
                                <button className='sv-button' onClick={() => {
                                    saveHtml();
                                }}>
                                    <IoSave />
                                    <span>Update</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {formSettings != '' &&
                        <div className='Form-model' style={{ width: '100%', display: 'flex' }}>
                            <div className='Form-render' style={{ width: '70%', height: '520px' }}>
                                <div className='form-container' ref={formContainer}>
                                    <DndProvider backend={HTML5Backend}>
                                        <form
                                            className="main-form"
                                            ref={form}
                                            id="dynamicForm"
                                            style={{
                                                backgroundColor: formSettings.backgroundColor,
                                                padding: formSettings.padding,
                                                margin: formSettings.margin,
                                                borderRadius: formSettings.borderRadius,
                                                color: formSettings.color,
                                                fontFamily: formSettings.fontFamily
                                            }}
                                            encType="multipart/form-data"
                                            method='post'

                                        >
                                            <div className="row">
                                                {formElements.map((element, index) => (
                                                    <DraggableFormElement
                                                        key={index}
                                                        index={index}
                                                        element={element}
                                                        moveFormElement={moveFormElement}
                                                        handleElementClick={handleElementClick}
                                                        isClicked={clickedElementIndex === index}
                                                    />
                                                ))}
                                            </div>
                                        </form>
                                    </DndProvider>
                                </div>
                            </div>
                            <div className='form-styles-settingd' style={{ width: '30%' }}>
                                <div className="cl-toggle-switch">
                                    <label className="cl-switch" style={{ cursor: 'pointer' }}>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => handleInheritStyles(e.target.checked)}
                                        />
                                        <span></span>
                                    </label>
                                    <span className='m-2' style={{ cursor: 'pointer' }}>inherit style from LP</span>
                                </div>
                                <Tabs
                                    defaultActiveKey="home"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >

                                    <Tab eventKey="home" className='p-2 element-seetings' title="Element Settings">
                                        {selectedElementIndex !== null && selectedElementIndex >= 0 &&
                                            <div className='common-div'>
                                                {validation === false &&
                                                    <button className="bin-button" onClick={removeElement}>
                                                        <svg
                                                            className="bin-top"
                                                            viewBox="0 0 39 7"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <line y1="5" x2="39" y2="5" stroke="white" strokeWidth="4"></line>
                                                            <line
                                                                x1="12"
                                                                y1="1.5"
                                                                x2="26.0357"
                                                                y2="1.5"
                                                                stroke="white"
                                                                strokeWidth="3"
                                                            ></line>
                                                        </svg>
                                                        <svg
                                                            className="bin-bottom"
                                                            viewBox="0 0 33 39"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <mask id="path-1-inside-1_8_19" fill="white">
                                                                <path
                                                                    d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
                                                                ></path>
                                                            </mask>
                                                            <path
                                                                d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                                                fill="white"
                                                                mask="url(#path-1-inside-1_8_19)"
                                                            ></path>
                                                            <path d="M12 6L12 29" stroke="white" strokeWidth="4"></path>
                                                            <path d="M21 6V29" stroke="white" strokeWidth="4"></path>
                                                        </svg>
                                                    </button>
                                                }

                                                {(formElements[selectedElementIndex].type != 'spacebar' && formElements[selectedElementIndex].type != 'recaptcha' && formElements[selectedElementIndex].type != 'heading' && formElements[selectedElementIndex].type != 'submit') &&
                                                    <>
                                                        {validation === false &&
                                                            <>
                                                                <label htmlFor='inputName'>Map field</label>
                                                                <Select
                                                                    value={columns.find(option => option.value === formElements[selectedElementIndex]?.name) || null}
                                                                    onChange={(selectedOption) => handleInputChange('name', selectedOption?.value)}
                                                                    options={columns}
                                                                    isClearable={true}
                                                                />
                                                                <label htmlFor='' className=''>Make Required</label><br />
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-req"
                                                                        value='on'
                                                                        onChange={(e) => handleInputChange('required', e.target.checked ? 'on' : 'off')}
                                                                        checked={formElements[selectedElementIndex].required == 'on' ? true : false} />
                                                                    <div className="slider"></div>
                                                                </label>
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </div>

                                        }

                                        {heading && selectedElementIndex !== null && (
                                            <input
                                                className='form-control mt-2 heading-text'
                                                type='text'
                                                placeholder='Enter text'
                                                value={formElements[selectedElementIndex].label}
                                                onChange={(e) => handleInputChange('text', e.target.value)}
                                            />
                                        )}
                                        {textInput && selectedElementIndex !== null && (

                                            <div className='textinputEditor'>
                                                {validation === false &&
                                                    <>
                                                        <label htmlFor="inputState">Select Input Type</label>
                                                        <select
                                                            id="inputState"
                                                            className="form-control mt-2"
                                                            value={formElements[selectedElementIndex].type}
                                                            onChange={(e) => handleInputChange('type', e.target.value)}
                                                        >
                                                            <option value="text">Text</option>
                                                            <option value="email">Email</option>
                                                            <option value="number">Number</option>
                                                            <option value="password">Password</option>
                                                            <option value="file">File</option>
                                                            <option value="date">Date</option>
                                                            <option value="datetime-local">Date And Time</option>
                                                            <option value="hidden">Hidden</option>
                                                        </select>
                                                    </>
                                                }
                                                <label htmlFor=''>Enter a label Name</label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a label name'
                                                    value={formElements[selectedElementIndex].label}
                                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                                />
                                                <label htmlFor=''>Enter Placeholder</label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a placeholder name'
                                                    value={formElements[selectedElementIndex].placeholder}
                                                    onChange={(e) => handleInputChange('placeholder', e.target.value)}
                                                />


                                            </div>
                                        )}
                                        {button && selectedElementIndex !== null && (
                                            <input
                                                className='form-control mt-2'
                                                type='text'
                                                placeholder='Enter text'
                                                value={formElements[selectedElementIndex].label}
                                                onChange={(e) => handleInputChange('label', e.target.value)}
                                            />
                                        )}
                                        {selectInput && selectedElementIndex !== null && (
                                            <div className='selectinputEditor'>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a label name'
                                                    value={formElements[selectedElementIndex].label}
                                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                                />

                                                {formElements[selectedElementIndex].options?.map((option, i) => (
                                                    <input
                                                        key={i}
                                                        className='form-control mt-2'
                                                        type='text'
                                                        placeholder={`Option ${i + 1}`}
                                                        value={option}
                                                        onChange={(e) => handleSelectOptionsChange(i, e.target.value)}
                                                    />
                                                ))}
                                                <button className='btn btn-success m-2' onClick={addSelectOption}>Add Option</button>
                                                <button className='btn btn-danger m-2' onClick={removeSelectOption}>Remove Option</button>
                                            </div>
                                        )}
                                        {textareaInput && selectedElementIndex !== null && (
                                            <div className='textinputEditor'>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a label name'
                                                    value={formElements[selectedElementIndex].label}
                                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                                />
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a placeholder name'
                                                    value={formElements[selectedElementIndex].placeholder}
                                                    onChange={(e) => handleInputChange('placeholder', e.target.value)}
                                                />
                                            </div>
                                        )}
                                        {checkboxInput && selectedElementIndex !== null && (
                                            <div className='checkboxinputEditor'>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a label name'
                                                    value={formElements[selectedElementIndex].label}
                                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                                />
                                                {formElements[selectedElementIndex].options?.map((option, i) => (
                                                    <input
                                                        key={i}
                                                        className='form-control mt-2'
                                                        type='text'
                                                        placeholder={`Option ${i + 1}`}
                                                        value={option}
                                                        onChange={(e) => handleSelectOptionsChange(i, e.target.value)}
                                                    />
                                                ))}
                                                <button className='btn btn-success m-2' onClick={addSelectOption}>Add </button>
                                                <button className='btn btn-danger m-2 ' onClick={removeSelectOption}>Remove </button>
                                            </div>
                                        )}
                                        {radioInput && selectedElementIndex !== null && (
                                            <div className='radioInputEditor'>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Enter a label name'
                                                    value={formElements[selectedElementIndex].label}
                                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                                />
                                                {formElements[selectedElementIndex].options?.map((option, i) => (
                                                    <input
                                                        key={i}
                                                        className='form-control mt-2'
                                                        type='text'
                                                        placeholder={`Option ${i + 1}`}
                                                        value={option}
                                                        onChange={(e) => handleSelectOptionsChange(i, e.target.value)}
                                                    />
                                                ))}
                                                <button className='btn btn-success m-2' onClick={addSelectOption}>Add Option</button>
                                                <button className='btn btn-danger m-2' onClick={removeSelectOption}>Remove Option</button>
                                            </div>
                                        )}


                                    </Tab>
                                    <Tab eventKey="profile" className='p-3 style-tab' title="Style Settings">

                                        {(textInput || selectInput || textareaInput || checkboxInput || heading || button || radioInput || recaptchaInput || spacebar) && (
                                            <>
                                                {!heading && !button && !spacebar && <div className="cl-toggle-switch">
                                                    <label className="cl-switch"><b>label styling {labelStyling}</b>
                                                        <input type="checkbox" onChange={(e) => setLabelStyling(e.target.checked ? true : false)} />
                                                        <span></span>
                                                    </label>
                                                </div>}
                                                <label htmlFor=''>Width <Icons id="tooltip-width" content='Enter a Width in % like 70%' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Width (e.g., 100%)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.width || '' : formElements[selectedElementIndex].style.width || ''}
                                                    onChange={(e) => handleInputChange('width', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Hieght <Icons id="tooltip-height" content='Enter a Height in px like 100px' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Height (e.g., 50px)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.height || '' : formElements[selectedElementIndex].style.height || ''}
                                                    onChange={(e) => handleInputChange('height', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Background Color <Icons id="tooltip-bg" content='Select Background Color' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='color'
                                                    placeholder='Background Color (e.g., #ffffff)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.backgroundColor || '#000000' : formElements[selectedElementIndex].style.backgroundColor || '#000000'}
                                                    onChange={(e) => handleInputChange('backgroundColor', e.target.value, true)}
                                                />
                                                <button id="reset" onClick={() => handleInputChange('backgroundColor', '', true)}> <MdOutlineFormatColorReset /> Reset</button><br />
                                                <label htmlFor=''>Color <Icons id="tooltip-Color" content='Select Color' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='color'
                                                    placeholder='Color (e.g., #000000)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.color || '#000000' : formElements[selectedElementIndex].style.color || '#000000'}
                                                    onChange={(e) => handleInputChange('color', e.target.value, true)}
                                                /><br />
                                                <label htmlFor=''>Padding <Icons id="tooltip-Padding" content='Enter a Padding in px like 10px' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Padding (e.g., 10px)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.padding || '' : formElements[selectedElementIndex].style.padding || ''}
                                                    onChange={(e) => handleInputChange('padding', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Margin <Icons id="tooltip-Margin" content='Enter a Margin in px like 10px' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Margin (e.g., 10px)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.margin || '' : formElements[selectedElementIndex].style.margin || ''}
                                                    onChange={(e) => handleInputChange('margin', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Border <Icons id="tooltip-Border" content='Enter a Border in px like 1px solid #000000' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Border (e.g., 1px solid #000000)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.border || '' : formElements[selectedElementIndex].style.border || ''}
                                                    onChange={(e) => handleInputChange('border', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Border radius <Icons id="tooltip-Border-radius" content='Enter a Border radius in px like 10px' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Border Radius (e.g., 10px)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.borderRadius || '' : formElements[selectedElementIndex].style.borderRadius || ''}
                                                    onChange={(e) => handleInputChange('borderRadius', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Font size <Icons id="tooltip-Font-size" content='Enter a Font size in px like 10px' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Font Size (e.g., 10px)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.fontSize || '' : formElements[selectedElementIndex].style.fontSize || ''}
                                                    onChange={(e) => handleInputChange('fontSize', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Align <Icons id="tooltip-Align" content='Select Text Align' /></label><br />
                                                <ButtonGroup className='mt-2' aria-label="Basic example">
                                                    <Button variant="light" onClick={(e) => handleInputChange('textAlign', 'left', true)}>Left</Button>
                                                    <Button variant="light" onClick={(e) => handleInputChange('textAlign', 'center', true)}>Center</Button>
                                                    <Button variant="light" onClick={(e) => handleInputChange('textAlign', 'right', true)}>Right</Button>
                                                </ButtonGroup><br />
                                                <label htmlFor=''>Line height <Icons id="tooltip-Line-height" content='Enter a Line Height in 1 like 1.5' /></label>
                                                <input
                                                    className='form-control mt-2'
                                                    type='text'
                                                    placeholder='Line Height (e.g., 1.5)'
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.lineHeight || '' : formElements[selectedElementIndex].style.lineHeight || ''}
                                                    onChange={(e) => handleInputChange('lineHeight', e.target.value, true)}
                                                />
                                                <label htmlFor=''>Font Family <Icons id="tooltip-Font-Family" content='Select Font Family' /></label>
                                                <select
                                                    className="form-control mt-2"
                                                    value={labelStyling ? formElements[selectedElementIndex].labelStyle.fontFamily || '' : formElements[selectedElementIndex].style.fontFamily || ''}
                                                    onChange={(e) => handleInputChange('fontFamily', e.target.value, true)}
                                                >
                                                    <option value="">Select Font Family</option>
                                                    {availableFonts.map((font) => (
                                                        <option key={font} value={font}>
                                                            {font}
                                                        </option>
                                                    ))}
                                                </select>
                                            </>
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FormDataChannel