export const Active =()=>{
    return(
        <button className='active-tag'>
            Active 
        </button>
    )
}
export const Paused =()=>{
    return(
        <p className='paused-tag'>
            Paused
        </p>
    )
}
export const Draft =()=>{
    return(
        <button className='draft-tag'>
            Draft 
        </button>
    )
}
export const Yes =()=>{
    return(
        <p className='y-tag'>
            Y
        </p>
    )
}
export const No =()=>{
    return(
        <p className='n-tag'>
            N
        </p>
    )
}