import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './unlayer.css';


const MyEmailTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/getMyTemplates`,{
      method: 'GET',
      credentials: 'include', 
    })
      .then(response => response.json())
      .then(data => setTemplates(data))
      .catch(error => console.error('Error fetching templates:', error));
  }, []);

  const handleTemplateSelect = (templateId) => {
    navigate(`/myEmailTemplateEditor/${templateId}`);
  };

  return (

    <div className='content-div'>
      <div >
        <div>
          <h3 style={{ textAlign: 'center', marginTop: '5px' }}>My Email Template List</h3>
          <div className='row m-3'>
            {templates.length > 0 && templates.map(template => (
              <div className='col-sm-3 mt-5 card-parent' key={template.id}>
                <div className="card-temp">
                  <div className="card-details">
                    <p className="text-title">{template.templateName}</p>
                  </div>
                  <button className="card-button" onClick={() => handleTemplateSelect(template._id)}>Use</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyEmailTemplates;
