import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './components/CSS/Custom.scss';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import NoMatch from './screens/404';
import Home from './screens/home';
import EventProgram from './screens/eventProgram';
import Program from './screens/Program';
import Setting from "./screens/Settings";
import Journey from './screens/Journey';
import Outcome from './screens/Outcome/Outcome';
import Editor from './screens/formBuilder/Editor';
import Form from './screens/formBuilder/Form';
import FormsList from './screens/formBuilder/FormsList';
import MainJourney from './screens/Journey';
import EmailTemplates from './screens/EmailBuilder/EmailTemplates';
import EmailTemplateEditor from './screens/EmailBuilder/EmailTemplateEditor';
import MyEmailTemplates from './screens/EmailBuilder/MyEmailTemplates';
import MyEmailTemplateEditor from './screens/EmailBuilder/MyEmailTemplateEditor';
import Login from './screens/Login/Login';
import ProtectedRoute from './components/Auth/ProtectedRoute';

import { useParams } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalProvider';
import JourneyEdit from './screens/Journey/JourneyEdit';
import DisplayLp from './screens/PageBuilder/DisplayLp';
import UserPreview from './screens/formBuilder/UserPreview';
import Savetemplate from './screens/PageBuilder/Savetemplate';
const AliasRoute = () => {
  const { alias } = useParams();

  switch (alias) {
    case 'event':
      return <EventProgram />;
    case 'event1':
      return <EventProgram />;
    default:
      return <Navigate to="/noMatch" />;
  }
};
function App() {
  return (
    <Router>
      <GlobalProvider>
        <Routes>

          {/* Public routes */}
          <Route path="/" element={<Login />} />
          <Route path="*" element={<NoMatch />} />
          <Route path="/pages/view/:id" element={<DisplayLp />} />
          <Route path="/UserPreview/:id" element={<UserPreview />} />
          <Route path='/pages/templates/create' element={<Savetemplate programType={'66b1ebb82db00e2fa0db66cd'} templateId={'66f63e23c3ac7def5bfc91b6'}/>}></Route>
          {/* Protected routes using ProtectedRoute */}
          <Route path="/home" element={<ProtectedRoute element={Home} />} />
          <Route path="/setting" element={<ProtectedRoute element={Setting} />} />
          <Route path="/program" element={<ProtectedRoute element={Program} />} />
          <Route path="/program/:alias" element={<ProtectedRoute element={AliasRoute} />} />
          <Route path="/journey/:programId" element={<ProtectedRoute element={Journey} />} />
          <Route path="/outcome/:programId" element={<ProtectedRoute element={Outcome} />} />
          <Route path="/journeyMain" element={<ProtectedRoute element={MainJourney} />} />
          <Route path="/Editor" element={<ProtectedRoute element={Editor} />} />
          <Route path="/preview/:id" element={<ProtectedRoute element={Form} />} />
          <Route path="/formEdit/:id" element={<ProtectedRoute element={Editor} />} />
          <Route path="/formslist" element={<ProtectedRoute element={FormsList} />} />
          <Route path="/emailTemplates" element={<ProtectedRoute element={EmailTemplates} />} />
          <Route path="/emailTemplateEditor/:id" element={<ProtectedRoute element={EmailTemplateEditor} />} />
          <Route path="/myEmailTemplateEditor/:id" element={<ProtectedRoute element={MyEmailTemplateEditor} />} />
          <Route path="/MyEmailTemplates" element={<ProtectedRoute element={MyEmailTemplates} />} />
          <Route path="/JourneyEdit/:programId" element={<ProtectedRoute element={JourneyEdit} />} />

        </Routes>
      </GlobalProvider>
    </Router>
  );
}

export default App;
