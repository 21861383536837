import React, { useState, useRef, useEffect } from 'react';
import './Editor.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import availableFonts from './FontFamily';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableFormElement from './DraggableFormElement';
import { IoSave } from "react-icons/io5";
import { MdPreview } from "react-icons/md";
import PreviewModel from './PreviewModel';
import SaveModel from './SaveModel';
import axios from 'axios';
import { MdOutlineFormatColorReset } from "react-icons/md";
import { BsTextParagraph } from "react-icons/bs";
import { LuFormInput } from "react-icons/lu";
import { GoMultiSelect } from "react-icons/go";
import { LuTextSelect } from "react-icons/lu";
import { BsTextareaResize } from "react-icons/bs";
import { RiCheckboxFill } from "react-icons/ri";
import { IoMdRadioButtonOn } from "react-icons/io";
import { MdVerifiedUser } from "react-icons/md";
import { GoColumns } from "react-icons/go";
import { TbColumns3 } from "react-icons/tb";
import { GrColumns } from "react-icons/gr";
import { RxButton } from "react-icons/rx";
import { MdOutlineSpaceBar } from "react-icons/md";
import { BiSolidCustomize } from "react-icons/bi";
import CustomColumnModal from './CustomColumnModal';
import Select from 'react-select';
import Icons from './Icons';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Editor = () => {
    const [formElements, setFormElements] = useState([]);
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);
    const [textInput, setTextInput] = useState(false);
    const [selectInput, setSelectInput] = useState(false);
    // const [multiSelectInput, setMultiSelectInput] = useState(false);
    const [textareaInput, setTextareaInput] = useState(false);
    const [checkboxInput, setCheckboxInput] = useState(false);
    const [radioInput, setRadioInput] = useState(false);
    const [heading, setHeading] = useState(false);
    const [recaptchaInput, setRecaptchaInput] = useState(false);
    const [button, setButton] = useState(false);
    const [spacebar, setSpacebar] = useState(false);
    const [preview, setPreview] = useState(false);
    const [save, setSave] = useState(false);
    const [labelStyling, setLabelStyling] = useState(false);
    const [columns, setColumns] = useState([]);
    const [unique, setUnique] = useState(0);
    const [editorStatus, setEditorStatus] = useState(false);
    const [mainForm, setMainForm] = useState({
        formName: '',
        successfulSubmitAction: '',
        redirectUrlOrMessage: '',
        description: '',
        action: '',
        actionDetails: ''
    });
    const [formSettings, setFormSettings] = useState({
        backgroundColor: '#ffffff',
        padding: '10px',
        margin: '10px',
        borderRadius: '5px',
        color: '#000000',

    });
    const form = useRef();
    const navigate = useNavigate();
    const [showCustomColumnModal, setShowCustomColumnModal] = useState(false);

    const handleCloseCustomColumnModal = () => setShowCustomColumnModal(false);
    const handleShowCustomColumnModal = () => setShowCustomColumnModal(true);

    const addCustomColumn = (selectedElements) => {
        if (selectedElements.length <= 0) {
            toast.error('Please select any element to add', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return
        }

        const arrLength = selectedElements.length;
        let number = arrLength % 2 === 0 ? arrLength : arrLength + 1;
        number = Math.ceil(12 / number);

        number = Math.max(1, Math.min(number, 12));

        const newElements = selectedElements.map((elementType, index) => (
            {
                type: elementType,
                label: elementType.charAt(0).toUpperCase() + elementType.slice(1),
                labelStyle: {},
                name: `${elementType}${index + 1}${unique}`,
                placeholder: `Enter ${elementType} here`,
                required: 'off',
                style: {},
                columns: number,
            }));
        setUnique(pre => pre + 1);

        setFormElements(prevFormElements => [...prevFormElements, ...newElements]);
    };

    const checkSubmitButton = (type) => {
        const submitButtonExists = formElements.some(element => element.type === 'submit');
        let text = ''
        if (type === 'submit') {
            text = 'Only one Button can be added in form!';
        } else {
            text = 'Unable to add Element after Button please remove button to Element .';
        }
        if (submitButtonExists) {
            toast.error(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return true;
        }

        return false;
    };
    const addTextInput = () => {
        const newElement = {
            type: 'text',
            label: 'Label',
            labelStyle: { color: '#000' },
            name: `defaultTextInput${unique}`,
            placeholder: 'Enter text here',
            required: 'off',
            style: { color: '#000' }
        };
        setUnique(pre => pre + 1);
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };

    const addSelectInput = () => {
        const newElement = {
            type: 'select',
            label: 'Select',
            name: `defaultSelectInput${unique}`,
            labelStyle: { color: '#000' },
            options: ['Option 1', 'Option 2', 'Option 3'],
            style: { color: '#000' },
            required: 'off',
        };
        setUnique(pre => pre + 1);
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };
    const addMultiSelectInput = () => {
        const newElement = {
            type: 'multi-select',
            label: 'Multi-Select',
            labelStyle: { color: '#000' },
            name: `defaultMultiSelectInput${unique}`,
            options: ['Option 1', 'Option 2', 'Option 3'],
            style: { color: '#000' },
            required: 'off',
        };
        setUnique(pre => pre + 1);
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };


    const addTextareaInput = () => {
        const newElement = {
            type: 'textarea',
            label: 'Textarea',
            labelStyle: { color: '#000' },
            name: `defaultTextareaInput${unique}`,
            placeholder: 'Enter text here',
            style: { color: '#000' }
        };
        setUnique(pre => pre + 1);
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };

    const addCheckboxInput = () => {
        const newElement = {
            type: 'checkbox',
            label: 'Checkbox',
            labelStyle: { color: '#000' },
            name: `defaultCheckboxInput${unique}[]`,
            options: ['Option 1', 'Option 2', 'Option 3'],
            style: { color: '#000' }
        };
        setUnique(pre => pre + 1);
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };

    const addHeading = () => {
        const newElement = {
            type: 'heading',
            text: 'hello world',
            style: { color: '#000' }
        }
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    }
    const handleInputChange = (property, value, isStyle = false) => {
        const updatedElements = [...formElements];
        const currentElement = updatedElements[selectedElementIndex];
        if (labelStyling) {
            updatedElements[selectedElementIndex].labelStyle = {
                ...currentElement.labelStyle,
                [property]: value
            };
        } else if (isStyle && !labelStyling) {
            updatedElements[selectedElementIndex].style = {
                ...currentElement.style,
                [property]: value
            };
        } else {
            if (property === 'label' && currentElement.name?.startsWith('default') || (!(currentElement.name) && property === 'label')) {
                updatedElements[selectedElementIndex] = {
                    ...currentElement,
                    label: value,
                    name: `default_${value}`
                };
            } else if (property === 'name' && value === undefined) {
                updatedElements[selectedElementIndex] = {
                    ...currentElement,
                    [property]: `default_${unique}`
                };
            } else {
                updatedElements[selectedElementIndex] = {
                    ...currentElement,
                    [property]: value
                };
            }
        }
        console.log(updatedElements);

        setFormElements(updatedElements);
    };

    const addColumn = (numColumns) => {
        setUnique((pre) => pre + 1);
        const columnWidth = 12 / numColumns;
        const newElements = Array.from({ length: numColumns }, (_, index) => ({
            type: 'text',
            label: `Column ${index + 1}`,
            labelStyle: { color: '#000' },
            name: `column${index + 1}${unique}`,
            placeholder: `Enter text for column ${index + 1}`,
            required: 'off',
            style: { color: '#000' },
            columns: columnWidth,
        }));
        if (checkSubmitButton(newElements.type)) return;
        setFormElements([...formElements, ...newElements]);
    };
    const addSubmitButton = () => {

        const newElement = {
            type: 'submit',
            label: 'Submit',
            style: {
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                padding: '10px 20px',
                cursor: 'pointer',
                fontSize: '16px'
            },
        };
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };
    const addRecaptchaElement = () => {
        const hasRecaptcha = formElements.some(element => element.type === 'recaptcha');
        const newElements = {
            type: 'recaptcha',
            siteKey: '6LfU_CkqAAAAAHXBYn5d52US5eSfx0OAoNnzM1qP',
            style: { margin: '10px 0' }
        }
        if (checkSubmitButton(newElements.type)) return;
        if (!hasRecaptcha) {
            setFormElements([
                ...formElements,
                newElements
            ]);
        } else {
            toast.error('Only one reCaptcha can be added in form!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
    };
    const addRadioButtonInput = () => {
        const newElement = {
            type: 'radio',
            label: 'Radio Button',
            labelStyle: { color: '#000' },
            name: 'defaultRadioInput',
            options: ['Option 1', 'Option 2', 'Option 3'],
            style: { color: '#000' }
        };
        if (checkSubmitButton(newElement.type)) return;
        setFormElements([...formElements, newElement]);
    };
    const addSpaceBar = () => {
        const newElement = {
            type: 'spacebar',
            style: { height: '20px', width: '100%' }
        };
        setFormElements([...formElements, newElement]);
    };
    const [clickedElementIndex, setClickedElementIndex] = useState(null);

    const handleElementClick = (index) => {
        console.log(index);

        setSelectedElementIndex(index);
        setClickedElementIndex(index);
        const element = formElements[index];
        console.log(element);


        setTextInput(false);
        setSelectInput(false);
        setTextareaInput(false);
        setCheckboxInput(false);
        setRadioInput(false);
        setHeading(false);
        setButton(false);
        setRecaptchaInput(false);
        setSpacebar(false);

        switch (element.type) {
            case 'text':
            case 'email':
            case 'number':
            case 'password':
            case 'file':
            case 'date':
            case 'datetime-local':
            case 'hidden':
                setTextInput(true);
                break;
            case 'select':
            case 'multi-select':
                setSelectInput(true);
                break;
            case 'textarea':
                setTextareaInput(true);
                break;
            case 'checkbox':
                setCheckboxInput(true);
                break;
            case 'heading':
                setHeading(true);
                break;
            case 'submit':
                setButton(true);
                break;
            case 'radio':
                setRadioInput(true);
                break;
            case 'recaptcha':
                setRecaptchaInput(true);
                break;
            case 'spacebar':
                setSpacebar(true);
                break;
            default:
                break;
        }
    };

    const removeElement = () => {
        if (selectedElementIndex !== null && selectedElementIndex >= 0) {
            setTextInput(false);
            setSelectInput(false);
            setTextareaInput(false);
            setCheckboxInput(false);
            setRadioInput(false);
            setHeading(false);
            setButton(false);
            setRadioInput(false);
            setRecaptchaInput(false);
            setSpacebar(false);
            const updatedElements = formElements.filter((_, index) => index !== selectedElementIndex);
            setFormElements(updatedElements);
            setSelectedElementIndex(null);
            toast.success('Element removed Successfully!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });

        }
    };



    const handleSelectOptionsChange = (index, value) => {
        const updatedElements = [...formElements];
        updatedElements[selectedElementIndex].options[index] = value;
        setFormElements(updatedElements);
    };

    const addSelectOption = () => {
        const updatedElements = [...formElements];
        const options = updatedElements[selectedElementIndex].options || [];
        options.push('New Option');
        updatedElements[selectedElementIndex].options = options;
        setFormElements(updatedElements);
    };

    const removeSelectOption = () => {
        const updatedElements = [...formElements];
        const options = updatedElements[selectedElementIndex].options || [];
        if (options.length > 1) {
            options.pop();
        } else {
            toast.warn('This element requires atleast one option ', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
        updatedElements[selectedElementIndex].options = options;
        setFormElements(updatedElements);
    };


    const moveFormElement = (fromIndex, toIndex) => {
        const updatedElements = [...formElements];
        const [movedElement] = updatedElements.splice(fromIndex, 1);
        updatedElements.splice(toIndex, 0, movedElement);
        setFormElements(updatedElements);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (formElements.length > 0) {
                const message = 'You have unsaved changes. Are you sure you want to leave?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formElements]);

    useEffect(() => {
        const fetchColumns = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/getColumns`, {
                    withCredentials: true
                });
                // console.log(response.data.data)
                const col = response.data.data
                setColumns(col.map(column => ({
                    value: column.alias,
                    label: column.label,
                })));

            } catch (error) {
                console.log(error);
            }
        }
        fetchColumns();

    }, []);


    const formContainer = useRef();
    const [htmlData, setHtmlData] = useState('');
    const [jsonData, setJsonData] = useState([]);
    const saveHtml = () => {
        if (formElements.length > 0) {
            if (formContainer.current) {
                const bootstrapLink = '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">';
                const htmlContent = formContainer.current.innerHTML;
                const fullHtmlContent = htmlContent;
                const jsonContent = formElements;
                setSave(true);
                setHtmlData(fullHtmlContent);
                setJsonData(jsonContent)
            }
        } else {
            toast.warn('Please add a Elements to save ', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }

    };
    const onShow = () => {
        if (formElements.length > 0) {
            setPreview(true)
        } else {
            toast.warn('Please add a Elements to preview', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
    }

    const saveForm = async () => {

        // console.log(mainForm);
        // return
        if (mainForm.formName === '' || mainForm.redirectUrl === '' || mainForm.successfulSubmitAction === '' || mainForm.description === '' || mainForm.action === '' || mainForm.actionDetails.length <= 0) {
            toast.error('Please fill the required fields..', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        } else {
            mainForm.action = [...new Set(mainForm.actionDetails.map(actions => actions.action))];
            mainForm.html = htmlData;
            mainForm.json = jsonData;
            mainForm.formSettings = formSettings;
            console.log('Form data:', mainForm);
            try {
                let url;
                if (id) {
                    mainForm.id = id;
                    url = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/updateForm`, mainForm, {
                        withCredentials: true
                    });
                } else {
                    url = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/saveForm`, mainForm, {
                        withCredentials: true
                    });
                }
                const api = url;
                const response = api.data;
                if (response) {
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    })
                    setTimeout(() => {
                        navigate('/formslist');
                    }, 2000);
                    setSave(false)
                } else {
                    toast.error('Something went wrong please try again later', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    setSave(false)
                }
            } catch (error) {
                console.log(error);
            }
        }

    };
    //for editing if opened for editing pupose 
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            fetch(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/formEdit/${id}`,{
                method: 'GET',
                credentials: 'include', 
              })
                .then(res => res.json())
                .then(data => {
                    setFormElements(data.json);
                    setFormSettings(data.formSettings);
                    setEditorStatus(true);
                    setMainForm({
                        formName: data.formName,
                        successfulSubmitAction: data.successfulSubmitAction,
                        redirectUrlOrMessage: data.redirectUrlOrMessage,
                        description: data.description,
                        action: data.action,
                        actionDetails: data.actionDetails
                    })
                })
                .catch(error => console.error('Error fetching form:', error));
        }
    }, [id]);
    return (
        <div className='main-body'>
            <CustomColumnModal
                show={showCustomColumnModal}
                handleClose={handleCloseCustomColumnModal}
                addCustomColumn={addCustomColumn}
            />
            <SaveModel
                show={save}
                onHide={() => setSave(false)}
                mainForm={mainForm}
                setMainForm={setMainForm}
                saveForm={saveForm}
                editorStatus={editorStatus}
            />
            <PreviewModel show={preview} onHide={() => setPreview(false)} formHtml={formElements.length > 0 ? formContainer.current.innerHTML : ''} />
            <div className='header'>
                <div className='left-head'>

                </div>
                <div className='right-head'>
                    <button className='sv-button' onClick={onShow}>
                        <MdPreview />
                        <span>Preview</span>
                    </button>
                    <button className='sv-button' onClick={saveHtml}>
                        <IoSave />
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div className='main'>
                <div className='left-bar'>
                    <ToastContainer />
                    <Tabs defaultActiveKey="element" id="fill-tab-example" className="mb-3" fill>
                        <Tab eventKey="element" title="Elements">
                            <div className='elements-div'>
                                {/* <OverlayTrigger
                                    key='right'
                                    placement='right'
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>
                                            <strong>click on any element to add</strong>.
                                        </Tooltip>
                                    }
                                > */}
                                <ListGroup>
                                    <ListGroup.Item action onClick={addHeading}>
                                        <BsTextParagraph /> Paragraph / Heading
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addTextInput}>
                                        <LuFormInput /> Add Text Input
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addSelectInput}>
                                        <GoMultiSelect /> Add Select Input
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addMultiSelectInput}>
                                        <LuTextSelect /> Add Multi Select Input
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addTextareaInput}>
                                        <BsTextareaResize /> Add Textarea
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addCheckboxInput}>
                                        <RiCheckboxFill /> Add Checkbox
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addRadioButtonInput}>
                                        <IoMdRadioButtonOn /> Add Radio Button
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addRecaptchaElement}>
                                        <MdVerifiedUser /> Add Recaptcha
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={() => addColumn(2)}>
                                        <GoColumns /> Add 2 Columns
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={() => addColumn(3)}>
                                        <TbColumns3 /> Add 3 Columns
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={() => addColumn(4)}>
                                        <GrColumns /> Add 4 Columns
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={handleShowCustomColumnModal}>
                                        <BiSolidCustomize /> Custom  Columns
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addSubmitButton}>
                                        <RxButton /> Add Submit Button
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={addSpaceBar}>
                                        <MdOutlineSpaceBar /> Add Space Bar
                                    </ListGroup.Item>
                                </ListGroup>
                                {/* </OverlayTrigger> */}
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Form Setting">
                            <div className="form-settings">
                                {/* <div className="form-group">
                                    <label>Action URL:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formSettings.actionUrl}
                                        onChange={(e) => setFormSettings({ ...formSettings, actionUrl: e.target.value })}
                                        placeholder="Enter form action URL"
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label>Background Color <Icons id="tooltip-fbg" content='Select Background Color for overall form' /></label>
                                    <input
                                        type="color"
                                        className="form-control"
                                        value={formSettings.backgroundColor}
                                        onChange={(e) => setFormSettings({ ...formSettings, backgroundColor: e.target.value })}
                                    />
                                    <button id="reset" onClick={(e) => setFormSettings({ ...formSettings, backgroundColor: '#ffffff' })}> <MdOutlineFormatColorReset /> Reset</button>
                                </div>
                                <div className="form-group">
                                    <label>Text Color <Icons id="tooltip-fColor" content='Select Color' /></label>
                                    <input
                                        type="color"
                                        className="form-control"
                                        value={formSettings.color}
                                        onChange={(e) => setFormSettings({ ...formSettings, color: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Padding <Icons id="tooltip-fPadding" content='Enter a Padding in px like 10px' /></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formSettings.padding}
                                        onChange={(e) => setFormSettings({ ...formSettings, padding: e.target.value })}
                                        placeholder="Enter padding (e.g., 10px)"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Margin <Icons id="tooltip-fMargin" content='Enter a Padding in px like 10px' /></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formSettings.margin}
                                        onChange={(e) => setFormSettings({ ...formSettings, margin: e.target.value })}
                                        placeholder="Enter margin (e.g., 10px)"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Border Radius <Icons id="tooltip-fBorder-radius" content='Enter a Border radius in px like 10px' /></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formSettings.borderRadius}
                                        onChange={(e) => setFormSettings({ ...formSettings, borderRadius: e.target.value })}
                                        placeholder="Enter border radius (e.g., 5px)"
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Select font family <Icons id="tooltip-fFont-Family" content='Select Font Family' /></label>
                                    <select
                                        className="form-control  mt-2"
                                        value={formSettings.fontFamily}
                                        onChange={(e) => setFormSettings({ ...formSettings, fontFamily: e.target.value })}
                                    >
                                        <option value="">Select Font Family</option>
                                        {availableFonts.map((font) => (
                                            <option key={font} value={font}>
                                                {font}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="prebuiltforms" title="Pre Built Forms">
                            pre built forms
                        </Tab>
                    </Tabs>
                </div>
                <div className='middle-bar'>
                    <div className='form-container' ref={formContainer}>
                        <DndProvider backend={HTML5Backend}>
                            <form
                                className="main-form"
                                ref={form}
                                id="dynamicForm"
                                style={{
                                    backgroundColor: formSettings.backgroundColor,
                                    padding: formSettings.padding,
                                    margin: formSettings.margin,
                                    borderRadius: formSettings.borderRadius,
                                    color: formSettings.color,
                                    fontFamily: formSettings.fontFamily
                                }}
                                encType="multipart/form-data"
                                method='post'

                            >
                                <div className="row">
                                    {formElements.map((element, index) => (
                                        <DraggableFormElement
                                            key={index}
                                            index={index}
                                            element={element}
                                            moveFormElement={moveFormElement}
                                            handleElementClick={handleElementClick}
                                            isClicked={clickedElementIndex === index}
                                        />
                                    ))}
                                </div>
                            </form>
                        </DndProvider>
                    </div>
                </div>
                <div className='right-bar'>
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >

                        <Tab eventKey="home" className='p-2 element-seetings' title="Element Settings">
                            {selectedElementIndex !== null && selectedElementIndex >= 0 &&
                                <div className='common-div'>
                                    <button className="bin-button" onClick={removeElement}>
                                        <svg
                                            className="bin-top"
                                            viewBox="0 0 39 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line y1="5" x2="39" y2="5" stroke="white" strokeWidth="4"></line>
                                            <line
                                                x1="12"
                                                y1="1.5"
                                                x2="26.0357"
                                                y2="1.5"
                                                stroke="white"
                                                strokeWidth="3"
                                            ></line>
                                        </svg>
                                        <svg
                                            className="bin-bottom"
                                            viewBox="0 0 33 39"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask id="path-1-inside-1_8_19" fill="white">
                                                <path
                                                    d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
                                                ></path>
                                            </mask>
                                            <path
                                                d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                                fill="white"
                                                mask="url(#path-1-inside-1_8_19)"
                                            ></path>
                                            <path d="M12 6L12 29" stroke="white" strokeWidth="4"></path>
                                            <path d="M21 6V29" stroke="white" strokeWidth="4"></path>
                                        </svg>
                                    </button>

                                    {(formElements[selectedElementIndex].type != 'spacebar' && formElements[selectedElementIndex].type != 'recaptcha' && formElements[selectedElementIndex].type != 'heading' && formElements[selectedElementIndex].type != 'submit') &&
                                        <>
                                            <label htmlFor='inputName'>Map field</label>
                                            {/* <select
                                                className='form-control mt-2'
                                                value={formElements[selectedElementIndex]?.name || ''}
                                                onChange={(e) => handleInputChange('name', e.target.value)}
                                            >
                                                <option value="" disabled>Select an input name</option>
                                                {columns.map((column) => (
                                                    <option key={column._id} value={column.alias}>
                                                        {column.label}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <Select
                                                value={columns.find(option => option.value === formElements[selectedElementIndex]?.name) || null}
                                                onChange={(selectedOption) => handleInputChange('name', selectedOption?.value)}
                                                options={columns}
                                                isClearable={true}
                                            />
                                            <label htmlFor='' className=''>Make Required</label><br />
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-req"
                                                    value='on'
                                                    onChange={(e) => handleInputChange('required', e.target.checked ? 'on' : 'off')}
                                                    checked={formElements[selectedElementIndex].required == 'on' ? true : false} />
                                                <div className="slider"></div>
                                            </label>
                                        </>
                                    }

                                </div>

                            }

                            {heading && selectedElementIndex !== null && (
                                <input
                                    className='form-control mt-2 heading-text'
                                    type='text'
                                    placeholder='Enter text'
                                    value={formElements[selectedElementIndex].label}
                                    onChange={(e) => handleInputChange('text', e.target.value)}
                                />
                            )}
                            {textInput && selectedElementIndex !== null && (

                                <div className='textinputEditor'>
                                    <label htmlFor="inputState">Select Input Type</label>
                                    <select
                                        id="inputState"
                                        className="form-control mt-2"
                                        value={formElements[selectedElementIndex].type}
                                        onChange={(e) => handleInputChange('type', e.target.value)}
                                    >
                                        <option value="text">Text</option>
                                        <option value="email">Email</option>
                                        <option value="number">Number</option>
                                        <option value="password">Password</option>
                                        <option value="file">File</option>
                                        <option value="date">Date</option>
                                        <option value="datetime-local">Date And Time</option>
                                        <option value="hidden">Hidden</option>
                                    </select>
                                    <label htmlFor=''>Enter a label Name</label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a label name'
                                        value={formElements[selectedElementIndex].label}
                                        onChange={(e) => handleInputChange('label', e.target.value)}
                                    />
                                    <label htmlFor=''>Enter Placeholder</label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a placeholder name'
                                        value={formElements[selectedElementIndex].placeholder}
                                        onChange={(e) => handleInputChange('placeholder', e.target.value)}
                                    />


                                </div>
                            )}
                            {button && selectedElementIndex !== null && (
                                <input
                                    className='form-control mt-2'
                                    type='text'
                                    placeholder='Enter text'
                                    value={formElements[selectedElementIndex].label}
                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                />
                            )}
                            {selectInput && selectedElementIndex !== null && (
                                <div className='selectinputEditor'>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a label name'
                                        value={formElements[selectedElementIndex].label}
                                        onChange={(e) => handleInputChange('label', e.target.value)}
                                    />

                                    {formElements[selectedElementIndex].options?.map((option, i) => (
                                        <input
                                            key={i}
                                            className='form-control mt-2'
                                            type='text'
                                            placeholder={`Option ${i + 1}`}
                                            value={option}
                                            onChange={(e) => handleSelectOptionsChange(i, e.target.value)}
                                        />
                                    ))}
                                    <button className='btn btn-success m-2' onClick={addSelectOption}>Add Option</button>
                                    <button className='btn btn-danger m-2' onClick={removeSelectOption}>Remove Option</button>
                                </div>
                            )}
                            {textareaInput && selectedElementIndex !== null && (
                                <div className='textinputEditor'>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a label name'
                                        value={formElements[selectedElementIndex].label}
                                        onChange={(e) => handleInputChange('label', e.target.value)}
                                    />
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a placeholder name'
                                        value={formElements[selectedElementIndex].placeholder}
                                        onChange={(e) => handleInputChange('placeholder', e.target.value)}
                                    />
                                </div>
                            )}
                            {checkboxInput && selectedElementIndex !== null && (
                                <div className='checkboxinputEditor'>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a label name'
                                        value={formElements[selectedElementIndex].label}
                                        onChange={(e) => handleInputChange('label', e.target.value)}
                                    />
                                    {formElements[selectedElementIndex].options?.map((option, i) => (
                                        <input
                                            key={i}
                                            className='form-control mt-2'
                                            type='text'
                                            placeholder={`Option ${i + 1}`}
                                            value={option}
                                            onChange={(e) => handleSelectOptionsChange(i, e.target.value)}
                                        />
                                    ))}
                                    <button className='btn btn-success m-2' onClick={addSelectOption}>Add </button>
                                    <button className='btn btn-danger m-2 ' onClick={removeSelectOption}>Remove </button>
                                </div>
                            )}
                            {radioInput && selectedElementIndex !== null && (
                                <div className='radioInputEditor'>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Enter a label name'
                                        value={formElements[selectedElementIndex].label}
                                        onChange={(e) => handleInputChange('label', e.target.value)}
                                    />
                                    {formElements[selectedElementIndex].options?.map((option, i) => (
                                        <input
                                            key={i}
                                            className='form-control mt-2'
                                            type='text'
                                            placeholder={`Option ${i + 1}`}
                                            value={option}
                                            onChange={(e) => handleSelectOptionsChange(i, e.target.value)}
                                        />
                                    ))}
                                    <button className='btn btn-success m-2' onClick={addSelectOption}>Add Option</button>
                                    <button className='btn btn-danger m-2' onClick={removeSelectOption}>Remove Option</button>
                                </div>
                            )}


                        </Tab>
                        <Tab eventKey="profile" className='p-3 style-tab' title="Style Settings">

                            {(textInput || selectInput || textareaInput || checkboxInput || heading || button || radioInput || recaptchaInput || spacebar) && (
                                <>
                                    {!heading && !button && !spacebar && <div className="cl-toggle-switch">
                                        <label className="cl-switch"><b>label styling {labelStyling}</b>
                                            <input type="checkbox" onChange={(e) => setLabelStyling(e.target.checked ? true : false)} />
                                            <span></span>
                                        </label>
                                    </div>}
                                    <label htmlFor=''>Width <Icons id="tooltip-width" content='Enter a Width in % like 70%' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Width (e.g., 100%)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.width || '' : formElements[selectedElementIndex].style.width || ''}
                                        onChange={(e) => handleInputChange('width', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Hieght <Icons id="tooltip-height" content='Enter a Height in px like 100px' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Height (e.g., 50px)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.height || '' : formElements[selectedElementIndex].style.height || ''}
                                        onChange={(e) => handleInputChange('height', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Background Color <Icons id="tooltip-bg" content='Select Background Color' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='color'
                                        placeholder='Background Color (e.g., #ffffff)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.backgroundColor || '#000000' : formElements[selectedElementIndex].style.backgroundColor || '#000000'}
                                        onChange={(e) => handleInputChange('backgroundColor', e.target.value, true)}
                                    />
                                    <button id="reset" onClick={() => handleInputChange('backgroundColor', '', true)}> <MdOutlineFormatColorReset /> Reset</button><br />
                                    <label htmlFor=''>Color <Icons id="tooltip-Color" content='Select Color' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='color'
                                        placeholder='Color (e.g., #000000)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.color || '#000000' : formElements[selectedElementIndex].style.color || '#000000'}
                                        onChange={(e) => handleInputChange('color', e.target.value, true)}
                                    /><br />
                                    <label htmlFor=''>Padding <Icons id="tooltip-Padding" content='Enter a Padding in px like 10px' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Padding (e.g., 10px)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.padding || '' : formElements[selectedElementIndex].style.padding || ''}
                                        onChange={(e) => handleInputChange('padding', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Margin <Icons id="tooltip-Margin" content='Enter a Margin in px like 10px' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Margin (e.g., 10px)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.margin || '' : formElements[selectedElementIndex].style.margin || ''}
                                        onChange={(e) => handleInputChange('margin', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Border <Icons id="tooltip-Border" content='Enter a Border in px like 1px solid #000000' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Border (e.g., 1px solid #000000)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.border || '' : formElements[selectedElementIndex].style.border || ''}
                                        onChange={(e) => handleInputChange('border', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Border radius <Icons id="tooltip-Border-radius" content='Enter a Border radius in px like 10px' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Border Radius (e.g., 10px)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.borderRadius || '' : formElements[selectedElementIndex].style.borderRadius || ''}
                                        onChange={(e) => handleInputChange('borderRadius', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Font size <Icons id="tooltip-Font-size" content='Enter a Font size in px like 10px' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Font Size (e.g., 10px)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.fontSize || '' : formElements[selectedElementIndex].style.fontSize || ''}
                                        onChange={(e) => handleInputChange('fontSize', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Align <Icons id="tooltip-Align" content='Select Text Align' /></label><br />
                                    <ButtonGroup className='mt-2' aria-label="Basic example">
                                        <Button variant="light" onClick={(e) => handleInputChange('textAlign', 'left', true)}>Left</Button>
                                        <Button variant="light" onClick={(e) => handleInputChange('textAlign', 'center', true)}>Center</Button>
                                        <Button variant="light" onClick={(e) => handleInputChange('textAlign', 'right', true)}>Right</Button>
                                    </ButtonGroup><br />
                                    <label htmlFor=''>Line height <Icons id="tooltip-Line-height" content='Enter a Line Height in 1 like 1.5' /></label>
                                    <input
                                        className='form-control mt-2'
                                        type='text'
                                        placeholder='Line Height (e.g., 1.5)'
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.lineHeight || '' : formElements[selectedElementIndex].style.lineHeight || ''}
                                        onChange={(e) => handleInputChange('lineHeight', e.target.value, true)}
                                    />
                                    <label htmlFor=''>Font Family <Icons id="tooltip-Font-Family" content='Select Font Family' /></label>
                                    <select
                                        className="form-control mt-2"
                                        value={labelStyling ? formElements[selectedElementIndex].labelStyle.fontFamily || '' : formElements[selectedElementIndex].style.fontFamily || ''}
                                        onChange={(e) => handleInputChange('fontFamily', e.target.value, true)}
                                    >
                                        <option value="">Select Font Family</option>
                                        {availableFonts.map((font) => (
                                            <option key={font} value={font}>
                                                {font}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default Editor;
