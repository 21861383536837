import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';

const PerformanceChart = ({ data, overallPerformance, showIndividualPercentages, projectedLabel = 'Projected', actualLabel = 'Actual', overallLabel = 'Overall Performance' }) => {
  
    // Calculate the percentage difference for each item and the overall performance

    // Calculate the percentage difference for each item and the overall performance
    const calculateDataWithPercentage = data.map((item) => {
        let percentageDifference;
        
        // Check if Projected is zero or undefined to avoid division by zero or invalid calculations
        if (item.Projected === 0 || item.Projected === undefined || item.Actual === undefined) {
            percentageDifference = 0; // Set to 0 or any fallback value as per your requirement
        } else {
            percentageDifference = ((item.Actual ) / item.Projected) * 100;
        }
    
        return { ...item, PercentageDifference: percentageDifference.toFixed(1) };
    });

    // Custom tooltip to display the bar name, projected, actual, and percentage change
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className='lbl-heading'>{label}</p>
                    <p className='lbl-project'>{projectedLabel}: {payload[0].value}</p>
                    <p className='lbl-actual'>{actualLabel}: {payload[1].value}</p>
                </div>
            );
        }
        return null;
    };

    // Custom Legend with approximate colors to match the gradient
    const CustomLegend = ({ payload }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px' }}>
                {payload.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                backgroundColor: entry.value === projectedLabel ? '#028897' : '#81CDD5',
                                borderRadius: '50%',
                            }}
                        ></span>
                        <span style={{ fontSize: '14px' }}>{entry.value}</span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto', height: 250 }}>
            <ResponsiveContainer>
                <BarChart data={calculateDataWithPercentage} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <defs>
                        <linearGradient id="colorProjected" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#028897" />
                            <stop offset="100%" stopColor="#045A64" />
                        </linearGradient>
                    </defs>

                    <CartesianGrid strokeDasharray="4 4" vertical={false} />
                    <XAxis dataKey="name" style={{ fontSize: '10px' }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} verticalAlign="top" />

                    <Bar dataKey="Projected" name={projectedLabel} fill="url(#colorProjected)" radius={[5, 5, 0, 0]} />
                    <Bar dataKey="Actual" name={actualLabel} fill="#81CDD5" radius={[5, 5, 0, 0]} />
                </BarChart>
            </ResponsiveContainer>

            {showIndividualPercentages && (
                <div style={{ margin: '-10px 20px 0px 20px', textAlign: '' }}>
                    <div className="individual-percentage d-flex" style={{ justifyContent: 'space-around', flexWrap: 'nowrap', display: 'flex' }}>
                        {calculateDataWithPercentage.map((item) => (
                            <div key={item.name} style={{ margin: '0 10px' }}>
                                <div
                                    style={{
                                        color: item.PercentageDifference < 0 ? 'red' : 'green',
                                        backgroundColor: item.PercentageDifference < 0 ? '#f8d7da' : '#d4edda',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        fontSize: '12px',
                                    }}
                                >
                                    {item.PercentageDifference}%
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className='row' style={{marginTop: '10px' ,padding: '0px 25px' }}>
                <div className="col-sm-12 overall">
                    <div className='d-flex justify-content-between' >
                        <div className='ovr-permformance'>{overallLabel} </div>
                    <div  className='percen' style={{ color: overallPerformance < 0 ? 'red' : 'green'}}>
                        {overallPerformance}%
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerformanceChart;
