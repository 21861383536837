import { useEffect, useRef, useState } from "react";
import CardComp from "./journeyModels/CardComp";

function RenderCardGroup({ CardData, CardType, groupIndex, handleChannelModel, fetchProgramDetails, onRenderComplete, animation }) {
    const [renderedCards, setRenderedCards] = useState(0);
    const [currentGroup, setCurrentGroup] = useState(groupIndex);
    const elementsRefs = useRef({});
    const getClassName = (data) => {
        // const hasReachForm = data.some(item => item.channel === 'reach_form_label');
        // const hasMetaAdForm = data.some(item => item.channel === 'lead_meta_ad_form');
        // const hasLinkedInAdForm = data.some(item => item.channel === 'lead_linkedin_ad_form');
        // const hasGoogleAdForm = data.some(item => item.channel === 'lead_google_ad_form');
        // if (hasReachForm && hasMetaAdForm && hasLinkedInAdForm && !hasGoogleAdForm) {
        //     return 'multiples below-channels';
        // }

        if (data.length === 1) return 'single';
        if (data.length === 2) return 'double';
        if (data.length > 2) return 'multiples';
        return '';
    };
    useEffect(() => {
        if (renderedCards === CardData.length && CardData.length > 0) {
            if (onRenderComplete) {
                // setTimeout(onRenderComplete, 500); 
                onRenderComplete();
                console.log('check');
            }
        }
    }, [renderedCards, CardData]);
    useEffect(() => {
        if (currentGroup === groupIndex && CardData.length > 0 && animation) {
            const intervalId = setInterval(() => {
                setRenderedCards(prevCount => {
                    if (prevCount < CardData.length) {
                        return prevCount + 1;
                    } else {
                        clearInterval(intervalId);
                        setCurrentGroup(prev => prev + 1)
                        // setTimeout(() => , 1000);
                        return prevCount;
                    }
                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
        else {
            setRenderedCards(CardData.length);
        }
    }, []);
    // console.log('this is called',currentGroup,' grp,',renderedCards,'rendered cards',animation);

    return (
        <>
            <div className={`col-sm-3 ${CardType} card-center ${getClassName(CardData)}`}>
                {CardData.slice(0, renderedCards).map((data, index) => (
                    <div className='card-wrap' key={index} id={`box-${data.id}`}
                        ref={(el) => (elementsRefs.current[data.id] = el)}>
                        <CardComp
                            channel={data.channel}
                            no_campains={data.no_campains}
                            expected={data.expected}
                            reach={data.reach}
                            icon={data.icon}
                            status={data.status}
                            isEnabled={data.isEnabled}
                            builder={data.builder}
                            handleChannelModel={handleChannelModel}
                            cardType={data.cardType}
                            cardId={data.cardId}
                            fetchProgramDetails={fetchProgramDetails}
                            numberofcards={renderedCards}
                            groupIndex={groupIndex}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
export default RenderCardGroup;