// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-plus-jakarta-sans: "Plus Jakarta Sans";
  --font-roboto: Roboto;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-base: 16px;

  /* Colors */
  --color-white: #fff;
  --color-darkcyan: #028897;
  --color-dimgray: #555;
  --color-darkgray: #999;
  --color-gray: #222;

  /* Border radiuses */
  --br-7xs: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AAIA;EACE,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,6CAA6C;EAC7C,qBAAqB;EACrB,uBAAuB;;EAEvB,eAAe;EACf,oBAAoB;EACpB,sBAAsB;;EAEtB,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;;EAElB,oBAAoB;EACpB,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600;800&display=swap\");\nbody {\n  margin: 0;\n  line-height: normal;\n}\n:root {\n  /* fonts */\n  --font-plus-jakarta-sans: \"Plus Jakarta Sans\";\n  --font-roboto: Roboto;\n  --font-poppins: Poppins;\n\n  /* font sizes */\n  --font-size-sm: 14px;\n  --font-size-base: 16px;\n\n  /* Colors */\n  --color-white: #fff;\n  --color-darkcyan: #028897;\n  --color-dimgray: #555;\n  --color-darkgray: #999;\n  --color-gray: #222;\n\n  /* Border radiuses */\n  --br-7xs: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
