import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const SegmentListsModel = ({ listModel, handleCloseListModel, channelData, segmentId, programDetails, programId }) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [cardName, setCardName] = useState('');
    const fetchMediaData = async (alias) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/api/labelIcon?alias=${alias}`, { withCredentials: true });
            const data = response.data;
            setCardName(data.name);
        } catch (error) {
            console.error(`Error fetching data for ${alias}:`, error);
            return null;
        }
    };

    const fetchColumns = async () => {
        try {
            const api = await axios.post(
                `${process.env.REACT_APP_BASE_URL_API}/formBuilder/getColumnsByProgram`,
                { programId: programId },
                { withCredentials: true }
            );
            const response = api.data;
            if (response.columns && response.columns.length > 0) {
                // Map the dynamic columns received from the API response
                const dynamicColumns = response.columns.map((col) => {
                    let headerName;
                    switch (col) {
                        case 'firstname':
                            headerName = 'First Name';
                            break;
                        case 'lastname':
                            headerName = 'Last Name';
                            break;
                        case 'email':
                            headerName = 'Email';
                            break;
                        case 'formattedMobile':
                            headerName = 'Mobile Number';
                            break;
                        case 'country':
                            headerName = 'Country';
                            break;
                        case 'state':
                            headerName = 'State';
                            break;
                        case 'city':
                            headerName = 'City';
                            break;
                        case 'company':
                            headerName = 'Company';
                            break;
                        case 'designation':
                            headerName = 'Designation';
                            break;
                        case 'industry':
                            headerName = 'Industry';
                            break;
                        default:
                            headerName = col; // Fallback to the original column name
                    }

                    return { field: col, headerName: headerName, width: 150 };
                });

                // Include mandatory columns
                const mandatoryColumns = [
                    { field: '_id', headerName: 'ID', width: 150 },
                    { field: 'date_added', headerName: 'Created', width: 100 }
                ];

                // Set the state for columns
                setColumns([...mandatoryColumns, ...dynamicColumns]);
            }
        } catch (error) {
            if(error.status===430){
                setColumns([
                    { field: '_id', headerName: 'ID', width: 150 },
                    { field: 'firstname', headerName: 'First Name', width: 150 },
                    { field: 'lastname', headerName: 'Last Name', width: 150 },
                    { field: 'email', headerName: 'Email', width: 200 },
                    { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                    { field: 'country', headerName: 'Country', width: 100 },
                    { field: 'state', headerName: 'State', width: 100 },
                    { field: 'city', headerName: 'City', width: 100 },
                    { field: 'company', headerName: 'Company', width: 100 },
                    { field: 'designation', headerName: 'Designation', width: 100 },
                    { field: 'industry', headerName: 'Industry', width: 100 },
                    { field: 'date_added', headerName: 'Created', width: 100 },
                ]);
            }
            else{
            console.log('Error fetching columns:', error);
            }
        }
    };

    // Fetch segment data based on the segment ID
    const fetchSegmentData = async () => {
        if (channelData.cardId !== '') {
            try {
                setRows([]);
                setError(true);
                const api = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentList`,
                    { segmentId: channelData.cardId },
                    { withCredentials: true }
                );
                const response = api.data;

                if (response && response.length > 0) {
                    const formattedRows = response.map((item) => {
                        const countryCode = item.leadData.country_code;
                        const mobile = item.leadData.mobile;

                        // Logic to format mobile number conditionally
                        let formattedMobile = '';
                        if (countryCode && mobile) {
                            formattedMobile = `+${countryCode}-${mobile}`;
                        } else if (mobile) {
                            formattedMobile = mobile;
                        } else if (countryCode) {
                            formattedMobile = `+${countryCode}`;
                        }

                        return {
                            ...item.leadData,
                            segment_id: item.segment_id,
                            lead_id: item.lead_id,
                            date_added: item.date_added,
                            formattedMobile,
                        };
                    });

                    setRows(formattedRows);
                    setFilteredRows(formattedRows);
                    setError(false);

                    // Fetch dynamic columns if the channel name is 'reach_list_label'
                    if (channelData.name === 'reach_list_label' || channelData.name==='register_list_label') {
                        await fetchColumns();
                    } else {
                        // Set default columns if the condition is not met
                        setColumns([
                            { field: '_id', headerName: 'ID', width: 150 },
                            { field: 'firstname', headerName: 'First Name', width: 150 },
                            { field: 'lastname', headerName: 'Last Name', width: 150 },
                            { field: 'email', headerName: 'Email', width: 200 },
                            { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                            { field: 'country', headerName: 'Country', width: 100 },
                            { field: 'state', headerName: 'State', width: 100 },
                            { field: 'city', headerName: 'City', width: 100 },
                            { field: 'company', headerName: 'Company', width: 100 },
                            { field: 'designation', headerName: 'Designation', width: 100 },
                            { field: 'industry', headerName: 'Industry', width: 100 },
                            { field: 'date_added', headerName: 'Created', width: 100 },
                        ]);
                    }
                } else {
                    setRows([]);
                    setFilteredRows([]);
                    setError('No data found for this segment.');
                }
            } catch (error) {
                console.log('Error fetching segment data:', error);
                setError('Failed to fetch data.');
            }
        }
    }



    useEffect(() => {
        if(channelData.name=='reach_list_label' || channelData.name === 'non_attend_list_label' || channelData.name==='attend_list_label' || channelData.name==='register_list_label'){
            fetchMediaData(channelData.name);
            fetchSegmentData();
        }
    }, [channelData]);

    // Date filter logic
    useEffect(() => {
        if (fromDate && toDate) {
            const filtered = rows.filter((row) => {
                const rowDate = new Date(row.date_added);
                return rowDate >= new Date(fromDate) && rowDate <= new Date(toDate);
            });
            setFilteredRows(filtered); // Set filtered rows
        } else {
            setFilteredRows(rows); // Reset to all rows if no date filter is applied
        }
    }, [fromDate, toDate, rows]);

    const exportToCSV = () => {
        const headerRow = columns.map((column) => column.headerName);
        const dataRows = filteredRows.map((row) =>
            columns.map((column) => row[column.field] || '')
        );

        const csvContent = [headerRow].concat(dataRows);

        const ws = XLSX.utils.aoa_to_sheet(csvContent);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Segment Data');

        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'segment_data.csv');
    };

    const exportToXLSX = () => {
        const headerRow = columns.map((column) => column.headerName);
        const dataRows = filteredRows.map((row) =>
            columns.map((column) => row[column.field] || '')
        );

        const xlsxContent = [headerRow].concat(dataRows);

        const ws = XLSX.utils.aoa_to_sheet(xlsxContent);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Segment Data');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'segment_data.xlsx');
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = columns.map((col) => col.headerName);
        const tableRows = filteredRows.map((row) => {
            return columns.map((col) => row[col.field] || '');
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            margin: { top: 5, right: 5, bottom: 5, left: 5 },
            columnStyles: { 0: { cellWidth: 20 } },
            styles: { fontSize: 7, overflow: 'linebreak' },
            theme: 'grid',
        });

        doc.save('segment_data.pdf');
    };

    return (
        <Modal
            size="xl"
            show={listModel}
            onHide={handleCloseListModel}
            aria-labelledby="example-modal-sizes-title-lg"
            className="custom-emailChannel-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {programDetails !== '' && (
                        <h5 className='segment-header'>
                            List Name : {programDetails.eventName} - Event - {cardName}

                        </h5>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ? (
                    <p>No data found</p>
                ) : (
                    <>
                        <div className="filter-div">
                            <div className='row m-2'>
                                <div className='col-sm-2'><h6>Filter Date Wise:  </h6></div>
                                <div className='col-sm-3'>
                                    <label>From</label>
                                    <input
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <label>To</label>
                                    <input
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 450, width: '100%' }}>
                            <DataGrid
                                rows={filteredRows} // Use filtered rows here
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                            />
                        </div>
                        <div className="list-buttons">
                            <button onClick={exportToCSV}>Export to CSV</button>
                            <button onClick={exportToPDF}>Export to PDF</button>
                            <button onClick={exportToXLSX}>Export to XLSX</button>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SegmentListsModel;
