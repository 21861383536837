
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { GrDownload } from "react-icons/gr";
import { IoIosClose } from "react-icons/io";

import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import axios from 'axios';







const ModalComponent = ({ show, handleClose, channel }) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const { programId } = useParams();


    const fetchSegmentData = async () => {
        if (channel !== '') {
            try {
                setRows([]);
                setError(true);
                const api = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/lead/getLeadSegmentListByChannel`,
                    { channel: channel, programId },
                    { withCredentials: true }
                );
                const response = api.data;

                if (response && response.length > 0) {
                    const formattedRows = response.map((item) => {
                        const countryCode = item.leadData.country_code;
                        const mobile = item.leadData.mobile;

                        // Logic to format mobile number conditionally
                        let formattedMobile = '';
                        if (countryCode && mobile) {
                            formattedMobile = `+${countryCode}-${mobile}`;
                        } else if (mobile) {
                            formattedMobile = mobile;
                        } else if (countryCode) {
                            formattedMobile = `+${countryCode}`;
                        }

                        return {
                            ...item.leadData,
                            segment_id: item.segment_id,
                            lead_id: item.lead_id,
                            date_added: item.date_added,
                            formattedMobile,
                        };
                    });
                    
                    setRows(formattedRows);
                    setFilteredRows(formattedRows);
                    setError(false);
                    setColumns([
                        { field: '_id', headerName: 'ID', width: 150 },
                        { field: 'firstname', headerName: 'First Name', width: 150 },
                        { field: 'lastname', headerName: 'Last Name', width: 150 },
                        { field: 'email', headerName: 'Email', width: 200 },
                        { field: 'formattedMobile', headerName: 'Mobile Number', width: 200 },
                        { field: 'country', headerName: 'Country', width: 100 },
                        { field: 'state', headerName: 'State', width: 100 },
                        { field: 'city', headerName: 'City', width: 100 },
                        { field: 'company', headerName: 'Company', width: 100 },
                        { field: 'designation', headerName: 'Designation', width: 100 },
                        { field: 'industry', headerName: 'Industry', width: 100 },
                        { field: 'date_added', headerName: 'Created', width: 100 },
                    ]);
                } else {
                    setRows([]);
                    setFilteredRows([]);
                    setError('No data found for this segment.');
                }
            } catch (error) {
                console.log('Error fetching segment data:', error);
                setError('Failed to fetch data.');
            }
        }
    }

    useEffect(() => {
        if (channel !== '' && channel !== undefined) {
            fetchSegmentData();
        }
    }, [channel])

    const exportToXLSX = () => {
        const headerRow = columns.map((column) => column.headerName);
        const dataRows = rows.map((row) =>
            columns.map((column) => row[column.field] || '')
        );
    
        const xlsxContent = [headerRow].concat(dataRows);
    
        const ws = XLSX.utils.aoa_to_sheet(xlsxContent);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Segment Data');
    
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'segment_data.xlsx');
    };
    return (
        <Modal className='w-100' show={show} onHide={handleClose} centered dialogClassName="modal-lg">
            <Modal.Header className='borderbttm'>
                <Modal.Title className='w-100'>
                    <div className="row">
                        <div className='col-sm-7'>
                            <div>
                                <span>Registered User
                                </span>
                            </div>
                        </div>
                        <div className='d-flex col-sm-5 justify-content-end' >

                            <button className='btn modal-download' onClick={exportToXLSX}>
                                <GrDownload /> Download
                            </button> &nbsp;
                            <button className='btn modal-cancel' onClick={handleClose}>
                                <IoIosClose size={'25'} style={{ color: '#028897' }} />Close
                            </button>

                        </div>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                
            <div style={{ height: 450, width: '100%' }}>
            {error ? (
                    <p>No data found</p>
                ) : 
                    <>
                            <DataGrid
                                rows={rows} 
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                            />
                            
                    </>
                }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalComponent;
