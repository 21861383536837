import React, { createContext, useContext, useState , useRef} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import metaIcon from '../components/Assets/meta.svg';
import googleIcon from '../components/Assets/google.svg';
import emailIcon from '../components/Assets/email.svg';
import page from '../components/Assets/page.svg';
import registerpage from '../components/Assets/registerpage.svg';
import registerlist from '../components/Assets/registerlist.svg';
import whatsapp from '../components/Assets/whatsapp.svg';
import attendlist from '../components/Assets/attendlist.svg';
// Create the context   
const GlobalContext = createContext();

// Provider component
export const GlobalProvider = ({ children }) => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      localStorage.removeItem('user');
      await axios.post(`${process.env.REACT_APP_BASE_URL_API}/login/logout`, {}, { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const [channelDatas, setChannelDatas] = useState([
    { channel: "Meta Ads", no_campains: "3 Campains", expected: '150', reach: '100', icon: metaIcon, id: "1", status: 'completed', isEnabled: true },
    { channel: "Google Ads", no_campains: "3 Campains", expected: '150', reach: '200', icon: googleIcon, id: "2", status: 'completed', isEnabled: true },
    { channel: "Email", no_campains: "3 Campains", expected: '150', reach: '200', icon: emailIcon, id: "3", status: 'incompleted', isEnabled: true }
  ]);

  const [registrationDatas, setRegistrationDatas] = useState([
    { channel: "Registration Page", no_campains: "", expected: '150', reach: '200', icon: page, id: "4", status: 'completed', isEnabled: true }
  ]);

  const [formsDatas, setFormsDatas] = useState([
    { channel: "Meta Lead Form", no_campains: "", expected: '150', reach: '100', icon: metaIcon, id: "5", status: 'incompleted', isEnabled: true },
    { channel: "Registration Form", no_campains: "", expected: '150', reach: '100', icon: registerpage, id: "6", status: 'completed', isEnabled: true }
  ]);

  const [registrationListDatas, setRegistrationListDatas] = useState([
    { channel: "Registration List", no_campains: "", expected: '150', reach: '100', icon: registerlist, id: "7", status: 'completed', isEnabled: true }
  ]);

  const [connectionIds, setConnectionIds] = useState([
    { source: '1', target: '5' },
    { source: '2', target: '4' },
    { source: '3', target: '4' },
    { source: '4', target: '6' },
    { source: '5', target: '7' },
    { source: '6', target: '7' }
  ]);

  const [register_registrationListDatas, setRegister_registrationListDatas] = useState([
    {
      channel: "Registration List",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: registerlist,
      id: "1",
      status: 'completed',
      isEnabled: true
    },

  ]);
  const [register_reminderDatas, setRegister_reminderDatas] = useState([
    {
      channel: "Email Reminder",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: emailIcon,
      id: "2",
      status: 'completed',
      isEnabled: true
    },
    {
      channel: "Whatsapp Reminder",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: whatsapp,
      id: "3",
      status: 'completed',
      isEnabled: true
    },
  ]);
  const [register_attendDatas, setRegister_attenDatas] = useState([
    {
      channel: "Attend List",
      no_campains: "",
      expected: '150',
      reach: '100',
      icon: attendlist,
      id: "4",
      status: 'completed',
      isEnabled: true
    },
  ])
  const [register_connectionIds, setRegister_connectionIds] = useState([
    {
      source: '1',
      target: '2',
    },
    {
      source: '1',
      target: '3',
    },
    {
      source: '2',
      target: '4',
    },
    {
      source: '3',
      target: '4',
    },

  ]);


  //attend tab
  const [attend_List, setAttend_List] = useState([
    {
      channel: "Attend List",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "1",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [attend_Channel, setAttend_Channel] = useState([
    {
      channel: "ThankYou Email",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "2",
      status: 'completed',
      isEnabled: true
    },
    {
      channel: "CRM",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "3",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [attend_connectionIds, setAttend_connectionIds] = useState([
    {
      source: '1',
      target: '3'
    },
    {
      source: '1',
      target: '2'
    }
  ]);


  const [notAttend_List, setNotAttend_List] = useState([
    {
      channel: "Not Attend List",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "5",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [notAttend_Channel, setNotAttend_Channel] = useState([
    {
      channel: "ThankYou Email",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "6",
      status: 'completed',
      isEnabled: true
    },
    {
      channel: "CRM",
      no_campains: "",
      expected: '150',
      reach: '200',
      icon: whatsapp,
      id: "7",
      status: 'completed',
      isEnabled: true
    },
  ]);

  const [notAttend_connectionIds, setNotAttend_connectionIds] = useState([
    {
      source: '5',
      target: '6'
    },
    {
      source: '5',
      target: '7'
    }
  ]);
  const [AnimationMode,ToggleAnimation]=useState(false);

  //tab status
  const [tabLoading, setTabLoading] = useState(false);
  const resetTransformRef = useRef(null);


  const globalFunctions = {
    //logout
    logout,

    //reach datas
    channelDatas,
    setChannelDatas,
    registrationDatas,
    setRegistrationDatas,
    formsDatas,
    setFormsDatas,
    registrationListDatas,
    setRegistrationListDatas,
    connectionIds,
    setConnectionIds,

    //register datas
    register_registrationListDatas,
    setRegister_registrationListDatas,
    register_reminderDatas,
    setRegister_reminderDatas,
    register_attendDatas,
    setRegister_attenDatas,
    register_connectionIds,
    setRegister_connectionIds,

    //attend datas
    attend_List,
    setAttend_List,
    attend_Channel,
    setAttend_Channel,
    attend_connectionIds,
    setAttend_connectionIds,
    notAttend_List,
    setNotAttend_List,
    notAttend_Channel,
    setNotAttend_Channel,
    notAttend_connectionIds,
    setNotAttend_connectionIds,

    //tab status
    tabLoading, 
    setTabLoading,

    //RESET ZOOM
    resetTransformRef,

    //journey animation
    AnimationMode,
    ToggleAnimation
  };

  return (
    <GlobalContext.Provider value={globalFunctions}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook for using the context
export const useGlobal = () => useContext(GlobalContext);
