const availableFonts = [
    'Arial', 
    'Arial Black', 
    'Verdana', 
    'Tahoma', 
    'Trebuchet MS', 
    'Gill Sans', 
    'Helvetica', 
    'Impact', 
    'Lucida Sans Unicode', 
    'Georgia', 
    'Palatino Linotype', 
    'Times New Roman', 
    'Baskerville', 
    'Garamond', 
    'Courier New', 
    'Lucida Console', 
    'Courier', 
    'Monaco', 
    'Comic Sans MS', 
    'Brush Script MT', 
    'Copperplate', 
    'Papyrus', 
    'Century Gothic', 
    'Lucida Grande', 
    'Optima', 
    'Franklin Gothic Medium', 
    'Futura', 
    'Goudy Old Style', 
    'Hoefler Text', 
    'Bodoni MT', 
    'Book Antiqua', 
    'Didot', 
    'Rockwell', 
    'Cambria', 
    'Candara', 
    'Constantia', 
    'Corbel', 
    'Calibri', 
    'Segoe UI', 
    'PT Sans', 
    'Droid Sans', 
    'Noto Sans', 
    'Raleway', 
    'Roboto', 
    'Open Sans', 
    'Lato', 
    'Montserrat', 
    'Oswald', 
    'Poppins', 
    'Merriweather', 
    'Ubuntu', 
    'Playfair Display'
];

export default availableFonts
