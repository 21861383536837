import React, { useEffect, useRef } from 'react';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';
import './CSS/DateTimeRangePicker.css';
import { CalendarMonth } from '@mui/icons-material';

const DateTimePicker = ({ onChange }) => {
  const dateTimeRef = useRef(null);

  useEffect(() => {
    flatpickr(dateTimeRef.current, {
      mode: "range",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      monthSelectorType: "static",
      minDate: "today", // Prevent selection of past dates
      onClose: (selectedDates, dateStr, instance) => {
        if (onChange) {
          onChange(selectedDates);
        }
      },
    });
  }, [onChange]);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <input
        ref={dateTimeRef}
        type="text"
        className="form-control"
        placeholder="Select Time and Date"
        style={{ paddingRight: '40px', position: 'relative' }} // Apply padding and ensure the container is positioned correctly
      />
      <CalendarMonth style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#aaa', pointerEvents: 'none', zIndex: 1 }}/>
      <i className="fa fa-calendar icon-class" ></i>
    </div>
  );
};

export default DateTimePicker;