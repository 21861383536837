import React from 'react'

const BuilderLoader = () => {
  return (
    <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', height:'450px'}}>
        <div className="builder-loader"></div>
    </div>
  )
}

export default BuilderLoader