import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Stack } from '@mui/material';
import NavbarComp from '../../components/NavbarComp';
import { MdEditSquare } from "react-icons/md";
import { MdDeleteSweep } from "react-icons/md";
import { MdPreview } from "react-icons/md";
import Swal from 'sweetalert2';
import axios from 'axios'
const FormsList = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


useEffect(() => {
  fetch(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/forms`, {
    method: 'GET',
    credentials: 'include', // Include cookies in the request
  })
    .then(res => res.json())
    .then(data => {
      setForms(data.forms);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching forms:', error);
      setLoading(false);
    });
}, []);



  const handleEdit = (formId) => {
    navigate(`/formEdit/${formId}`);
  };
  const handlePreview = (formId) => {
    navigate(`/preview/${formId}`);
  };

  const handleDelete = (formId) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteApi = await axios.post((`${process.env.REACT_APP_BASE_URL_API}/formBuilder/delete`), { id: formId },{
              withCredentials: true
            });
            const deleteResponse = deleteApi.data;
            if (deleteResponse) {
              setForms(forms.filter(form => form._id !== formId));
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
            }
          } catch (error) {
            console.log(error);
          }

        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'formName', headerName: 'Form Name', flex: 2 },
    { field: 'date_added', headerName: 'Added Date', flex: 2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleEdit(params.row.id)}
          >
            <MdEditSquare />
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => handleDelete(params.row.id)}
          >
            <MdDeleteSweep />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handlePreview(params.row.id)}
          >
            <MdPreview />
          </Button>
        </Stack>
      ),
    },
  ];

  const rows = forms.map((form) => ({
    id: form._id,
    formName: form.formName,
    date_added: form.date_added
  }));

  return (
    <>
      <NavbarComp />
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
        // autoHeight
        />
      </div>
    </>
  );
};

export default FormsList;
