

import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const CostChannel = ({ data4 }) => {
    const calculateDataWithPercentage = data4.map((item) => {
        return { ...item };
    });

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className='lbl-heading'>{label}</p>
                    <p className='lbl-project'>{payload[0].value}$</p>
                </div>
            );
        }
        return null;
    };

    const averageCost = (data4.reduce((sum, item) => sum + item.Projected, 0) / data4.length).toFixed(2);

    return (
        <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto', height: 250 }}>
            <ResponsiveContainer>
                <BarChart data={calculateDataWithPercentage} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="4 4" vertical={false} />
                    <XAxis dataKey="name" style={{ fontSize: '10px' }} />
                    <Tooltip content={<CustomTooltip />} />
 
                    <Bar dataKey="Projected" fill="#81CDD5" radius={[5, 5, 0, 0]} barSize={20} />
                </BarChart>
            </ResponsiveContainer>

            <div className='row' style={{ marginTop: '10px', padding: '0px 25px' }}>
                <div className="col-sm-12 overall">
                    <div className='d-flex justify-content-between'>
                        <div className='ovr-performance'>Average Cost</div>
                        <div className='percen' style={{ color: 'black' }}>
                            {averageCost} $
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CostChannel;
