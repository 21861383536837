
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import EmailEditor from 'react-email-editor';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #c0ffe8;
  color: #000;
//   padding: 10px;
  display: flex;
  max-height: 40px;

  h1 {
    flex: 1;
    font-size: 16px;
    // margin: 10px;
    text-align: left;
  }

  button {
    flex: 1;
    height:40px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0;
    max-width: 150px;
    cursor: pointer;
  }
`;

const EmailTemplateEditor = () => {
  const emailEditorRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadTemplate(id);
    }
  }, [id]);

  const saveDesign = () => {
    const unlayer = emailEditorRef.current?.editor;
    const templateName = prompt('Enter a template Name');
    if (unlayer) {
      unlayer.saveDesign((json) => {
        unlayer.exportHtml((data) => {
          const { html } = data;

          fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/save-template`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              json,
              html,
              templateName
            }),
          })
            .then(response => response.json())
            .then(data => {
              console.log('Template saved successfully:', data);
              alert('Template saved successfully!');
            })
            .catch(error => {
              console.error('Error saving template:', error);
              alert('An error occurred while saving the template.');
            });
        });
      });
    } else {
      console.error('Editor instance is not available.');
    }
  };

  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (unlayer) {
      if (preview) {
        unlayer.hidePreview();
        setPreview(false);
      } else {
        unlayer.showPreview('desktop');
        setPreview(true);
      }
    } else {
      console.error('Editor instance is not available.');
    }
  };

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  const onLoad = (unlayer) => {
    console.log('onLoad', unlayer);
    unlayer.addEventListener('design:loaded', onDesignLoad);
    unlayer.loadDesign();

    unlayer.registerCallback('image', (file, done) => {
      const formData = new FormData();
      formData.append('file', file.attachments[0]);

      fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/upload-image`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.url) {
            const fullUrl = `${process.env.REACT_APP_BASE_URL_API}${data.url}`;
            done({ progress: 100, url: fullUrl });
          } else {
            done({ progress: 0, error: 'Image upload failed' });
          }
        })
        .catch(error => {
          console.error('Upload failed:', error);
          done({ progress: 0, error: 'Image upload failed' });
        });
    });
  };

  const onReady = (unlayer) => {
    console.log('onReady', unlayer);
  };

  const loadTemplate = (id) => {
    fetch(`${process.env.REACT_APP_BASE_URL_API}/emailBuilder/template/${id}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer.loadDesign(data.json);
      })
      .catch(error => console.error('Error loading template:', error));
  };


  return (
    <Container>
      <Bar>
        <h1>Camp Email Editor</h1>

        <button onClick={togglePreview}>
          {preview ? 'Hide' : 'Show'} Preview
        </button>
        <button onClick={saveDesign}>Save Design</button>
      </Bar>

      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        options={{
          version: 'latest',
          appearance: {
            theme: 'dark',
            panels: {
              tools: {
                dock: 'left'
              }
            }
          },
          mergeTags: {
            event_name: {
              name: 'Event Name',
              value: '{{event_name}}'
            },
            event_date_and_time: {
              name: 'Event Date And Time',
              value: '{{event_d_t}}'
            },
            event_link: {
              name: 'Event Link',
              value: '{{event_link}}'
            },
            event_venue: {
              name: 'Event Venue',
              value: '{{event_venue}}'
            },
            event_speaker: {
              name: 'Event Speakers',
              value: '{{event_speakers}}'
            },
            first_name :{
              name:'First Name',
              value: '{{first_name}}'
            },
            last_name:{
              name:'last name',
              value:'{{last_name}}'
            },
            company:{
              name:'Company',
              value:'{{company}}'
            },
            CTA:{
              name:'CTA',
              value:'{{CTA}}'
            },
            Unsubscribe:{
              name:'Unsubscribe',
              value:'{{unsubscribe}}'
            }
          },
          fonts: {
            showDefaultFonts: true,
            customFonts: [
              {
                label: "Andale Mono",
                value: "andale mono,times"
              },
              {
                label: "Arial",
                value: "arial,helvetica,sans-serif"
              },
              {
                label: "Arial Black",
                value: "arial black,avant garde,arial"
              },
              {
                label: "Book Antiqua",
                value: "book antiqua,palatino"
              },
              {
                label: "Comic Sans MS",
                value: "comic sans ms,sans-serif"
              },
              {
                label: "Courier New",
                value: "courier new,courier"
              },
              {
                label: "Georgia",
                value: "georgia,palatino"
              },
              {
                label: "Helvetica",
                value: "helvetica,sans-serif"
              },
              {
                label: "Impact",
                value: "impact,chicago"
              },
              {
                label: "Symbol",
                value: "symbol"
              },
              {
                label: "Tahoma",
                value: "tahoma,arial,helvetica,sans-serif"
              },
              {
                label: "Terminal",
                value: "terminal,monaco"
              },
              {
                label: "Times New Roman",
                value: "times new roman,times"
              },
              {
                label: "Trebuchet MS",
                value: "trebuchet ms,geneva"
              },
              {
                label: "Verdana",
                value: "verdana,geneva"
              },
              {
                label: "Lobster Two",
                value: "'Lobster Two',cursive",
                url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
              },
              {
                label: "Playfair Display",
                value: "'Playfair Display',serif",
                url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700"
              },
              {
                label: "Rubik",
                value: "'Rubik',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Rubik:400,700"
              },
              {
                label: "Source Sans Pro",
                value: "'Source Sans Pro',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700"
              },
              {
                label: "Open Sans",
                value: "'Open Sans',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700"
              },
              {
                label: "Crimson Text",
                value: "'Crimson Text',serif",
                url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700"
              },
              {
                label: "Montserrat",
                value: "'Montserrat',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Montserrat:400,700"
              },
              {
                label: "Old Standard TT",
                value: "'Old Standard TT',serif",
                url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700"
              },
              {
                label: "Lato",
                value: "'Lato',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Lato:400,700"
              },
              {
                label: "Raleway",
                value: "'Raleway',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Raleway:400,700"
              },
              {
                label: "Cabin",
                value: "'Cabin',sans-serif",
                url: "https://fonts.googleapis.com/css?family=Cabin:400,700"
              },
              {
                label: "Pacifico",
                value: "'Pacifico',cursive",
                url: "https://fonts.googleapis.com/css?family=Pacifico"
              }
            ]
          },
          specialLinks: [
            {
              name: 'Manage account',
              href: 'https://[my-account]/',
              target: '_self',
            },
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Subscribe',
                  href: '[subscribe]',
                  target: '_blank',
                },
                {
                  name: 'Unsubscribe',
                  href: '[unsubscribe]',
                  target: '_blank',
                }
              ]
            }
          ]
        }}
      />
    </Container>
  );
};

export default EmailTemplateEditor;