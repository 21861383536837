import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AdProfilePic from '../../../components/Assets/ad-profile-pic.png';
import { DataGrid } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { CiSearch } from "react-icons/ci";
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { Active, Paused, Draft, Yes, No} from './DynamicButtons';
import NameCell from './NameCell';
import { BiArrowBack } from 'react-icons/bi';
import axios from 'axios';
import { IoSettings } from 'react-icons/io5';
import { useGlobal } from '../../../context/GlobalProvider';
import { ToastContainer, toast, Bounce } from 'react-toastify';

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    // console.log(className);

    return (
        <MuiPagination
            color="success"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}

        />
    );
}
function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}
const GoogleLeadFormModal = ({ GoogleLeadFormModal,setGoogleLeadFormModal,programId,fetchProgramDetails,selectedCampaigns }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [SelectedSegment,setSelectedSegment]=useState();
    const [LeadFields,setLeadFields]=useState();
    const [accountId, setAccountId] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountImage, setAccountImage] = useState('');
    const columns = [
        { field: 'id', headerName: 'S.No', flex: 1 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => <NameCell name={params.value} /> // Use the NameCell component
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => {
                switch (params.value) {
                    case 'ELIGIBLE':
                      return <Active />;
                    case 'NOT_ELIGIBLE':
                      return <p className='paused-tag'>Not eligble</p>;
                    case 'PAUSED':
                      return <Paused />;
                    case '':
                      return <Draft />;
                    default:
                      return null;
                  }
            }
        },
        { field: 'campaign', headerName: 'Campaign', flex: 1, headerClassName: 'custom-header' },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => {
              return <button className='btn btn-success btn-sm cfs-5 text-light' onClick={()=>handleFormMap(params.row)}><IoSettings></IoSettings> Map leads</button>;
            }
        },
        {
            field: 'subscribed', headerName: 'Subscribed', flex: 1, headerClassName: 'custom-header',
            renderCell:(params)=>{
                return (
                    params.value ? <Yes /> : <No />
                );
            }
        }

        // { field: 'type', headerName: 'Type', flex: 1, headerClassName: 'custom-header' }
    ];
    const {registrationListDatas} = useGlobal();
    // console.log(registrationListDatas);
    
    const [initialRows, setInitialRows] = useState([]);
    const [Formdata,setFormdata]=useState({});
    const [SelectedFormId,setSelectedFormId]=useState(null);
    const [SelectedCampaignId,setSelectedCampaignId]=useState(null);
    const [mappedCount,setmappedCount]=useState(null);
    const [formValues, setFormValues] = useState({});
    const handleFormMap=(form)=>{
        setSelectedFormId(form.assetId);
        setSelectedCampaignId(form.campaign)
        // console.log(form,'ssss');
        
    }
    const handleLeadformClose = () => {
        setGoogleLeadFormModal((prev)=>{
            return !prev;
        })
        setSelectedFormId(null)
        setSelectedCampaignId(null)
    }
    useEffect(() => {
        let apiCallURL = "api/google/getAccountData";
        
        const fetchAccountDetails = async () => {
            try {
                if (apiCallURL === undefined || apiCallURL === '') {
                    setAccountId('0123456789');
                    setAccountName('Account Name');
                    setAccountImage('');
                    console.log(apiCallURL);
                    return;
                }
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/${apiCallURL}`, {
                    method: 'GET',
                    credentials: 'include',
                });
                
                const data = await response.json();
                setAccountId(data.accountId);
                setAccountName(data.accountName);
                setAccountImage(data.profilePic);
                console.log(data.profilePic);
                
            } catch (error) {
                console.error('Error fetching account details:', error);
            }
        };
        // Call the fetch function
        fetchAccountDetails();
    }, []);
    useEffect(() => {
        const fetchforms = async () => {
            if (!programId) {
                console.error("Invalid or missing programId");
                return;
            }
            const payload = {
                programId: programId,
                channel: 'google_ad',
                builder: 'reachBuilder',
            };
            setRows([]);
            if(GoogleLeadFormModal)
            try {
                const checkExistingSelectedIdResponse = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/checkExistingSelectedId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                    credentials: 'include',
                });
    
                // Validate response
                if (!checkExistingSelectedIdResponse.ok) {
                    throw new Error("Failed to fetch existing selected IDs");
                }
    
                const checkExistingSelectedIdData = await checkExistingSelectedIdResponse.json();
                
                // Validate if ids exist and are in the correct format
                if (!Array.isArray(checkExistingSelectedIdData.ids) || checkExistingSelectedIdData.ids.length === 0) {
                    toast.warn('Connect atleast one campaign with a form', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    return;
                }
    
                const formdata = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/google/getGoogleForms`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        campaignIds: [...checkExistingSelectedIdData.ids],
                    })
                });
                if (!formdata.ok) {
                    toast.warn('Connect atleast one campaign with a form', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    return;
                    // throw new Error("Failed to fetch Google forms");
                }
                const formdataJson = await formdata.json();
                if (Array.isArray(formdataJson) && formdataJson.length > 0) {
                    const formattedData = formdataJson.map((form, index) => {
                        const subscription = form.subscription.find(sub => sub.selectedSegment === registrationListDatas[0].cardId);
                        const subscribed = subscription && subscription.mappedValues && Object.keys(subscription.mappedValues).length > 0;
                        return({
                        id: index,
                        name: form.formName,
                        status: form.formStatus,
                        campaign: form.campaignId,
                        assetId: form.formId,
                        subscribed: !!subscribed
                        })
                    });
    
                    const defaultSelectedRows = formattedData
                        .filter(row => row.selected)
                        .map(row => row.id);
    
                    setInitialRows(formattedData);
                    setRows(formattedData);
                    setFormdata(formdataJson);
                } else {
                    toast.warn('Connect atleast one campaign with a form', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    return;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        // if(selectedCampaigns)
        fetchforms();
    }, [GoogleLeadFormModal]);
    
    useEffect(() => {
        const fetchSegmentData = async () => {
          try {
            setSelectedSegment(registrationListDatas[0].cardId);
            const leadfields = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/formBuilder/getColumns`, {
                withCredentials: true
            });
            setLeadFields(leadfields.data);
          } catch (err) {
            console.log(err.message);
          };
        };
        fetchSegmentData();
    }, [SelectedFormId]);
    useEffect(() => {
        // if (Formdata && SelectedCampaignId && SelectedFormId) {
        //     const selectedForm = Formdata.find(form => form.campaignId === SelectedCampaignId && form.formId === SelectedFormId);
            
        //     if (selectedForm) {
        //         const defaultValues = {};
        //         selectedForm.formContent.fields.forEach(field => {
        //             defaultValues[field.inputType] = getfieldvalue(field.inputType);
        //         });
        //         setFormValues(defaultValues); 
        //     }
        // }
        let mappedfields = [];
        if (SelectedFormId&&Formdata&&SelectedCampaignId) {
            Formdata.filter(form => form.campaignId === SelectedCampaignId && form.formId === SelectedFormId).forEach((form) => {
                const fields = form.formContent.fields; 
                const customfields=form.formContent.customQuestionFields||[];
                    // console.log(form);
                    fields.forEach((field) => {
                        const isfieldAvailable = getfieldvalue(field.inputType);
                        mappedfields.push({
                            inputType: field.inputType,
                            campfield: isfieldAvailable,
                            status: isfieldAvailable ? true : false,
                            label:field.inputType ? field.inputType.replace(/_/g, ' ') : '',
                        });
                        // console.log(field, 'this');
                    });
                    customfields.forEach((field) => {
                        const isfieldAvailable = getfieldvalue(field.customQuestionText || 'Unspecified');
                        mappedfields.push({
                            inputType: field.customQuestionText,
                            campfield: isfieldAvailable,
                            status: isfieldAvailable ? true : false,
                            label:field.customQuestionText ? field.customQuestionText.replace(/_/g, ' ') : '',
                        });
                        // console.log(field, 'this');
                    });
            });
        }
        const mappedCount = mappedfields.filter(field => field.status).length;
        setFormValues(mappedfields);
        setmappedCount(mappedCount);
    }, [Formdata, SelectedCampaignId, SelectedFormId]);
    
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = initialRows.filter((row) =>
            row.name.toLowerCase().includes(value)
        );
        setRows(filteredRows);
    };
    // const handleInputChange = (fieldInputType, value) => {
    //     setFormValues((prevValues) => ({
    //       ...prevValues,
    //       [fieldInputType]: value,
    //     }));
    // };
    const handleInputChange = (fieldInputType, value, key) => {
        // console.log(fieldInputType, value);
        setFormValues((prevValues) => {
            const updatedValues = prevValues.map((item, index) =>
                index === key
                    ? { ...item, campfield: value, fieldInputType,status:true }
                    : item
            );
            const mappedCount = updatedValues.filter(field => field.status).length;
            setmappedCount(mappedCount);
            console.log(mappedCount);
            
            return updatedValues;
        });
    };
    // console.log(SelectedSegment,'segmentid');

    const handlesubscribe = async()=>{
        const subscriptionData = {
            selectedFormId: SelectedFormId,
            selectedSegment: SelectedSegment,
            mappedValues: formValues,
            selectedCampaignId: SelectedCampaignId,
            programId:programId
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/api/google/leadform/subscribe`, subscriptionData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const selectedData={
                builder:'reachBuilder',
                programId:programId, 
                cardName:'lead_google_ad_form',
                cardType:'form',
            };
            await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedCardbytype`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });
            fetchProgramDetails();
            handleLeadformClose();
            // console.log('Subscription successful:', response.data);
        } catch (error) {
            console.error('Error subscribing:', error.response ? error.response.data : error.message);
        }
    }
    const searchLeadFields = (inputType) => {
        const matchedField = LeadFields.data.find((field) => field.alias === inputType);
        return matchedField ? matchedField._id : '';
    };
    const getfieldvalue = (inputType) => {
        switch (inputType) {
            case 'FULL_NAME':
            case 'FIRST_NAME':
                return searchLeadFields('firstname') || '';
            case 'LAST_NAME':
                return searchLeadFields('lastname') || '';
            case 'EMAIL':
                return searchLeadFields('email') || '';
            case 'WORK_EMAIL':
                return searchLeadFields('email') || '';
            case 'PHONE_NUMBER':
            case 'WORK_PHONE':
                return searchLeadFields('phone') || '';
            case 'POSTAL_CODE':
                return searchLeadFields('zipcode') || '';
            case 'STREET_ADDRESS':
                return searchLeadFields('address1') || '';
            case 'CITY':
                return searchLeadFields('city') || '';
            case 'REGION':
                return searchLeadFields('state') || '';
            case 'COUNTRY':
                return searchLeadFields('country') || '';
            case 'COMPANY_NAME':
                return searchLeadFields('company') || '';
            default:
                return '';
        }
    };
    
    return (
        <Modal
            show={GoogleLeadFormModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={handleLeadformClose}
            className="custom-leadData-modal"
            
        >
            {SelectedFormId ?
                <>
                    <Modal.Header className='justify-content-between align-items-start'>
                        <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                            <p className='ad-details-p'>Ad Accounts Details</p>
                            <div className='ad-profile-card'>
                                <div className='ad-profile-avatar'>
                                    <img src={accountImage} alt='profile pic' />
                                </div>
                                <div className='ad-profile-details'>
                                    <p><span>{accountName}</span>&nbsp; id : {accountId}</p>
                                    <p></p>
                                </div>
                            </div>
                        </Modal.Title>
                        <div>
                            <button className='btn btn-link fw-bold' onClick={()=>{setSelectedFormId(null);setSelectedCampaignId(null)}}><BiArrowBack></BiArrowBack> Go back</button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className='list-head'>Subscribe form</h5>
                        <div style={{ width: '100%', height: '320px' }}>
                            <div  style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                                <h4 className='text-primary'>Lead Map</h4>
                                <div className='row align-items-center mt-3'>
                                    <div className='col-md-2'>
                                        <p className='text-dark'></p>
                                    </div>
                                    <div className='col-md-6'>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className='d-flex'><p>out of {formValues.length} fields {mappedCount} are mapped.</p>
                                        {formValues.length-mappedCount>0?(<p className='m-0'>{formValues.length-mappedCount} is pending.</p>):null}
                                        </span>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>Channel Form fields</div>
                                    <div className='col-md-6'>Camp Fields</div>
                                </div>
                                {formValues.map((field, idx) => (
                                    <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <input
                                        className='form-control'
                                        placeholder={field.inputType||field.customquestion}
                                        readOnly
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <select
                                        className={`form-select ${field.campfield?'border-green':'border-red'}`}
                                        defaultValue={getfieldvalue(field.inputType||field.customquestion)}
                                        disabled={field.inputType==="EMAIL"?true:false}
                                        onChange={(e) => handleInputChange(field.inputType, e.target.value,idx)}
                                        key={idx}
                                        >
                                        <option value="" disabled>
                                            Select an option...
                                        </option>
                                        {LeadFields.data.map((leadfield) => {
                                            const maxLength = 90;
                                            const truncatedName = leadfield.label.length > maxLength 
                                            ? leadfield.label.substring(0, maxLength) + '...' 
                                            : leadfield.label;
                                            return (
                                            <option key={leadfield._id} value={leadfield._id}>
                                                {truncatedName}
                                            </option>
                                            );
                                        })}
                                        </select>
                                    </div>
                                    </div>
                                ))}
                                <h6 className='mt-3'>Note: If you are unable to find fields to map, please setup the custom fields from settings</h6>
                            </div>
                            <button className='btn btn-success text-light text-end float-end' onClick={handlesubscribe}>
                            Subscribe
                            </button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                        </div>
                    </Modal.Footer>
                </>
                :
                <>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className='ad-details'>
                            <p className='ad-details-p'>Ad Accounts Details</p>
                            <div className='ad-profile-card'>
                                <div className='ad-profile-avatar'>
                                    <img src={accountImage} alt='profile pic' />
                                </div>
                                <div className='ad-profile-details'>
                                    <p><span>{accountName}</span>&nbsp; id : {accountId}</p>
                                    <p></p>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className='list-head'>List of Forms</h5>
                        <div style={{ width: '100%' }}>
                            <div style={{ width: '100%', height: '320px' }}>
                                <div className='row' style={{ margin: '12px 0px' }}>
                                    <div className="col-sm-3 search-wrapper">
                                        <CiSearch className='search-icon' />
                                        <input
                                            type="text"
                                            className="grid-search"
                                            placeholder="search by Form name"
                                            value={searchText}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                                {initialRows.length > 0 &&
                                    <>
                                        <DataGrid
                                            disableRowSelectionOnClick
                                            rows={rows}
                                            columns={columns}
                                            pageSize={5}
                                            rowHeight={45}
                                            autoHeight
                                            pagination
                                            disableColumnMenu={true}
                                            page={page}
                                            onPageChange={(newPage) => setPage(newPage)}
                                            initialState={{
                                                ...rows.initialState,
                                                pagination: { paginationModel: { pageSize: 5 } },
                                                columns: {
                                                    columnVisibilityModel: {
                                                        id: false,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10, 25]}
                                            loading={loading}
                                            slots={{
                                                pagination: CustomPagination,
                                            }}
                                            columnHeaderHeight={40}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </>
            }

        </Modal>
    );
};
export default GoogleLeadFormModal;
