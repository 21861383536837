import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CustomColumnModal = ({ show, handleClose, addCustomColumn }) => {
    const [selectedElements, setSelectedElements] = useState([]);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setSelectedElements((prev) =>
            checked ? [...prev, value] : prev.filter((item) => item !== value)
        );
    };

    const handleSubmit = () => {
        addCustomColumn(selectedElements);
        setSelectedElements([]);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Customize Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Check
                        type="checkbox"
                        label="Text Input"
                        value="text"
                        onChange={handleCheckboxChange}
                    />
                    <Form.Check
                        type="checkbox"
                        label="Select Input"
                        value="select"
                        onChange={handleCheckboxChange}
                    />
                    <Form.Check
                        type="checkbox"
                        label="Multi Select Input"
                        value="multi-select"
                        onChange={handleCheckboxChange}
                    />
                    <Form.Check
                        type="checkbox"
                        label="Radio Button"
                        value="radio"
                        onChange={handleCheckboxChange}
                    />
                    <Form.Check
                        type="checkbox"
                        label="Textarea"
                        value="textarea"
                        onChange={handleCheckboxChange}
                    />
                    <Form.Check
                        type="checkbox"
                        label="Empty"
                        value="empty"
                        onChange={handleCheckboxChange}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <button className='add-col' onClick={handleSubmit}>
                    Add Columns
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomColumnModal;
