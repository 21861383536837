    import axios from 'axios';
    import React, { useState, useEffect } from 'react';
    import Modal from 'react-bootstrap/Modal';
    import { MultiSelect } from "react-multi-select-component";
    import { FaEdit } from "react-icons/fa";
    import { MdDeleteForever } from "react-icons/md";

    function SaveModel({ show, onHide, mainForm, setMainForm, saveForm, editorStatus }) {
        const [mcs, setMcs] = useState(false);
        const [selectedMcsAdd, setSelectedMcsAdd] = useState([]);
        const [selectedMcsRemove, setSelectedMcsRemove] = useState([]);
        const [options, setOptions] = useState([]);
        const [assetOptions, setAssetOptions] = useState([]);
        const [actionName, setActionName] = useState('');
        const [editIndex, setEditIndex] = useState(null);
        const [downloadAsset, setDownloadAsset] = useState(false);
        const [actionModel, setActionModel] = useState(false);
        const [selectedAsset, setSelectedAsset] = useState('');
        const [crmOptions, setCrmOptions] = useState([]);
        const [pushCrm, setPushCrm] = useState(false);
        const [selectedCrm, setSelectedCrm] = useState('');

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const segmentResponse = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/segments/getSegmentList`, {
                        withCredentials: true
                      });
                    const formattedSegmentOptions = segmentResponse.data.map(segment => ({
                        value: segment._id,
                        label: segment.name
                    }));
                    setOptions(formattedSegmentOptions);
    
                    const assetResponse = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/asset/getAssetList`,{
                        withCredentials: true
                      });
                    const formattedAssetOptions = assetResponse.data.map(asset => ({
                        value: asset._id,
                        label: asset.title
                    }));
                    setAssetOptions(formattedAssetOptions);
    
                    const crmResponse = await axios.get(`${process.env.REACT_APP_BASE_URL_API}/crmplatform/getCrmList`,{
                        withCredentials: true
                      });
                    const formattedCrmOptions = crmResponse.data.map(crm => ({
                        value: crm._id,
                        label: crm.name
                    }));
                    setCrmOptions(formattedCrmOptions);
                    
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            };
    
            fetchData();
        }, []);

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setMainForm((prev) => ({
                ...prev,
                [name]: value,
            }));
        };

        const handleInputChangeAction = (e) => {
            const { name, value } = e.target;
            if (name === 'action') {
                if (value === 'Modify Contact Segment') {
                    setMcs(true);
                    setDownloadAsset(false);
                    setActionModel(true);
                    setPushCrm(false);
                } else if (value === 'Download Asset Form Action') {
                    setDownloadAsset(true);
                    setMcs(false);
                    setActionModel(true);
                    setPushCrm(false);
                }else if(value === 'Push Contact to CRM Form Action'){
                    setDownloadAsset(false);
                    setMcs(false);
                    setPushCrm(true);
                    setActionModel(true);
                } else {
                    setActionModel(false);
                    setMcs(false);
                    setDownloadAsset(false);
                    setPushCrm(false)
                }
            }

            setMainForm((prev) => ({
                ...prev,
                action: value,
            }));
        }

        const handleAddActionDetails = () => {
            const newActionDetail = {
                action: mainForm.action,
                action_name: actionName,
                add_segments: selectedMcsAdd,
                remove_segments: selectedMcsRemove,
                asset: downloadAsset ? selectedAsset : null,
                pushCrm: pushCrm ? selectedCrm : null
            };

            setMainForm((prev) => {
                const currentActionDetails = Array.isArray(prev.actionDetails) ? prev.actionDetails : [];
                const updatedActionDetails = editIndex !== null
                    ? currentActionDetails.map((detail, index) =>
                        index === editIndex ? newActionDetail : detail
                    )
                    : [...currentActionDetails, newActionDetail];

                return {
                    ...prev,
                    actionDetails: updatedActionDetails,
                };
            });

            setActionName('');
            setSelectedMcsAdd([]);
            setSelectedMcsRemove([]);
            setSelectedAsset('');
            setEditIndex(null);
            setMcs(false);
            setDownloadAsset(false);
        };

        const handleAddActionDetailsClose = () => {
            setActionName('');
            setSelectedMcsAdd([]);
            setSelectedMcsRemove([]);
            setSelectedAsset('');
            setEditIndex(null);
            setMcs(false);
            setActionModel(false);
        };

        const handleEditAction = (index) => {
            console.log(index);
            
            const actionDetail = mainForm.actionDetails[index];
            mainForm.action=actionDetail.action
            setActionName(actionDetail.action_name);
            setSelectedMcsAdd(actionDetail.add_segments);
            setSelectedMcsRemove(actionDetail.remove_segments);
            setSelectedAsset(actionDetail.asset || '');
            setEditIndex(index);
            setMcs(actionDetail.action === 'Modify Contact Segment');
            setDownloadAsset(actionDetail.action === 'Download Asset Form Action');
            setPushCrm(actionDetail.action === 'Push Contact to CRM Form Action')
        };

        const handleDeleteAction = (index) => {
            setMainForm((prev) => {
                const updatedActionDetails = Array.isArray(prev.actionDetails)
                    ? prev.actionDetails.filter((_, i) => i !== index)
                    : [];
                return {
                    ...prev,
                    actionDetails: updatedActionDetails,
                };
            });
        };

        return (
            <>
                <Modal show={show} onHide={onHide} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Form Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-2'>
                            <label>Form Name <span style={{ color: 'red' }}> * </span></label>
                            <input
                                placeholder="Enter a form name"
                                type="text"
                                name="formName"
                                className="input-save"
                                value={mainForm.formName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='mb-2'>
                            <label>Successful Submit Action <span style={{ color: 'red' }}> * </span></label>
                            <select
                                name="successfulSubmitAction"
                                className="input-save"
                                value={mainForm.successfulSubmitAction}
                                onChange={handleInputChange}
                            >
                                <option>select</option>
                                <option value="remainForm">Remain on form</option>
                                <option value="remainFormWithMessage">Remain on form with message</option>
                                <option value="redirectUrl">Redirect URL</option>
                            </select>
                        </div>

                        <div className='mb-2'>
                            <label>Redirect Url / Message <span style={{ color: 'red' }}> * </span></label>
                            <input
                                placeholder="Enter a redirect URL"
                                type="text"
                                name="redirectUrlOrMessage"
                                className="input-save"
                                value={mainForm.redirectUrlOrMessage}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='mb-2'>
                            <label>Description <span style={{ color: 'red' }}> * </span></label>
                            <textarea
                                placeholder="Enter a description"
                                name="description"
                                className="input-save"
                                value={mainForm.description}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='mb-2'>
                            <label>Action <span style={{ color: 'red' }}> * </span></label>
                            <select
                                name="action"
                                className="input-save"
                                value={mainForm.action}
                                onChange={handleInputChangeAction}
                            >
                                <option>select</option>
                                <option value="Modify Contact Segment">Modify Contact Segment</option>
                                <option value="Push Contact to CRM Form Action">Push Contact to CRM Form Action</option>
                                <option value="Download Asset Form Action">Download Asset Form Action</option>
                            </select>
                        </div>
                        {actionModel &&
                            <div className='action-details' style={{ backgroundColor: '#e4e4e4', padding: '20px' }}>
                                <h5>Action details</h5>
                                <label>Name</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='action_name'
                                    value={actionName}
                                    onChange={(e) => setActionName(e.target.value)}
                                />
                                {mcs &&
                                    <>
                                        <div>
                                            <label>Add to segments</label>
                                            <MultiSelect
                                                name="add_segments"
                                                options={options}
                                                value={selectedMcsAdd}
                                                onChange={setSelectedMcsAdd}
                                                labelledBy="Select"
                                            />
                                        </div>
                                        <div>
                                            <label>Remove from segments</label>
                                            <MultiSelect
                                                name="remove_segments"
                                                options={options}
                                                value={selectedMcsRemove}
                                                onChange={setSelectedMcsRemove}
                                                labelledBy="Select"
                                            />
                                        </div>
                                    </>
                                }
                                {downloadAsset &&
                                    <div className='mb-2'>
                                        <label>Select Asset</label>
                                        <select
                                            name="selectedAsset"
                                            className="input-save"
                                            value={selectedAsset}
                                            onChange={(e) => setSelectedAsset(e.target.value)}
                                        >
                                            <option value="">Select an asset</option>
                                            {assetOptions.map(asset => (
                                                <option key={asset.value} value={asset.value}>
                                                    {asset.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {pushCrm &&
                                    <div className='mb-2'>
                                        <label>Select CRM</label>
                                        <select
                                            name="selectedCrm"
                                            className="input-save"
                                            value={selectedCrm}
                                            onChange={(e) => setSelectedCrm(e.target.value)}
                                        >
                                            <option value="">Select an asset</option>
                                            {crmOptions.map(crm => (
                                                <option key={crm.value} value={crm.value}>
                                                    {crm.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                <div>
                                    <button onClick={handleAddActionDetails} className='btn btn-primary'>
                                        {editIndex !== null ? 'Update Action' : 'Add Action'}
                                    </button>
                                    <button onClick={handleAddActionDetailsClose} className='btn btn-secondary'>
                                        Close
                                    </button>
                                </div>
                            </div>
                        }
                        <div className='actions-list'>
                            {mainForm.actionDetails.length > 0 &&
                                <table className='table striped'> 
                                    <thead>
                                        <tr>
                                            <th>Action Name</th>
                                            <th>Action</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mainForm.actionDetails && mainForm.actionDetails.map((action, index) => (
                                            <tr key={index}>
                                                <td>{action.action_name || ''}</td>
                                                <td>{action.action || ''}</td>
                               
                                                <td>
                                                    <FaEdit onClick={() => handleEditAction(index)} style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                    <MdDeleteForever onClick={() => handleDeleteAction(index)} style={{ cursor: 'pointer' }} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={onHide}>
                            Close
                        </button>
                        <button className="btn btn-primary" onClick={saveForm}>
                            {editorStatus? 'Update' : 'Save'} 
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    export default SaveModel;
