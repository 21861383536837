import React from 'react';
import usericon from "../../components/Assets/socialIcons/UserIcon.png";

const SemiCircleProgressBar = ({ progress }) => {
  const radius = 50;
  const circumference = Math.PI * radius; // Circumference for a semi-circle

  // Ensure the progress value is clamped and adjusted
  let adjustedProgress = Math.max(0, Math.min(progress, 100));

  // Apply the custom conditions
  if (adjustedProgress === 0) {
    adjustedProgress = 0; // No progress, no blue line
  } else if (adjustedProgress < 8) {
    adjustedProgress = 8; // Set progress to 6 if it is less than 6
  } else if (adjustedProgress >= 8 && adjustedProgress <= 10) {
    adjustedProgress = 10; // Set progress to 10 if it is between 6 and 10
  }

  // Calculate the stroke offset
  const strokeDashoffset =
    adjustedProgress > 0
      ? circumference - (adjustedProgress / 100) * circumference
      : circumference; // Fully hidden when progress is 0%

  const showProgress = adjustedProgress > 0; // Boolean flag to check if progress > 0

  // Calculate the position of the end circle at the end of the progress bar
  const angle = (adjustedProgress / 100) * 180; // Map progress to an angle in degrees
  const endX = 60 + radius * Math.cos((180 - angle) * (Math.PI / 180)); // X position
  const endY = 60 - radius * Math.sin((180 - angle) * (Math.PI / 180)); // Y position

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
      <svg width="110" height="70" viewBox="0 0 120 60">
        {/* Background Circle */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          stroke="#E9ECF1"
          strokeWidth="10"
          fill="none"
        />
        {/* Foreground Circle (progress indicator) */}
        {showProgress && adjustedProgress > 0 && (
          <circle
            cx="60"
            cy="60"
            r={radius}
            stroke="#81CDD5"
            strokeWidth="10"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            transform="rotate(180 60 60)" // Ensure the stroke starts from the left (180°)
            strokeLinecap="round"
          />
        )}
        {/* End Circle */}
        {showProgress && (
          <g>
            <circle
              cx={endX}
              cy={endY}
              r="8" // Outer circle radius (increased to 8 from 6)
              fill="#81CDD5" // Same color as the progress line
            />
            <circle
              cx={endX}
              cy={endY}
              r="4" // Inner white circle radius (increased to 4 from 3)
              fill="#FFFFFF" // White color for the center
            />
          </g>
        )}
      </svg>
    </div>
  );
};

const Guage = ({ progress }) => {
  return (
    <div style={{ textAlign: 'center', height: '80px' }}>
      <SemiCircleProgressBar progress={progress} />
      <div style={{ marginTop: '15px' }}>
        <img src={usericon} alt="user_icon" style={{ marginTop: '-90px' }} />
      </div>
      <div className="semi-progress">
        {progress}% {/* Displaying the actual progress value */}
      </div>
    </div>
  );
};

export default Guage;
