import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const GaugeChart = ({ percentage, registered, total, image ,type=0}) => {
  const progressData = [{ value: percentage }];
  const backgroundData = [{ value: 100 }];

  const COLORS = ["#028897", "#e9ecf1"]; 

  return (
    <div className="card flow-card" style={{ width: "90%", maxHeight:'80px' }}>
      <div className="row g-0">
        <div className="col-md-3">
          <div
            className="progress-left"
            style={{ width: 50, textAlign: "center", position: "relative" }}
          >
            <PieChart width={50} height={50}>
              <Pie
                data={backgroundData}
                innerRadius={20}
                outerRadius={25}
                startAngle={90}
                endAngle={-270} 
                dataKey="value"
                fill={COLORS[1]}
              />

             
              <Pie
                data={progressData}
                innerRadius={20}
                outerRadius={25}
                startAngle={90}
                endAngle={90 - (percentage * 3.6)} 
                dataKey="value"
                cornerRadius={25} 
              >
                <Cell fill={COLORS[0]} /> 
              </Pie>
            </PieChart>
            <div
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img src={image} alt="icon" style={{ width: 20, height: 20 }} />
            </div>
          </div>
        </div>
        <div className="col-md-9 progress-right">
          <div className="card-body text-start"  style={{padding: '15px 5px 15px 0px'}}>
            <h5 className="card-title">
              <span>{percentage.toFixed(2)}%</span>
            </h5>
            <p className="registered-reached">
              {type===0 && (<>{registered} Registered out of {total}</>)}
              {type===1 && (<>{registered} Registered out of {total} landing page visits</>)}
              {type===2 && (<>{registered} Attended out of {total} registrations</>)}
              {type===3 && (<>{registered} Contacts sent to crm out of {total} attendee</>)}
              
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GaugeChart;
