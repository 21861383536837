import React, { useEffect, useState } from 'react';
import bg from '../components/Assets/HomeBg.png'
import BasicSpeedDial from "../components/SpeedDial";
import NavbarComp from '../components/NavbarComp';

const Setting = () => {
    const [platforms, setPlatforms] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [formData, setFormData] = useState();

    const handleClick = (platform) => {
        setSelectedPlatform(platform);
        // Set form data
        setFormData({
            alias: platform.alias,
            clientId: platform.details[0]?.clientId || "",
            clientSecret: platform.details[0]?.clientSecret || "",
            accountId: platform.details[0]?.accountId || "",
            redirectUri: platform.details[0]?.redirectUri || "",
            tenantId: platform.details[0]?.tenantId || "",
            developerToken: platform.details[0]?.developerToken || "",
            customerId: platform.details[0]?.customerId || ""
            // Use optional chaining to avoid errors if details array is empty
        });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        const details = [
            {
                clientId: formData.clientId || "",
                clientSecret: formData.clientSecret || "",
                accountId: formData.accountId || "",
                redirectUri: formData.redirectUri || "",
                tenantId: formData.tenantId || "",
                developerToken: formData.developerToken || "",
                customerId: formData.customerId || ""
            }
        ];
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/plateform`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    alias: selectedPlatform.alias,
                    details,
                }),
            });
            setSelectedPlatform(null);
            const data = await response.json();
            if (data._id !== "") {
                alert('Data stored successfully!');
                window.location.reload(); // Refresh the page after successful submission
            }
            else {
                alert('Something went wrong!');
            }
        } catch (error) {
            alert('Error storing data!');
            console.error('Error fetching data:', error);
        }
    };

    const handleCancel = () => {
        setSelectedPlatform(null);
    };

    useEffect(() => {
        // Function to fetch data from the API
        const fetchProgramDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/plateform/list`, {
                    method: 'get',
                    credentials: 'include'
                });
                const data = await response.json();
                setPlatforms(data); // Store the fetched data in state

            } catch (error) {
                console.error('Error fetching program details:', error);
            }
        };

        // Call the fetch function
        fetchProgramDetails();
    }, []);

    return (
        <>
            <NavbarComp></NavbarComp>
            <div
                className="overflow-hidden position-relative"
                style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '91vh'
                }}>
                <div className="container">
                    <div className="row justify-content-center" style={{ paddingTop: '20px' }}>
                        <div className="col-md-10" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: '10px' }}>
                            {platforms && platforms.length > 0 ? (
                                platforms.map(platform => (
                                    <div key={platform.name}
                                        style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center', backgroundColor: '#f9f9f9', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                        onClick={() => handleClick(platform)}>
                                        <b>{platform.name}</b>
                                    </div>
                                ))
                            ) : (
                                <p>No platforms available</p> // Optional: Fallback content
                            )}
                        </div>
                        {selectedPlatform && (
                            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '10px', zIndex: 1000, width: '50%' }}>
                                <h3>{selectedPlatform.name}</h3><br></br>
                                {selectedPlatform.alias === 'zoom' || selectedPlatform.alias === 'abc' ? (
                                    <>
                                        <div>
                                            <label>Client ID:</label>
                                            <input
                                                type="text"
                                                name="clientId"
                                                value={formData.clientId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Client Secret:</label>
                                            <input
                                                type="text"
                                                name="clientSecret"
                                                value={formData.clientSecret}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Account ID :</label>
                                            <input
                                                type="text"
                                                name="accountId"
                                                value={formData.accountId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={handleCancel} style={{ padding: '10px 20px', backgroundColor: '#ccc', border: 'none', borderRadius: '5px' }}>Cancel</button>
                                            <button onClick={handleSubmit} style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px' }}>Submit</button>
                                        </div>
                                    </>
                                ) : selectedPlatform.alias === 'teams' ? (
                                    <>
                                        <div>
                                            <label>Client ID:</label>
                                            <input
                                                type="text"
                                                name="clientId"
                                                value={formData.clientId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Client Secret:</label>
                                            <input
                                                type="text"
                                                name="clientSecret"
                                                value={formData.clientSecret}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Callback Url :</label>
                                            <p>(http://YOUR_SERVER_DOMAIN/api/teams/auth/callback)</p>
                                            <input
                                                type="text"
                                                name="redirectUri"
                                                value={formData.redirectUri}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Tenant ID :</label>
                                            <p>(required if account is personal)</p>
                                            <input
                                                type="text"
                                                name="tenantId"
                                                value={formData.tenantId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={handleCancel} style={{ padding: '10px 20px', backgroundColor: '#ccc', border: 'none', borderRadius: '5px' }}>Cancel</button>
                                            <button onClick={handleSubmit} style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px' }}>Submit</button>
                                        </div>
                                    </>
                                ) : selectedPlatform.alias === 'googlemeet' ? (
                                    <>
                                        <div>
                                            <label>Client ID:</label>
                                            <input
                                                type="text"
                                                name="clientId"
                                                value={formData.clientId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Client Secret:</label>
                                            <input
                                                type="text"
                                                name="clientSecret"
                                                value={formData.clientSecret}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Callback Url :</label>
                                            <p>(http://YOUR_SERVER_DOMAIN/api/google/google/callback)</p>
                                            <input
                                                type="text"
                                                name="redirectUri"
                                                value={formData.redirectUri}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={handleCancel} style={{ padding: '10px 20px', backgroundColor: '#ccc', border: 'none', borderRadius: '5px' }}>Cancel</button>
                                            <button onClick={handleSubmit} style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px' }}>Submit</button>
                                        </div>
                                    </>
                                ) : selectedPlatform.alias === 'facebook' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <button
                                            style={{
                                                padding: '10px 20px',
                                                backgroundColor: '#1877F2',
                                                color: '#fff',
                                                border: 'none',
                                                borderRadius: '5px',
                                                marginBottom: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(`${process.env.REACT_APP_BASE_URL_API}/api/facebook/login`, '_blank'); // Opens the login URL in a new tab
                                            }}
                                        >
                                            Login with {selectedPlatform.name}
                                        </button>
                                    </div>
                                ) : selectedPlatform.alias === 'linkedin' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <button
                                            style={{
                                                padding: '10px 20px',
                                                backgroundColor: '#1877F2',
                                                color: '#fff',
                                                border: 'none',
                                                borderRadius: '5px',
                                                marginBottom: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(`${process.env.REACT_APP_BASE_URL_API}/api/linkedInPro/login`, '_blank'); // Opens the login URL in a new tab
                                            }}
                                        >
                                            Login with {selectedPlatform.name}
                                        </button>
                                    </div>
                                ) : selectedPlatform.alias === 'google' ? (
                                    <>
                                        <div>
                                            <label>Client ID:</label>
                                            <input
                                                type="text"
                                                name="clientId"
                                                value={formData.clientId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Client Secret:</label>
                                            <input
                                                type="text"
                                                name="clientSecret"
                                                value={formData.clientSecret}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Callback Url :</label>
                                            <p>(http://YOUR_SERVER_DOMAIN/api/google/loginCallback)</p>
                                            <input
                                                type="text"
                                                name="redirectUri"
                                                value={formData.redirectUri}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Developer Token :</label>
                                            <input
                                                type="text"
                                                name="developerToken"
                                                value={formData.developerToken}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div>
                                            <label>Customer Id :</label>
                                            <input
                                                type="text"
                                                name="customerId"
                                                value={formData.customerId}
                                                onChange={handleChange}
                                                style={{ width: '100%', padding: '8px', margin: '10px 0', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={handleCancel} style={{ padding: '10px 20px', backgroundColor: '#ccc', border: 'none', borderRadius: '5px' }}>Cancel</button>
                                            <button onClick={handleSubmit} style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px' }}>Submit</button>
                                        </div>
                                    </>
                                ) : (
                                    <div style={{ textAlign: 'center', color: '#888', marginTop: '20px' }}>
                                        No settings available for this platform.
                                    </div>
                                )}
                            </div>
                        )}

                        {selectedPlatform && (
                            <div onClick={handleCancel} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} />
                        )}
                    </div>
                </div>
                <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>

                </div>
            </div>
        </>
    );
}
export default Setting;