import React, { useRef, useEffect } from 'react';
import CardComp from './journeyModels/CardComp';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useGlobal } from '../../context/GlobalProvider';
const Register = ({handleChannelModel,fetchProgramDetails}) => {
    const { 
        register_registrationListDatas,
        register_reminderDatas,
        register_attendDatas,
        register_connectionIds,
     } = useGlobal();
    const getClassName = (data) => {
        if (data.length === 1) return 'single';
        if (data.length === 2) return 'double';
        if (data.length > 2) return 'multiples';
        return '';
    };
    const elementsRefs = useRef({});

    

    return (
        <Xwrapper>
            <div className='register-tab'>
                <div className='row ' style={{marginLeft:'30px'}}>
                    <div className='col-sm-1'></div>
                    <div className={`col-sm-3 channel-list card-center ${getClassName(register_registrationListDatas)}`}>
                        {register_registrationListDatas.length > 0 && register_registrationListDatas.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={`col-sm-3 registration-page card-center ${getClassName(register_reminderDatas)}`}>
                        {register_reminderDatas.length > 0 && register_reminderDatas.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={`col-sm-3 form-list card-center ${getClassName(register_attendDatas)}`}>
                        {register_attendDatas.length > 0 && register_attendDatas.map((data, index) => (
                            <div className='card-wrap' key={index} id={`box-${data.id}`}
                                ref={(el) => (elementsRefs.current[data.id] = el)}>
                                <CardComp
                                    channel={data.channel}
                                    no_campains={data.no_campains}
                                    expected={data.expected}
                                    reach={data.reach}
                                    icon={data.icon}
                                    status={data.status}
                                    isEnabled={data.isEnabled}
                                    builder= {data.builder}
                                    handleChannelModel={handleChannelModel}
                                    cardType={data.cardType}
                                    cardId={data.cardId}
                                    fetchProgramDetails={fetchProgramDetails}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {register_connectionIds.map((connection, index) => (
                    <Xarrow
                        key={index}
                        start={`box-${connection.source}`}
                        end={`box-${connection.target}`}
                        startAnchor='right'
                        endAnchor='left'
                        color="#B3B3B3"
                        strokeWidth={2}
                        headSize={6}
                        curveness={0.1}
                        path={"grid"}
                    />
                ))}
            </div>
        </Xwrapper>
    );
}

export default Register;
