import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PageBuilderModal from "../Journey/journeyModels/BuilderModal";
import { toast } from "react-toastify";
import axios from 'axios';
const PagePreview= ({PageModal,channelData, closePageModal,programId,fetchProgramDetails,pageUrl})=>{
    const [PageHtml,setPageHtml]=useState('');
    const [isPublished,setisPublished]=useState('');
    const [Iframe,setIframe]=useState(false);
    useEffect(() => {
        if(channelData.cardId && PageModal && !Iframe)
        fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/data/${channelData.cardId}?key=${Date.now()}`,{
            method: 'GET',
          })
            .then(res => {
                // console.log(res);
                if(res.status===404){
                    return null;
                }
                return res.json()
            })
            .then(data => {
                if(data===null){
                    setPageHtml(`Page not found`);
                    return
                }
                setPageHtml(data.htmlContent);
                setisPublished(data.isPublished);
                // console.log(data);
            })
            .catch(error => console.error('Error fetching form:', error));
    }, [PageModal,Iframe]);
    const handlePublish=async(publish)=>{
        try {
            const response = await axios.put(
              `${process.env.REACT_APP_BASE_URL_API}/pages/publish/${channelData.cardId}`,
              { is_published: publish },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
              }
            );
            // console.log('Page publish status updated:', response.data);
            if(publish)
                toast.success(`Page has been published!`);
            else
                toast.error(`Page has been unpublished!`);
            setisPublished(publish);
            const metricsdata = await fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/metrics/${channelData.cardId}`, {
                method: 'get',
                credentials: 'include',
            });
            const metrics= await metricsdata.json();
            const selectedData={
                programId:programId, 
                builder:'reachBuilder',
                projected:metrics.totalClicks,
                actual:'0', 
                cardId:channelData.cardId, 
                cardName:'reach_page_label',
                cardType:'page',
                cardstatus:publish?'1':'0'
            };
            await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder/saveSelectedCardbytype`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedData),
            });
            fetchProgramDetails()
        } catch (error) {
            toast.error('Something went wrong');
            console.error('Error updating page publish status:', error.response ? error.response.data : error.message);
        }
    };
    const handleCopyLink = () => {
        const url = pageUrl;
        navigator.clipboard.writeText(url)
            .then(() => {
                console.log('Link copied to clipboard!');
                toast.success('Link copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };
    // console.log(isPublished,pageUrl);
    
    return(
            <Modal
            show={PageModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={closePageModal} fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Page Preview
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Iframe ?(
                        <PageBuilderModal PageModal={Iframe} channelData={channelData} closePageModal={setIframe} programId={programId} fetchProgramDetails={fetchProgramDetails}></PageBuilderModal>
                    ):(
                        <div dangerouslySetInnerHTML={{__html:PageHtml}}>

                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 align-items-end">
                            <div className="col-md-6">
                                {isPublished && <>
                                <h5 className='list-head'>Registration Page URL</h5>
                                <div className='footer-input'>
                                    <div className='url-input'>
                                        <input type='text' className='page-url pe-none' value={pageUrl} readOnly />
                                    </div>
                                    <div className='copy-btn'>
                                        <button onClick={handleCopyLink}><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 0.8V17.6C24 18.042 23.642 18.4 23.2 18.4H20C19.558 18.4 19.2 18.042 19.2 17.6C19.2 17.158 19.558 16.8 20 16.8H22.4V1.6H7.2V4C7.2 4.44201 6.84201 4.8 6.4 4.8C5.95799 4.8 5.6 4.44201 5.6 4V0.8C5.6 0.35799 5.95799 0 6.4 0H23.2C23.642 0 24 0.35799 24 0.8ZM17.6 5.6H0.8C0.35799 5.6 0 5.95799 0 6.4V23.2C0 23.642 0.35799 24 0.8 24H17.6C18.042 24 18.4 23.642 18.4 23.2V6.4C18.4 5.95799 18.042 5.6 17.6 5.6Z" fill="#028897" />
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                                </>}
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="button-div" style={{display: 'flex', width:'70%'}}>
                                <button onClick={()=>setIframe(true)}>Edit</button>
                                <button className="ms-4" onClick={()=>handlePublish(!isPublished)}>{isPublished&&<>unpublish</>}{!isPublished&&<>publish</>}</button> 

                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </Modal.Footer>
            </Modal>
    );
}
export default PagePreview;