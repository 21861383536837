import React, { useEffect, useState } from 'react';
import bg from '../components/Assets/HomeBg.png'
import BasicSpeedDial from "../components/SpeedDial";
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Settings } from "@mui/icons-material";
import { Logout } from "@mui/icons-material";
import ProgramSlider from "../components/ProgramSlider";
import NavbarComp from '../components/NavbarComp';
import BuilderLoader from './Journey/journeyModels/BuilderLoader';
const actions = [
    { icon: <FileCopyIcon sx={{ color: 'black' }} />, name: 'Copy', onclick: () => { console.log('add onclick action here') } },
    { icon: <Settings sx={{ color: 'black' }} />, name: 'settings', onclick: () => { console.log('add onclick action here') } },
    { icon: <Logout sx={{ color: 'red' }} />, name: 'logout', onclick: () => { console.log('add onclick action here') } },
];
const Program = () => {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BASE_URL_API}/api/program`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setPrograms(data);
                setLoading(false);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    return (
        <>
            <NavbarComp></NavbarComp>
            <div
                className="overflow-hidden position-relative"
                style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '91vh'
                }}>
                <div className="Container my-5">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-5">
                            <ul className="nav nav-tabs w-100 rounded-pill" id="PreviewTab" role="tablist">
                                <li className="nav-item w-50">
                                    <a className="nav-link active rounded-pill" id="First-tab" data-bs-toggle="tab" href="#Firsttab" role="tab">Select Your AI Bot</a>
                                </li>
                                <li className="nav-item w-50">
                                    <a className="nav-link rounded-pill" id="Second-tab" data-bs-toggle="tab" href="#Event" role="tab">My Programs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="Firsttab" role="tabpanel" aria-labelledby="objectives">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    {loading ? <BuilderLoader/> : <ProgramSlider programs={{ programs }}></ProgramSlider>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade show active" id="Firsttab" role="tabpanel" aria-labelledby="objectives">

                    </div>
                </div>

                <div className="position-absolute" style={{ bottom: '60px', left: '32px' }}>
                    <BasicSpeedDial actions={actions}></BasicSpeedDial>
                </div>

            </div>
        </>
    );
}
export default Program;