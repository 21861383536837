import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthProvider from './AuthProvider';

const ProtectedRoute = ({ element: Component }) => {
    return (
        <AuthProvider>
            {Component ? <Component /> : <Navigate to="/" />}
        </AuthProvider>
    );
};

export default ProtectedRoute;
