import React from 'react';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        borderRadius: "50%", // Make it circular
        zIndex: 1, // Ensure it stays on top
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        borderRadius: "50%", // Make it circular, // Adjust padding
        zIndex: 1, // Ensure it stays on top
      }}
      onClick={onClick}
    />
  );
};

export { CustomPrevArrow, CustomNextArrow };
