function formatNumber(num) {
    if (num >= 1_000_000_000) {
        return Math.floor(num / 1_000_000_000) + 'B'; // Billions
    } else if (num >= 1_000_000) {
        return Math.floor(num / 1_000_000) + 'M'; // Millions
    } else if (num >= 1_000) {
        return Math.floor(num / 1_000) + 'K'; // Thousands
    } else {
        return num.toString(); 
    }
}

function NumberShorthand({ value }) {
    return <>{formatNumber(value)}</>;
}

export default NumberShorthand;
