import React, { useState } from 'react';
import { Button } from '@mui/material';

const NameCell = ({ name }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const toggleTooltip = () => {
        setTooltipVisible(!isTooltipVisible);
    };

    const handleDisabled = () => {
        setIsDisabled(!isDisabled);
        setTooltipVisible(!isTooltipVisible);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Button
                onClick={toggleTooltip}
                style={{ 
                    textTransform: 'none',
                    color: 'black',
                 }}
            >
                {name}
            </Button>

            {/* Tooltip */}
            {isTooltipVisible && (
                <div
                    onClick={handleDisabled} // Handle enable/disable on click
                    style={{
                        position: 'absolute',
                        right: '13px', // Adjust tooltip's position
                        bottom: '7px',
                        width: '88px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',   
                        height:'40px',
                        padding: '5px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: isDisabled ? 'rgb(8, 171, 110)' : 'rgb(234, 67, 53)',
                        cursor: 'pointer',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000 // Ensure tooltip is above other content
                    }}
                >
                    {isDisabled ? 'Enable' : 'Disable'}
                </div>
            )}
        </div>
    );
};

export default NameCell;
