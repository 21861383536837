import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import './CSS/Style.css';
import {ReactComponent as Img1} from './Assets/ProgramIcons/1.svg';
import {ReactComponent as Img2} from './Assets/ProgramIcons/2.svg';
import {ReactComponent as Img3} from './Assets/ProgramIcons/3.svg';
import {ReactComponent as Img4} from './Assets/ProgramIcons/4.svg';
import {ReactComponent as Img5} from './Assets/ProgramIcons/5.svg';
import {ReactComponent as Img6} from './Assets/ProgramIcons/6.svg';
import {ReactComponent as Img7} from './Assets/ProgramIcons/7.svg';
import {ReactComponent as Img8} from './Assets/ProgramIcons/8.svg';
import { CustomPrevArrow, CustomNextArrow } from './CustomArrows';
// import image from '../images/HomeIcons'
function ProgramSlider({programs}) {
  const settings = {
    dots: true,
    infinite: programs.programs.length>3?true:false,
    speed: 500,
    slidesToShow: programs.programs.length>3?3:programs.programs.length,
    slidesToScroll: 3,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    autoplay: true,  
    autoplaySpeed: 3000
  };
	const navigate = useNavigate();

    const handleCardClick  = (alias) => {
        navigate(`/program/${alias}`);
    };
    function geticon(id) {
        switch(id) {
            case 1:
                return <Img1 className="svg-icon" />;
            case 2:
                return <Img2 className="svg-icon" />;
            case 3:
                return <Img3 className="svg-icon" />;
            case 4:
                return <Img4 className="svg-icon" />;
            case 5:
                return <Img5 className="svg-icon" />;
            case 6:
                return <Img6 className="svg-icon" />;
            case 7:
                return <Img7 className="svg-icon" />;
            case 8:
                return <Img8 className="svg-icon" />;
            default:
                return <Img1 className="svg-icon" />;
        }
    }
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {programs.programs.map(program => (
            // <div 
            //   key={program._id} 
            //   className="program-card" 
            //   onClick={() => handleCardClick(program.alias)}
            // >
            //  <h3> {program.name} </h3>
            // </div>
            <div className="slide" key={program._id}>
                <div className="m-5 d-flex align-items-end position-relative" onClick={() => handleCardClick(program.alias)}>  
                    <div className="svg-container text-start m-auto d-inline" >
                        {/* <Img1 className="svg-icon"/> */}
                        {geticon(program.icon)}
                    </div>   
                    <div className="text-start">
                        <div className="mb-3 card-title" dangerouslySetInnerHTML={{ __html: program.name }}>
                            {/* Generate<br></br> Leads Program */}
                            {/* {program.name} */}
                        </div>
                        <div className="card-text">
                            {program.description}
                        </div>
                    </div>

                </div>
            </div>
            
        ))}
        {/* <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img1 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Generate<br></br> Leads Program
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img2 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Attract<br></br> Event Audience
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img3 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Downloads <br></br>& Signups
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img4 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Drive Online <br></br>Purchases
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img5 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Upsell /<br></br> Cross sell
                        
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img6 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Publish <br></br> Newsletters
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img7 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Drive <br></br>Referrals
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div>
        <div className="slide">
            <div className="m-5 d-flex align-items-end position-relative">  
                <div className="svg-container text-start m-auto d-inline" >
                    <Img8 className="svg-icon"/>
                </div>   
                <div className="text-start">
                    <div className="mb-3 card-title">
                        Get Feedbacks <br></br>/ Survey
                    </div>
                    <div className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>

            </div>
        </div> */}

      </Slider>
    </div>
  );
}

export default ProgramSlider;