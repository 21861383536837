import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkLogin = async () => {
            try {
                const user = localStorage.getItem('user'); 
                if (user) {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL_API}/login/checkUser`, {}, {
                        withCredentials: true, 
                    });

                    if (response.data.isAuthenticated) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                setIsAuthenticated(false);
                navigate('/');
            }
        };

        checkLogin();
    }, [navigate]);

    if (!isAuthenticated) {
        return <><p>Loading...</p></>;
    }

    return <>{children}</>;
};

export default AuthProvider;
