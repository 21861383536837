import React from "react";
import { Link } from "react-router-dom";
const Home =()=>{
    
    return(
      <>
        <Link to='/program'>Program</Link><br/>
        <Link to='/Editor'>Form Editor</Link><br/>
        <Link to='/formslist'>Forms List</Link><br/>
        <Link to='/emailTemplates'>Email Builder</Link><br/>
        <Link to='/journeyMain'>journeyMain Development</Link>
        <br/><Link to='/pages/edit'>Page builder</Link>
      </>
    );

}
export default Home;