// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-container {
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out; 
    margin: 0 auto;
    border: 1px solid #ddd;
}

.preview-mobile {
    width: 375px;
    /* height: 667px;  */
}

.preview-laptop {
    width: 768px;
    /* height: 100%;  */
}

.preview-desktop {
    width: 100%;
    height: 100%; 
    overflow-y: scroll;
}

.view-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

@media (max-width: 576px) {
    .view-toggle {
        flex-direction: column;
        gap: 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/screens/formBuilder/PreviewModel.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI;QACI,sBAAsB;QACtB,QAAQ;IACZ;AACJ","sourcesContent":[".preview-container {\n    transition: width 0.5s ease-in-out, height 0.5s ease-in-out; \n    margin: 0 auto;\n    border: 1px solid #ddd;\n}\n\n.preview-mobile {\n    width: 375px;\n    /* height: 667px;  */\n}\n\n.preview-laptop {\n    width: 768px;\n    /* height: 100%;  */\n}\n\n.preview-desktop {\n    width: 100%;\n    height: 100%; \n    overflow-y: scroll;\n}\n\n.view-toggle {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    margin-left: auto;\n}\n\n@media (max-width: 576px) {\n    .view-toggle {\n        flex-direction: column;\n        gap: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
