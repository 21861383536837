import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BsEye } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ReactComponent as AiIcon } from '../components/Assets/AiIcon.svg';
import { TiEdit } from "react-icons/ti";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle } from "react-icons/fa";
import NavbarComp from "../components/NavbarComp";
import HeaderComp from '../components/HeaderComp';
import BasicSpeedDial from "../components/SpeedDial";
import ProgressStepper from "./EventProgram/ProgressStepper";
import Linkedinspeaker from "./EventProgram/Linkedinspeaker";
import EventDetails from "./EventProgram/EventDetails";
import EventReachExpectations from "./EventProgram/EventExpectations";
import CustomizableText from "./EventProgram/ValueProposition";
import '../components/CSS/Style.css'
import axios from 'axios';
import { useGlobal } from "../context/GlobalProvider";

const steps = [
  {
    label: '1',
    name: 'step 1',
    className: 'step1 custom-step',
    subSteps: [
      { label: 'Step 1.1' },
      { label: 'Step 1.2' },
      { label: 'Step 1.3' },
      { label: 'Step 1.4' },
    ],
  },
  {
    label: '2',
    name: 'step 2',
    className: 'step2 custom-step',
    subSteps: [
      { label: 'Step 2.1' },
      { label: 'Step 2.2' },
      { label: 'Step 2.3' },
      { label: 'Step 2.4' },
    ],
  },
  {
    label: '3',
    name: 'step 3',
    className: 'step3 custom-step',
    subSteps: [
      { label: 'Step 3.1' },
      { label: 'Step 3.2' },
      { label: 'Step 3.3' },
      { label: 'Step 3.4' },
    ],
  },
];

const EventProgram = () => {
  const navigate = useNavigate();
  const [tab, setactivetab] = useState(0);
  const tabname = ['Objectives', 'Event Details', 'Speaker Details', 'Event Reach Expectations'];
  const [objectives, setobjectives] = useState([]);
  const [responseObjectives, setResponseObjectives] = useState('');
  const [eventType, setEventType] = useState('');
  const [valueProposition, setValueProposition] = useState({ segment: 'consumers', benefit: 'benefit', differentiation: 'differentiation' });
  const [productName, setProductName] = useState('');
  const [suggestedEventNames, setSuggestedEventNames] = useState([]);
  const [eventDetails, setEventDetails] = useState({});
  const [speakerDetails, setSpeakerDetails] = useState({});
  const [expectationDetails, setExpectationDetails] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { ToggleAnimation } = useGlobal();
  const getvalueproposition = () => {
    return `Our ${productName || 'product/service'} helps ${valueProposition.segment}, ${valueProposition.benefit} by ${valueProposition.differentiation}.`;
  }


  const formPayload = {
    programType: "event",
    tabStepNo: tab,
    isStepCompleted: 1,
    responseObjectives: objectives,
    productName: productName,
    valueProposition: getvalueproposition(),
    eventName: eventDetails.eventName,
    eventType: eventType,
    eventDate: eventDetails.eventdate,
    eventVenue: eventDetails.venue,
    speakerDetails: speakerDetails.speaker,
    eventVenueDetails: eventDetails.venue === "online"
      ? [{
        venueId: '',
        venueName: eventDetails.selectedMeetingName,
        venueLink: eventDetails.selectedMeetingUrl,
        venuePhotoUrl: '',
        venueOther: eventDetails.chosenplatform
      }]
      : eventDetails.chosenlocations && eventDetails.chosenlocations.map(location => ({
        venueId: '',
        venueName: location.placeName,
        venueLink: location.mapsLink,
        venuePhotoUrl: location.photoUrl,
        venueOther: location.address
      })),
    channelDetails: expectationDetails.media && expectationDetails.media.map(item => ({
      channelId: 0,
      isEnabled: item.enabled,
      channelName: item.name,
      exRegistrations: item.registrations,
      exBudget: item.budget,
      exCostperregister: item.costPerRegistration,
    }))
  };
  const channelCards = [
    ...(expectationDetails.media
      ? expectationDetails.media
        .filter(item => item.enabled)
        .map((item, index) => ({
          cardType: "channel",
          cardId: 0,
          cardName: item.name,
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: (index + 1).toString(),
        }))
      : []),
    {
      cardType: "page",
      cardId: "0",
      cardName: "reach_page_label",
      isEnabled: true,
      projectedCount: "0",
      actualCount: "0",
      selectedData: [],
      setupStatus: "0",
      childConnector: (expectationDetails.media ? expectationDetails.media.filter(item => item.enabled).length + 1 : 1).toString(),
    },
    {
      cardType: "form",
      cardId: '0',
      cardName: "reach_form_label",
      isEnabled: true,
      projectedCount: "0",
      actualCount: "0",
      selectedData: [],
      setupStatus: "0",
      childConnector: (expectationDetails.media ? expectationDetails.media.filter(item => item.enabled).length + 2 : 2).toString(),
    }
  ];

  const handleEventDetailsData = useCallback((data) => {
    setEventDetails(data);
  }, []);

  const handleSpeakerDetailsData = useCallback((data) => {
    setSpeakerDetails(data);
  }, []);

  const handleExpectationDetailsData = useCallback((data) => {
    setExpectationDetails(data);
  }, []);

  const handleNextSubStep = () => {
    const nextSubStep = currentSubStep + 1;
    if (nextSubStep < steps[currentStep].subSteps.length) {
      setCurrentSubStep(nextSubStep);
    } else {
      handleNextStep();
    }
  };

  const handlePreviousSubStep = () => {
    const previousSubStep = currentSubStep - 1;
    if (previousSubStep >= 0) {
      setCurrentSubStep(previousSubStep);
    } else {
      handlePreviousStep();
    }
  };

  const handleNextStep = () => {
    const nextStep = currentStep + 1;
    if (nextStep < steps.length) {
      setCurrentStep(nextStep);
      setCurrentSubStep(0); // Reset sub-step to the first one
    }
  };

  const handlePreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 0) {
      setCurrentStep(previousStep);
      setCurrentSubStep(steps[previousStep].subSteps.length - 1); // Set to last sub-step of the previous step
    }
  };

  const handleSubmit = () => {
    console.log('Form submitted!');
  };

  const handleValueProposition = useCallback((data) => {
    setValueProposition(prevState => ({
      ...prevState,
      ...data
    }));
  }, []);
  const formatDateTime = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleEventFormSubmit = async () => {
    //console.log(expectationDetails.media);
    // Check if the media array is either undefined, null, or empty
    if (!expectationDetails.media || !expectationDetails.media.some(item => item.enabled === true)) {
      toast.error('Please select any one channel', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false;  // Prevent form submission
    }
    //console.log(formPayload);
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programDetails`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formPayload,
        }),

      });
      const data = await response.json();
      const programId = data._id;
      //console.log(data);
      const eventName = formPayload.eventName;
      const currentDateTime = new Date().toISOString().replace(/[^0-9]/g, ''); // Get current datetime without special characters
      // Create segmentName and segmentAlias
      const segmentName = `${eventName} Registration List`;  // Append "registration list" to eventName
      const segmentAlias = `${eventName.toLowerCase().replace(/\s+/g, '_')}_registrationlist_${currentDateTime}`; // Lowercase, no spaces, append datetime

      const responseSeg = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          is_published: 1,
          name: segmentName,
          description: "",
          alias: segmentAlias
        }),

      });
      const dataSeg = await responseSeg.json();
      const segmentId = dataSeg.segmentId;
      //console.log(programId);
      //console.log(dataSeg);

      // Get the last childConnector value from the current channelCards array
      const lastChildConnector = channelCards.length > 0
        ? parseInt(channelCards[channelCards.length - 1].childConnector, 10)
        : 0;

      // Add a new element to the channelCards array with the incremented childConnector value
      channelCards.push({
        cardType: "list",
        cardId: segmentId, // Use the alias as cardId
        cardName: "reach_list_label", //segmentName, // Use the name as cardName
        isEnabled: true,
        projectedCount: "0",
        actualCount: "0",
        selectedData: [],
        setupStatus: "1",
        childConnector: (lastChildConnector + 1).toString(), // Incremented childConnector
      });


      const attendSegmentApi = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          is_published: 1,
          name: `Attend_list_${programId}`,
          description: "",
          alias: `Attend_list_${programId}`
        }),

      });
      const attendData = await attendSegmentApi.json();

      const NonAttendSegmentApi = await fetch(`${process.env.REACT_APP_BASE_URL_API}/segments/createSegment`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          is_published: 1,
          name: `Non_Attend_list_${programId}`,
          description: "",
          alias: `Non_Attend_list_${programId}`
        }),

      });
      const NonAttendData = await NonAttendSegmentApi.json();

      const registerBuilder = [
        {
          cardType: "r_list",
          cardId: segmentId,
          cardName: "register_list_label",// segmentName,
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "1",
          childConnector: "1",
        },
        {
          cardType: "r_reminder",
          cardId: segmentId,
          cardName: 'register_email',
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "2",
        },
        {
          cardType: "r_reminder",
          cardId: segmentId,
          cardName: 'register_whatsapp',
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "3",
        },
        {
          cardType: "r_attendlist",
          cardId: attendData.segmentId,
          cardName: 'register_attend_list_label',
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "4",
        },

      ]

      const attendBuilder = [
        {
          cardType: "attendlist",
          cardId: attendData.segmentId,
          cardName: 'attend_list_label',
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "1",
        },
        {
          cardType: "attendchannel",
          cardId: attendData.segmentId,
          cardName: "attend_email",
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "2",
        },
        {
          cardType: "attendchannel",
          cardId: attendData.segmentId,
          cardName: "attend_crm",
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "3",
        },
        {
          cardType: "non_attendlist",
          cardId: NonAttendData.segmentId,
          cardName: 'non_attend_list_label',
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "4",
        },
        {
          cardType: "non_attendchannel",
          cardId: NonAttendData.segmentId,
          cardName: "non_attend_email",
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "5",
        },
        {
          cardType: "non_attendchannel",
          cardId: NonAttendData.segmentId,
          cardName: "non_attend_crm",
          isEnabled: true,
          projectedCount: "0",
          actualCount: "0",
          selectedData: [],
          setupStatus: "0",
          childConnector: "6",
        },
      ]
      //page and form clone 

      const page = await fetch(`${process.env.REACT_APP_BASE_URL_API}/pages/new`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          is_published: 0,
          pagename: `${eventName}_page`,
          EventDetails: formPayload,
          programDetailId: programId,
          formName: `${eventName} Form`,
          segmentId: segmentId,
          segmentName: segmentName,
        }),
      });
      const pagedata = await page.json();
      let pageId;
      if (pagedata?.page?._id) {
        pageId = pagedata?.page?._id
      } else {
        toast.error('failed to clone page', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
        return false
      }




      /*if (!emailResponse.ok || !emailData.clonedTemplateIds) {
        alert('Failed to create emails');
        return;
      }*/
      const updatedChannelCards = channelCards.map((card) => {
        if (card.cardType === 'page') {
          return { ...card, cardId: pageId };
        }
        else if (card.cardType === 'form') {
          return { ...card, cardId: pageId };
        }
        return card;

      });
      const responseProgramBuilder = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/programBuilder`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          programDetailId: programId,
          reachBuilder: updatedChannelCards,
          attendBuilder: attendBuilder,
          registerBuilder: registerBuilder
        }),
      });
      if (responseProgramBuilder) {
        setLoading(false);
        ToggleAnimation(true);
        navigate(`/journey/${programId}`);
      }

    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const tabchange = (direction) => {
    if (direction === 'up' && tab > 0) {
      setactivetab(tab - 1);
    } else if (direction === 'down' && tab < 3) {
      if (tab === 0) {
        fetchEventNames(); // Invoke fetchEventNames
      }
      setactivetab(tab + 1);
    }
  };

  const handleobjective = async (objectives) => {
    setobjectives(prev => {
      let updatedObjectives;
      if (prev.includes(objectives)) {
        updatedObjectives = prev.filter(item => item !== objectives);
      } else {
        updatedObjectives = [...prev, objectives];
      }
      // Make the API call with the updated objectives
      fetchOjectiveData(updatedObjectives);

      return updatedObjectives;
    });
  }
  //Suggested Objectives by Open AI
  const fetchOjectiveData = async (objectives) => {
    if (objectives.length > 0) {
      const query = objectives.join(',').toLowerCase();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getObjectives`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            objectives: query,
          }),
        });
        const data = await response.json();
        setResponseObjectives(data.response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setResponseObjectives("");
    }
  };

  const getbuttonclass = (vars) => {
    return objectives.includes(vars) ? 'btn btn-sm btn-gradient me-2 cfs-5 py-2' : 'btn btn-sm btn-outline-gradient me-2 cfs-5 py-2';
  }
  //Suggested Event Name by Open AI
  const fetchEventNames = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API}/api/ai/getEventName`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          objectives: responseObjectives,
          serviceName: productName,
        }),
      });
      const data = await response.json();
      setSuggestedEventNames(data.response);
    } catch (error) {
      console.error("Error fetching event names:", error);
    }
  };
  const handlechangeeventtype = (mode) => {
    setEventType(mode);
  }
  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }

  const handleCheckFields = () => {
    //console.log(eventDetails);
    //console.log(tab);
    if (tab === 0 && !responseObjectives) {
      toast.error('Please select Objectives', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && !eventDetails.eventName) {
      toast.error('Please select Event Name', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && (formPayload.eventDate.endDate === null || formPayload.eventDate.startDate === null || formPayload.eventDate.endDate === undefined || formPayload.eventDate.startDate === undefined)) {
      toast.error('Please select Event Date', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 0 && formPayload.productName === '') {
      toast.error('Please Enter product Name', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 0 && formPayload.eventType === '') {
      toast.error('Please select Event Type', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && (eventDetails.venue === '' || eventDetails.venue === null)) {
      toast.error('Please select Event Venue', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && eventDetails.venue === 'offline' && (!eventDetails.chosenlocations || eventDetails.chosenlocations.length === 0)) {
      toast.error('Please add one Location', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && eventDetails.venue === 'online' && !eventDetails.chosenplatform != "") {
      toast.error('Please Select Online Platform', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && eventDetails.venue === 'online' && (eventDetails.chosenplatform === 'google' || eventDetails.chosenplatform === 'meet' || eventDetails.chosenplatform === 'teams') && !eventDetails.selectedMeetingName != "") {
      toast.error('Please Select Meeting', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else if (tab === 1 && eventDetails.venue === 'online' && !eventDetails.selectedMeetingUrl != "") {
      toast.error('Please Add Meeting Url', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return false
    }
    else {
      return true
    }
  }

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const fetchGoogleMeetings = fetch(`${process.env.REACT_APP_BASE_URL_API}/api/google/getGoogleMeetings`, {
          method: 'GET',
          credentials: 'include',
        });
        const fetchZoomMeetings = fetch(`${process.env.REACT_APP_BASE_URL_API}/api/zoom/getZoomMeetings`, {
          method: 'GET',
          credentials: 'include',
        });
        const fetchTeamsMeetings = fetch(`${process.env.REACT_APP_BASE_URL_API}/api/teams/getTeamsMeetings`, {
          method: 'GET',
          credentials: 'include',
        });
  
        const [data1, data2, data3] = await Promise.all([
          fetchGoogleMeetings,
          fetchZoomMeetings,
          fetchTeamsMeetings,
        ]);
        const parsedData1 = await data1.json();
        const parsedData2 = await data2.json();
        const parsedData3 = await data3.json();
  
        if (parsedData1 && parsedData2 && parsedData3) {
          console.log('Meetings loaded successfully');
        } else {
          console.log('Failed to load updated meetings');
        }
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };
  
    fetchMeetings();
  }, []);
  
  return (
    <div className="app">
      <NavbarComp />
      <div className="content overflow-hidden">
        <HeaderComp></HeaderComp>
        <ToastContainer />
        <div className="container-fluid px-4 pt-3">
          <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">Basic Event Information</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            {/* Tab Content Start */}
            <div className="offcanvas-body">
              <ul className="nav nav-tabs" id="PreviewTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="objectives-tab" data-bs-toggle="tab" href="#objectives" role="tab">Objectives</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="Event-tab" data-bs-toggle="tab" href="#Event" role="tab">Event</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="Speaker-tab" data-bs-toggle="tab" href="#Speaker" role="tab">Speaker</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="Goals-tab" data-bs-toggle="tab" href="#Goals" role="tab">Goals</a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="objectives" role="tabpanel" aria-labelledby="objectives">
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-md-12 p-0">
                        <div className="ms-2 position-relative">

                          <label className="form-label fs-sm">Objectives</label>
                          <div className="mb-3">
                            {objectives.includes('Showcase a Product/Service') && <button className="btn btn-sm btn-outline-success btn-success-nohover fw-bolder me-2 mt-2" style={{ fontSize: '10px', backgroundColor: '#E6F4F5', height: '40px' }}><FaCheckCircle></FaCheckCircle><span className="ms-2">Showcase a Product/Service</span></button>}
                            {objectives.includes('Generate Leads') && <button className="btn btn-sm btn-outline-success btn-success-nohover fw-bolder me-2 mt-2" style={{ fontSize: '10px', backgroundColor: '#E6F4F5', height: '40px' }}><FaCheckCircle></FaCheckCircle><span className="ms-2">Generate Leads</span></button>}
                            {objectives.includes('Business Networking') && <button className="btn btn-sm btn-outline-success btn-success-nohover fw-bolder me-2 mt-2" style={{ fontSize: '10px', backgroundColor: '#E6F4F5', height: '40px' }}><FaCheckCircle></FaCheckCircle><span className="ms-2">Business Networking</span></button>}
                            {objectives.includes('Educate/Train Audience') && <button className="btn btn-sm btn-outline-success btn-success-nohover fw-bolder me-2 mt-2" style={{
                              fontSize: '10px',
                              backgroundColor: '#E6F4F5', height: '40px'
                            }}><FaCheckCircle></FaCheckCircle><span className="ms-2">Educate/Train Audience</span></button>}
                            {objectives.includes('Promote thought leadership') && <button className="btn btn-sm btn-outline-success btn-success-nohover fw-bolder me-2 mt-2" style={{ fontSize: '10px', backgroundColor: '#E6F4F5', height: '40px' }}><FaCheckCircle></FaCheckCircle><span className="ms-2">Promote thought leadership</span></button>}
                            {objectives.includes('Promote Health and Wellness') && <button className="btn btn-sm btn-outline-success btn-success-nohover fw-bolder me-2 mt-2" style={{ fontSize: '10px', backgroundColor: '#E6F4F5', height: '40px' }}><FaCheckCircle></FaCheckCircle><span className="ms-2">Promote Health and Wellness</span></button>}
                          </div>
                          <div className="position-relative">
                            <textarea
                              name="objective"
                              className="form-control shadow-none ps-5 pe-5"
                              rows="1"
                              value={responseObjectives}
                              style={{
                                resize: 'none', border: '2px solid',
                                borderColor: 'rgba(147, 129, 251, 0.4) rgba(101, 127, 247, 0.4) rgba(101, 127, 247, 0.4) rgba(147, 129, 251, 0.4)',
                                borderRadius: '8px', fontSize: '14px', lineHeight: 2, paddingLeft: '60px'
                              }}
                              readOnly
                            ></textarea>
                            <div>
                              <span className="btn btn-link position-absolute"
                                style={{ top: '11px', left: '14px', padding: 0 }}>
                                <AiIcon style={{ height: '25px', width: '25px', color: '#7280f9' }}></AiIcon>
                              </span>
                            </div>
                          </div>

                        </div>
                        {objectives.length > 0 &&
                          <div className="row mb-3">
                            <div className="col-md-12 mt-4 ms-2">
                              <label className="form-label">Product/Service Name</label>
                              <input name="servicename" type="text" value={productName} className="form-control" placeholder="Product/Service Name" readOnly />
                            </div>
                            <div className="col-md-12 mt-4 ms-2">
                              <label className="form-label">Value Proposition</label>
                              <textarea
                                name="valueproposition"
                                className="form-control"
                                rows="3"
                                placeholder="Our Product/Services help Customer Segments who want to Get Jobs Done by Reducing/Avoiding a Customer pain and Increasing/Enabling a Customer Gain"
                                style={{ resize: 'none' }}
                                value={getvalueproposition()}
                                readOnly
                              ></textarea>
                              <div className="col-md-12 mt-4 ms-2">
                                <label className="form-label">Event Type : </label>
                                {eventType === "b2bevent" && <> B2B Events</>}
                                {eventType === "b2cevent" && <> B2C Events</>}
                                {eventType === "bothevent" && <> B2B & B2C Events</>}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="Event" role="tabpanel" aria-labelledby="Event-tab">
                  <div className="container mt-4">
                    <div className="mb-3">
                      <label htmlFor="eventName" className="form-label">Event Name</label>
                      <input type="text" className="form-control" value={eventDetails.eventName} id="eventName" readOnly />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="eventDate" className="form-label">Event Date</label>
                      <input
                        type="text"
                        className="form-control"
                        id="eventDate"
                        value={
                          eventDetails?.eventdate && eventDetails.eventdate.startDate && eventDetails.eventdate.endDate
                            ? `${formatDateTime(eventDetails.eventdate.startDate)} to ${formatDateTime(eventDetails.eventdate.endDate)}`
                            : ''
                        }
                        readOnly
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="eventVenue" className="form-label">Event Venue</label>
                      <input type="text" className="form-control" value={eventDetails.venue} id="eventVenue" readOnly />
                    </div>
                    {eventDetails.venue ? (
                      eventDetails.venue.toLowerCase() === 'offline' ? (
                        <>
                          {eventDetails.chosenlocations.length > 0 && eventDetails.chosenlocations.map((location, index) => (
                            <div key={index} className="mb-4">
                              <div key={index} className="p-3 mb-4 border rounded shadow-sm">
                                <h6>Venue {index + 1}</h6><br></br>
                                <div className="mb-3">
                                  <label htmlFor={`venueName${index}`} className="form-label">Venue Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`venueName${index}`}
                                    value={location.placeName}
                                    readOnly
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor={`venueAddress${index}`} className="form-label">Venue Address</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`venueAddress${index}`}
                                    value={location.address}
                                    readOnly
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor={`googleMapUrl${index}`} className="form-label">Google Map URL</label>
                                  <input
                                    type="url"
                                    className="form-control"
                                    id={`googleMapUrl${index}`}
                                    value={location.mapsLink}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>

                      ) : eventDetails.venue.toLowerCase() === 'online' ? (
                        <>{(eventDetails.chosenplatform === 'google' || eventDetails.chosenplatform === 'meet' || eventDetails.chosenplatform === 'teams') &&
                          <div>
                            <div className="mb-3">
                              <label htmlFor="googleMapUrl" className="form-label">Platform</label>
                              <input type="url" className="form-control" value={eventDetails.chosenplatform} id="googleMapUrl" readOnly />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="googleMapUrl" className="form-label">Meeting Name</label>
                              <input type="url" className="form-control" value={eventDetails.selectedMeetingName} id="googleMapUrl" readOnly />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="googleMapUrl" className="form-label">Meeting Link</label>
                              <input type="url" className="form-control" value={eventDetails.selectedMeetingUrl} id="googleMapUrl" readOnly />
                            </div>
                          </div>
                        }
                          {(eventDetails.chosenplatform === 'others') &&
                            <div>
                              <div className="mb-3">
                                <label htmlFor="googleMapUrl" className="form-label">Platform</label>
                                <input type="url" className="form-control" value={eventDetails.chosenplatform} id="googleMapUrl" readOnly />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="googleMapUrl" className="form-label">Meeting Link</label>
                                <input type="url" className="form-control" value={eventDetails.selectedMeetingUrl} id="googleMapUrl" readOnly />
                              </div>
                            </div>
                          }
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="tab-pane fade" id="Speaker" role="tabpanel" aria-labelledby="Speaker-tab">
                  <div className="row my-3">
                    {speakerDetails.speaker && speakerDetails.speaker.map((data, index) => (
                      <div className="col-md-6">
                        <div className="card position-relative shadow" style={{ borderRadius: "24px" }}>
                          <img src={data.profilePic} alt="Speaker" className="rounded-circle mt-4 mx-auto" height={90} width={90} style={{ padding: "0.15rem", backgroundColor: 'var(--bs-body-bg)', border: "3px solid var(--bs-success)" }} />
                          <div className="card-body text-center">
                            <span className="card-title" style={{ fontSize: "14px" }}>
                              <a className="text-success" href={data.linkedinURL} target="_blank" rel="noopener noreferrer">
                                <p className="card-text text-truncate" style={{ fontSize: "14px", cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top"
                                  title={data.name} >{data.name}</p>
                              </a>
                            </span>
                            <p className="card-text text-truncate" style={{ fontSize: "14px", cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top"
                              title={data.occupation} >{data.occupation}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tab-pane fade pt-4" id="Goals" role="tabpanel" aria-labelledby="Goals">
                  <div>
                    {expectationDetails.media && <>
                      {expectationDetails.media && expectationDetails.media.map((item, index) => (
                        <div className={`${item.enabled ? 'd-block' : 'd-none'}`} key={index}>
                          <div className="card mb-3 shadow border-0" key={index} style={{ borderRadius: '15px' }}>
                            <div className="card-header bg-light">
                              {item.displayName}
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-5">
                                  <h6 className="text-muted text-nowrap" style={{ fontSize: '10px' }}>No of Registrations</h6>
                                  <p className="text-success fw-bolder mb-0">{formatNumber(item.registrations)}</p>
                                </div>
                                <div className="col-3">
                                  <h6 className="text-muted text-nowrap" style={{ fontSize: '10px' }}>Total Budget</h6>
                                  <p className="text-success fw-bolder mb-0">{formatNumber(item.budget)} $</p>
                                </div>
                                <div className="col-4">
                                  <h6 className="text-muted text-nowrap" style={{ fontSize: '10px' }}>Cost/Registration</h6>
                                  <p className="text-success fw-bolder mb-0">{formatNumber(item.costPerRegistration)} $</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* Tab Content End */}
          </div>
          <div className="row justify-content-around">
            <div className="col-md-2">
              <div style={{ height: '63vh' }} className="bg-light rounded border border-secondary border-2">
                <ProgressStepper
                  steps={steps}
                  currentStep={currentStep}
                  currentSubStep={currentSubStep}
                  setCurrentStep={setCurrentStep}
                  setCurrentSubStep={setCurrentSubStep}
                />
              </div>
            </div>
            <div className="col-md-10">
              <div style={{ height: '63vh' }} className="bg-light rounded  border border-secondary border-2 overflow-hidden position-relative">
                <div className="container ms-3 pt-4">
                  <div className="row align-items-center">
                    <div className="col-md-11">
                      <div className="mb-0 alert bg-secondary text-success p-2 fw-bold">{tabname[tab]}</div>
                    </div>
                    <div className="col-md-1 text-center">
                      <button className="rounded-circle btn btn-secondary custom-hover" style={{ minWidth: 'unset' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop">
                        <BsEye strokeWidth={0.5}></BsEye>
                      </button>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-11">
                      <div className={tab === 0 ? "d-block" : "d-none"}>
                        <div className="mb-3 ms-2 position-relative">
                          <label className="form-label fs-sm">Choose Objectives</label>
                          <textarea
                            name="objective"
                            className="form-control shadow-none pe-5"
                            rows="1"
                            value={responseObjectives}
                            onChange={(e) => setResponseObjectives(e.target.value)}
                            style={{
                              resize: 'none', border: '2px solid',
                              borderColor: 'rgba(147, 129, 251, 0.4) rgba(101, 127, 247, 0.4) rgba(101, 127, 247, 0.4) rgba(147, 129, 251, 0.4)',
                              borderRadius: '8px', fontSize: '14px', lineHeight: 1.5, paddingLeft: '60px', paddingTop: '22px', height: '75px', paddingBottom: '5px'
                            }}
                          ></textarea>
                          <div>
                            <span className="btn btn-link position-absolute"
                              style={{ top: '54px', left: '20px', padding: 0 }}>
                              <AiIcon style={{ height: '25px', width: '25px', color: '#7280f9' }}></AiIcon>
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 ms-2">
                          <button className={getbuttonclass('Showcase a Product/Service')} style={{ fontSize: '11px' }} onClick={() => handleobjective('Showcase a Product/Service')}>Showcase a Product/Service</button>
                          <button className={getbuttonclass('Generate Leads')} style={{ fontSize: '11px' }} onClick={() => handleobjective('Generate Leads')}>Generate Leads</button>
                          <button className={getbuttonclass('Business Networking')} style={{ fontSize: '11px' }} onClick={() => handleobjective('Business Networking')}>Business Networking</button>
                          <button className={getbuttonclass('Educate/Train Audience')} style={{ fontSize: '11px' }} onClick={() => handleobjective('Educate/Train Audience')}>Educate/Train Audience</button>
                          <button className={getbuttonclass('Promote thought leadership')} style={{ fontSize: '11px' }} onClick={() => handleobjective('Promote thought leadership')}>Promote thought leadership</button>
                          <button className={getbuttonclass('Promote Health and Wellness')} style={{ fontSize: '11px' }} onClick={() => handleobjective('Promote Health and Wellness')}>Promote Health and Wellness</button>
                        </div>
                        <div className={objectives.length > 0 ? "d-block ms-2" : "d-none"}>
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label className="form-label">Product/Service Name</label>
                              <input name="servicename" value={productName} onChange={(e) => setProductName(e.target.value)} type="text" className="form-control" placeholder="Product/Service Name" />
                              <label className="form-label mt-3">Event Type</label>
                              <div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="eventType"
                                    id="b2bevent"
                                    value="b2bevent"
                                    checked={eventType === "b2bevent"}
                                    onChange={() => handlechangeeventtype('b2bevent')}
                                  />
                                  <label className="form-check-label" htmlFor="b2bevent">B2B Events</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="eventType"
                                    id="b2cevent"
                                    value="b2cevent"
                                    checked={eventType === "b2cevent"}
                                    onChange={() => handlechangeeventtype('b2cevent')}
                                  />
                                  <label className="form-check-label" htmlFor="b2cevent">B2C Events</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="eventType"
                                    id="bothevent"
                                    value="bothevent"
                                    checked={eventType === "bothevent"}
                                    onChange={() => handlechangeeventtype('bothevent')}
                                  />
                                  <label className="form-check-label" htmlFor="bothevent">Both</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Value Proposition</label>
                              <div className="position-relative">
                                <textarea
                                  name="valueproposition"
                                  className="form-control"
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  readOnly
                                ></textarea>
                                <span className="position-absolute" style={{ top: '5px', left: '5px' }}>
                                  <CustomizableText setvalueproposition={handleValueProposition} productName={productName}></CustomizableText>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className={tab === 1 ? "d-block" : "d-none"}>
                        <EventDetails suggestedEventNames={suggestedEventNames} onData={handleEventDetailsData} />
                      </div>
                      <div className={tab === 2 ? "d-block" : "d-none"}>
                        <Linkedinspeaker onData={handleSpeakerDetailsData} ></Linkedinspeaker>
                        <div className="row mt-5">
                          <button className="btn btn-secondary position-absolute" style={{ width: '10%', bottom: '10px' }} onClick={() => {
                            if (currentStep === steps.length - 1 && currentSubStep === steps[currentStep].subSteps.length - 1) {
                              handleSubmit();
                            } else {
                              handleNextSubStep();
                            }
                            tabchange('down');
                          }
                          }>skip</button>
                        </div>
                      </div>
                      <div className={tab === 3 ? "d-block" : "d-none"}>
                        <EventReachExpectations eventType={eventType} onData={handleExpectationDetailsData} />
                      </div>
                    </div>
                    {/* <button type="submit">submit</button> */}
                    {/* </form> */}
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-md-11">
                      {/* <hr></hr> */}
                    </div>
                    <div className="col-md-1">
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-relative">
                <div className="d-flex flex-column" style={{ right: '15px', position: 'absolute', bottom: '15px' }}>
                  <button className="btn btn-light border rounded-circle" style={{ width: '41px', height: '41px' }} onClick={() => tabchange('up')} ><IoIosArrowUp></IoIosArrowUp></button>
                  <button
                    className="btn btn-light border rounded-circle mt-3" style={{ width: '41px', height: '41px' }}
                    onClick={() => tabchange('down')}
                    disabled={
                      (tab === 0 && !responseObjectives) ||
                      (tab === 1 && !eventDetails.eventName) ||
                      (tab === 3)
                    }
                  >
                    <IoIosArrowDown></IoIosArrowDown>
                  </button>
                </div>
              </div>
              <div className="position-relative">
                <div className="d-flex flex-column" style={{ right: '15px', position: 'absolute', bottom: '15px' }}>
                  <button
                    className="btn btn-light border rounded-circle"
                    style={{ width: '41px', height: '41px' }}
                    onClick={() => {
                      if (currentStep === 0 && currentSubStep === 0) return;
                      handlePreviousSubStep();
                      tabchange('up');
                    }}
                  // disabled={currentStep === 0 && currentSubStep === 0}
                  >
                    <IoIosArrowUp></IoIosArrowUp>
                  </button>
                  <button
                    className="btn btn-light border rounded-circle mt-3"
                    style={{ width: '41px', height: '41px' }}
                    onClick={() => {
                      if (handleCheckFields()) {
                        if (currentStep === steps.length - 1 && currentSubStep === steps[currentStep].subSteps.length - 1) {
                          handleSubmit();
                        } else {
                          handleNextSubStep();
                        }
                        tabchange('down');
                      }
                    }}
                    disabled={
                      (tab === 3) ||
                      (currentStep === steps.length - 1 && currentSubStep === steps[currentStep].subSteps.length - 1)
                    }
                  >
                    <IoIosArrowDown></IoIosArrowDown>
                  </button>
                </div>
              </div>   {/**/}
            </div>
          </div>
          <div className="row justify-content-end my-3">
            <div className="col">
              <BasicSpeedDial></BasicSpeedDial>
            </div>
            <div className="col-md-6">
              <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-secondary me-2 btn-full"
                  onClick={() => navigate("/program")}
                  style={{ border: '2px solid #028897' }}
                  type="button"
                >
                  cancel
                </button>
                <button
                  className={`btn text-white btn-full ${tab !== 3 ? "disabled btn-grey" : "btn-success"}`}
                  onClick={handleEventFormSubmit}

                  type="button"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Start Journey'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default EventProgram;
