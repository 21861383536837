import React, { useState } from 'react';
import { Select, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderColor: 'var(--bs-success)',
      height:'20px',
      '&:hover': {
        borderColor: '#388E3C',
      }
    },
    '& .MuiSelect-select':{
      padding:'10px'
    }
  }));  
  const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      borderBottom: '2px solid var(--bs-success)',
      '&:hover': {
        borderBottomColor: '#388E3C',
      },
      '&.Mui-focused': {
        borderBottomColor: 'var(--bs-success)',
        boxShadow: '0 0 0 2px rgba(76, 175, 80, 0.2)',
      },
    },
    '& .MuiInputBase-input': {
      height: '0.5em',
      padding:'10px !important',
      maxWidth:'150px'
    },
  }));

const CustomizableText = ({setvalueproposition, productName}) => {
  
  const [segment, setSegment] = useState('consumers');
  const [benefit, setBenefit] = useState('benefit');
  const [differentiation, setDifferentiation] = useState('differentiation');

  const [editField, setEditField] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);

  const handleEdit = (field) => {
    setEditField(field);
    if (field === 'segment') {
      setSelectOpen(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setEditField(null);
      if (selectOpen) {
        setSelectOpen(false);
      }
    }, 200); 
  };

  const findValidWord = (value) => {
    const trimmedValue = value.trim();

    if (!trimmedValue || /^\d+$/.test(trimmedValue) || /^[^a-zA-Z0-9]+$/.test(trimmedValue) || /(\d)\1{2,}/.test(trimmedValue)) {
      return false;
    }

    return true;
  };

  const handleSegmentChange = (event) => {
    setSegment(event.target.value);
    setSelectOpen(false);
    if(event.target.value)
    setvalueproposition({segment:event.target.value});
  };

  const handleBenefitChange = (event) => {
    setBenefit(event.target.value);
    if(event.target.value)
    setvalueproposition({ benefit:event.target.value});
  };

  const handleDifferentiationChange = (event) => {
    setDifferentiation(event.target.value);
    if(event.target.value)
      setvalueproposition({ differentiation:event.target.value});
  };

  const handleSegmentBlur = () => {
    if (!findValidWord(segment)) {
      setSegment('consumers'); // Default value
    }
    handleBlur();
  };

  const handleBenefitBlur = () => {
    if (!findValidWord(benefit)) {
      setBenefit('benefit');
    }
    handleBlur();
  };

  const handleDifferentiationBlur = () => {
    if (!findValidWord(differentiation)) {
      setDifferentiation('differentiation');
    }
    handleBlur();
  };

  return (
    <div className="customizable-text-container">
      <div className="customizable-text">
        Our {productName || 'product/service'} helps{' '}
        {editField === 'segment' ? (
          <StyledSelect
            value={segment}
            onChange={handleSegmentChange}
            onBlur={handleSegmentBlur}
            autoFocus
            open={selectOpen}
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
          >
            <MenuItem value="consumers">consumers</MenuItem>
            <MenuItem value="businesses">businesses</MenuItem>
            <MenuItem value="government">government</MenuItem>
          </StyledSelect>
        ) : (
          <span
            className="editable-text"
            onMouseEnter={() => handleEdit('segment')}
            style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline-block' }}
          >
            {segment}
          </span>
        )}
        {', '}
        {editField === 'benefit' ? (
          <StyledTextField
            value={benefit}
            onChange={handleBenefitChange}
            onBlur={handleBenefitBlur}
            autoFocus
            variant="outlined"
            className="customizable-input"
            style={{ width: 'auto' }}
          />
        ) : (
          <span
            className="editable-text"
            onMouseEnter={() => handleEdit('benefit')}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {benefit}
          </span>
        )}
        {' '}by{' '}
        {editField === 'differentiation' ? (
          <StyledTextField
            value={differentiation}
            onChange={handleDifferentiationChange}
            onBlur={handleDifferentiationBlur}
            autoFocus
            variant="outlined"
            className="customizable-input"
            style={{ width: 'auto' }}
          />
        ) : (
          <span
            className="editable-text"
            onMouseEnter={() => handleEdit('differentiation')}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {differentiation}
          </span>
        )}
        .
      </div>
    </div>
  );
};

export default CustomizableText;
