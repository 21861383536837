import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Savetemplate({ programType }) {
    const [htmlContent, setHtmlContent] = useState('');
    const [Templates, setTemplates] = useState([]);
    const [templateId, setTemplateId] = useState('');
    const [ProgramType,setProgramType]= useState('');
    useEffect(() => {
        const fetchTemplate=async()=>{
            const templatesResponse=await axios.get(`${process.env.REACT_APP_BASE_URL_API}/pages/templates`);
            setTemplates(templatesResponse.data.templates);
        };
        fetchTemplate();
    }, []);
    
    const saveSection= (section,order)=>{
        const blockId = section.id;
        const blockHtml = section.outerHTML;

        // Skip if there's no ID
        if (!blockId) return;

        // Define the data object for each block
        const data = {
            tag: [blockId],
            templateId,
            plainHtml: blockHtml,
            programType,
            blockId,
            order
        };

        // Post data to the API
        axios.post(`${process.env.REACT_APP_BASE_URL_API}/pages/test`, data)
        .then(response => {
            console.log(`Content saved for block ${blockId}:`, response.data);
        })
        .catch(error => {
            console.error(`Error saving content for block ${blockId}:`, error);
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const sections = doc.querySelectorAll('section');
        const footers = doc.querySelectorAll('footer');
        let order=0;
        sections.forEach(section => {
            saveSection(section,order);
            order++;
        });
        footers.forEach(section => {
            saveSection(section,order);
            order++;
        });
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-6'>
                        <textarea
                            value={htmlContent}
                            onChange={(e) => setHtmlContent(e.target.value)}
                            placeholder="Paste your HTML content here"
                            rows="10"
                            cols="50"
                            className='form-control'
                        />
                        <button type="submit" className='btn btn-primary mt-5'>Save Content</button>
                    </div>
                    <div className='col-md-6'>
                        <select
                        className={`form-select`}
                        onChange={(e) => setTemplateId(e.target.value)}
                        >
                        <option value="" disabled>
                            Select an option...
                        </option>
                        {console.log(Templates)
                        }
                        {Templates.map((template) => {
                            return (
                            <option key={template._id} value={template._id}>
                                {template.templatename}
                            </option>
                            );
                        })}
                        </select>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default Savetemplate
